import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

// Time
import moment from 'moment';

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Helpers
import cardNumberAddSpaces from "src/helpers/cardNumberAddSpaces";

// Config
import ConfigApi from "src/configs/api";

// Services
import CardService from "src/service/Card";

// Constants
import CONSTANTS from "src/constants/index";

// Configs
import WEBSITES from "src/configs/websites";

// Toast
import { toast } from "react-hot-toast";

// Socket
import { socket } from "src/socket";

// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";
import CurrencyInfo from "src/components/Cards/CurrencyInfo";
import InfoRaw from "src/components/InfoRaw";

// Modules
import Control from './Control'
import Images from './Images'
import Info from './Info';
import { Divider } from "@mui/material";


function CardContent(props) {
  let { data, handleClose } = props;
  // State
  let [card, setCard] = useState(data);

  // Hooks
  useEffect(() => {
    socket.on(`card_upd_${card._id}`, (c) => {
      setCard((card) => {
        return Object.assign({}, card, c);
      });
    });
    return () => {
      socket.off(`card_upd_${card._id}`);
    };
  }, []);

  // Handlers
  const toggleArchivedHandler = () => {
    let archived = !card.archived;
    CardService.change({
      _id: card._id,
      archived,
    }).then(() => {
      toast.success(
        `Карта ${archived ? "заархивирована" : "разархивирована"}!`
      );
    });
  };

  // Calc
  let statusInfo = CONSTANTS.cardStatusList.find((c) => c.code == card?.status);
  let website = WEBSITES.find((s) => s.id == card?.sc);

  return (
    <Box>
      <Info
      card={card}
      handleClose={handleClose}
      style={{ pt: 0 }}
      />
      <Divider sx={{my: '0px !important'}} />
      {/* <Images/> */}
      <Control card={card}/>
    </Box>
  );
}

export default CardContent;
