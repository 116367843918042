import { useState, useEffect } from "react";

import { Box, Grid } from "@mui/material";

// Components
import PageWrap from "src/components/PageWrap";

// Modules
import CurrencyName from "./CurrencyName";
import Fields from "./Fields";

// Service
import LocalizationService from "src/service/Localization";



function Localization() {
  let [data, setData] = useState(null);

  useEffect(() => {
    LocalizationService.getData().then((r) => setData(r.data));
  }, []);

  if(!data) return null;

  return (
    <PageWrap title="Локализация">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <CurrencyName currencyNames={data.currencyNames} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Fields fields={data.fields} />
        </Grid>
      </Grid>
    </PageWrap>
  );
}

export default Localization;
