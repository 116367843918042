// Mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

// ** Icon
import Icon from "src/@core/components/icon";

function Control(props) {
  let { openAddOrderHandler, search, setSearch } = props;
  return (
    <Box sx={{ mt: 3 }}>
      <Grid justifyContent="space-between" container spacing={4}>
        <Grid item xs={6} md={2}>
          <Button
            startIcon={<Icon icon="material-symbols:add" />}
            fullWidth
            onClick={openAddOrderHandler}
            variant="contained"
          >
            Добавить
          </Button>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box sx={{ display: "flex" }}>
            <Button
              // endIcon={}
              //   fullWidth
              variant="outlined"
            >
              {/* Поиск */}
              <Icon icon="material-symbols:manage-search-rounded" />
            </Button>
            <TextField
              placeholder="Номер заявки или email клиента"
              sx={{ ml: 2, flexGrow: 1 }}
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Control;
