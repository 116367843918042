// ** React Imports
import { useEffect, useState } from "react";

// ** Hooks
import { useAuth } from "../../hooks/useAuth";

// ** Next Import
import { Link, useLocation, useNavigate } from "react-router-dom";

// ** MUI Components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import FormHelperText from "@mui/material/FormHelperText";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Third Party Imports
import Cleave from "cleave.js/react";
import { useForm, Controller } from "react-hook-form";

// ** Configs
import themeConfig from "src/configs/themeConfig";

// ** Layout Import
import BlankLayout from "src/@core/layouts/BlankLayout";

// ** Demo Imports
// import FooterIllustrationsV1 from "src/views/pages/auth/FooterIllustrationsV1";

// ** Custom Styled Component
// import CleaveWrapper from "src/@core/styles/libs/react-cleave";

// ** Util Import
// import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

// ** Services
import AuthService from "src/service/Auth";

import toast from "react-hot-toast";

// ** Styles
import "cleave.js/dist/addons/cleave-phone.us";

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  marginLeft: theme.spacing(1),
  color: theme.palette.primary.main,
}));

const TwoStepsV1 = () => {
  // ** State
  const [isSubmitResend, setSubmitResend] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [textError, setTextError] = useState("");

  // ** Hooks
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  let hl = location?.state?.email && location?.state?.password;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      code: "",
    },
  });

  // * Handlers

  function resetHandler(e) {
    e.preventDefault();

    if (isSubmitResend) return;

    setSubmitResend(true);

    const sendCodePromise = new Promise((resolve, reject) => {
      AuthService.login({
        email: location.state.email,
        password: location.state.password,
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          setSubmitResend(false);
        });
    });

    return toast.promise(sendCodePromise, {
      loading: "Отправляем код",
      success: "Код отправлен",
      error: "Ошибка отправки",
    });
  }

  // ** Vars

  const onSubmit = handleSubmit((data) => {
    setSubmiting(true);

    AuthService.login(Object.assign({}, location.state, { code: data.code }))
      .then((res) => {
        auth.login(res.data);
      })
      .catch((e) => {
        let message = e.response.data.message;
        if (message) {
          setTextError(message);
          setShowError(true);
        }
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  if(!hl){
    navigate('/login')
    return null;
  }

  return (
    <BlankLayout>
      <Box className="content-center">
        <Card sx={{ zIndex: 1 }}>
          <CardContent
            sx={{ p: (theme) => `${theme.spacing(15.5, 7, 9)} !important` }}
          >
            <Collapse sx={{ mb: 4, mt: 2 }} in={showError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    color="inherit"
                    aria-label="close"
                    onClick={() => setShowError(false)}
                  >
                    <Icon icon="mdi:close" fontSize="inherit" />
                  </IconButton>
                }
              >
                {textError}
              </Alert>
            </Collapse>

            <form noValidate onSubmit={onSubmit}>
              <Controller
                name={"code"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    id="code"
                    type="text"
                    label="Код"
                    sx={{ mt: 4 }}
                    error={Boolean(errors.code?.message)}
                    {...register("code", {
                      required: "Введите код!",

                      minLength: {
                        message: "Мин. длина: 6 символа",
                        value: 6,
                      },
                      maxLength: {
                        message: "Макс. длина: 6 символа",
                        value: 6,
                      },
                    })}
                    helperText={errors.code?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 4 }}
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
              >
                Войти
              </Button>
            </form>
            <Box
              sx={{
                mt: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: "text.secondary" }}>
                Не получили код?
              </Typography>
              <LinkStyled href="/" onClick={resetHandler}>
                Переслать
              </LinkStyled>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </BlankLayout>
  );
};

export default TwoStepsV1;
