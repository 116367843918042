import { useState } from "react";
// Mui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Modules

import LoyaltyChip from "src/modules/LoyaltyChip";

// Configs
import CONSTANTS from "src/constants/index";

// Services
import ClientService from "src/service/Client";

// tost
import toast from "react-hot-toast";

function ClientLoyalty({ init, clientId, style = {} }) {
  let [anchorLoyalty, setAnchorLoyalty] = useState(null);
  let [loyalty, setLoyalty] = useState(init);

  // Calc
  let loyaltyList = Object.entries(CONSTANTS.loalty).map((l) => ({
    name: l[1],
    value: l[0],
  }));

  // Handlers
  const changeLoyaltyHandler = (loyalty) => {
    ClientService.change(clientId, { loyalty }).then(() => {
      setLoyalty(loyalty);
      setAnchorLoyalty(null);
      toast.success("Уровень лояльности изменен!");
    });
  };

  return (
    <Box sx={{ ...style }}>
      <LoyaltyChip
        aria-controls="change-loyalty-menu"
        onClick={(e) => {
          setAnchorLoyalty(e.currentTarget);
        }}
        loyalty={loyalty}
      />
      <Menu
        keepMounted
        id="change-loyalty-menu"
        anchorEl={anchorLoyalty}
        onClose={() => setAnchorLoyalty(null)}
        open={Boolean(anchorLoyalty)}
      >
        {loyaltyList.map((l) => {
          return (
            <MenuItem
              onClick={() => changeLoyaltyHandler(l.value)}
              selected={l.value == loyalty}
            >
              {l.name}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default ClientLoyalty;
