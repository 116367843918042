// ** MUI Imports
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

function SwitchLang(props) {
  let { value, setValue } = props;
  function changeValueHandler(e) {
    setValue(e.target.value);
  }

  return (
    <ToggleButtonGroup
      
      exclusive
      color="primary"
      size="small"
      value={value}
      onChange={changeValueHandler}
      {...props}
    >
      <ToggleButton value="rus">Русский</ToggleButton>
      <ToggleButton value="eng">English</ToggleButton>
    </ToggleButtonGroup>
  );
}

export default SwitchLang;
