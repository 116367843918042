// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const TableHeader = (props) => {
  // ** Props
  const { search, changeSearch, addClientToggle } = props;

  return (
    <Box
      sx={{
        p: 5,
        pb: 3,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Button sx={{ mb: 2 }} onClick={addClientToggle} variant="contained">
        Добавить
      </Button>

      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <TextField
          size="small"
          value={search}
          sx={{ mr: 6, mb: 2 }}
          placeholder="Поиск"
          onChange={(e) => changeSearch(e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default TableHeader;
