import axios from "axios";

class Acquiring {
  getAdvancedCash(params) {
    return axios.get("/advanced-cash", { params });
  }
  changeAdvancedCash(data) {
    return axios.post("/advanced-cash", data);
  }
  getPayeer(params) {
    return axios.get("/payeer", { params });
  }
  changePayeer(data) {
    return axios.post("/payeer", data);
  }
  getYoomoney(params) {
    return axios.get("/ym", { params });
  }
  changeYoomoney(data) {
    return axios.post("/ym", data);
  }
  //   login(data) {
  //     return axios.post("/admin/login", data);
  //   }
  //   getProfile() {
  //     return axios.get("/admin/profile");
  //   }
  //   createAdmin(data) {
  //     return axios.post("/admin", data);
  //   }
  //   getAdminList() {
  //     return axios.get("/admin");
  //   }
  //   getAdminOne(id) {
  //     return axios.get("/admin/one", { params: { id: id } });
  //   }
  //   // Session
  //   adminGetSession(id) {
  //     return axios.get("/admin/session", { params: { id: id } });
  //   }
  //   adminResetSession(id) {
  //     return axios.put("/admin/session", { id });
  //   }
}

export default new Acquiring();
