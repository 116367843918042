export default function addNestedField(value, field, obj) {
    const keys = field.split('.');
    let currObj = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (!(key in currObj)) {
        currObj[key] = {};
      }
      currObj = currObj[key];
    }
    currObj[keys[keys.length - 1]] = value;
    return obj;
  }