import { useState, useEffect } from "react";
// Router
import { useNavigate } from "react-router-dom";
// Mui
import {
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Typography,
  Avatar,
} from "@mui/material";
//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";
// ** Icon Imports
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";

// Libs
import moment from "moment";

// Config
import ConfigApi from "src/configs/api";

// Modules
import RewardChip from "./RewardChip";
// import Timer from "../Timer";
import { socket } from "src/socket";

function RewardCard({ data }) {
  let [reward, setReward] = useState(data);

  useEffect(() => {
    socket.on(`update_reward`, (upd) => {
      if (upd._id == reward._id) {
        setReward((o) => Object.assign({}, o, upd));
      }
    });
    return () => {
      socket.off(`update_reward`);
    };
  }, []);

  // Router
  const navigate = useNavigate();

  let isReferral = reward.type !== "cashback";

  // Calc
  let name = isReferral ? "Реф. программа" : "Кэшбек";
  let status = reward.status;
  let sc = reward.sc;
  let infoList = [
    {
      icon: "ic:round-currency-ruble",
      alt: "Сумма",
      name: reward.sum,
    },
    {
      icon: "mingcute:bank-line",
      alt: "Банк",
      name: reward?.currency?.name || "Не установлено",
    },
    {
      icon: "dashicons:admin-site-alt3",
      alt: "Сайт",
      name: WEBSITES.find((s) => s.id === sc)?.name,
    },
    {
      icon: "mdi:user-circle-outline",
      alt: "Клиент",
      name: reward.client.email,
    },
  ];

  return (
    <Card
      onClick={() => {
        navigate(`?rewardId=${data._id}`);
      }}
    >
      <CardContent sx={{ py: 2, px: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "18px" }} variant="body1">
            {name}
          </Typography>
          <RewardChip size="small" status={status} />
        </Box>

        <Info list={infoList} />
      </CardContent>
      <CardContent sx={{ py: 2, px: 3, pb: "12px !important" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Typography
            sx={{
              alignSelf: "flex-end",
            }}
            variant="caption"
          >
            {moment(data.createdAt).format("YYYY.MM.DD HH:mm")}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

function Info(props) {
  let { list } = props;

  return (
    <Box sx={{ mt: 2.5, color: "secondary" }}>
      {list.map((i) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
            <Icon fontSize={18} icon={i.icon} />
            <Typography sx={{ ml: 1.5 }} variant="body2">
              {i.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default RewardCard;
