//MUi
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import toast from "react-hot-toast";

// Libs
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CustomTextField(props) {
  let { value, isChanged = false, saveHandler } = props;

  let endAdornment;

  if (isChanged) {
    endAdornment = (
      <IconButton onClick={saveHandler} sx={{ borderRadius: 0 }}>
        <Icon icon="mingcute:save-line" />
      </IconButton>
    );
  } else {
    endAdornment = (
      <CopyToClipboard
        text={value}
        onCopy={() => {
          toast.success("Скопировано!");
        }}
      >
        <IconButton sx={{ borderRadius: 0 }}>
          <Icon icon="material-symbols:content-copy-outline-rounded" />
        </IconButton>
      </CopyToClipboard>
    );
  }

  return (
    <TextField
      fullWidth
      sx={{
        "& .MuiInputBase-root": {
          pr: 0,
        },
      }}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
