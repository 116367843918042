// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TableContainer from "@mui/material/TableContainer";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";
// import OptionsMenu from 'src/@core/components/option-menu'
// CONSTANTS
import WEBSITES from "src/configs/websites";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

import { observer } from "mobx-react-lite";

const StatProfit = ({ profitData = {} }) => {
  let profitCount = 0;

  let formattedList = [];

  WEBSITES.forEach((s) => {
    profitCount += profitData[s.id];
  });

  WEBSITES.forEach((s) => {
    formattedList.push({
      title: s.name,
      amount: profitData[s.id],
    });
  });

  profitCount += profitData.costs;

  return (
    <Card>
      <CardHeader
        title="Прибыль за день"
        titleTypographyProps={{
          sx: {
            lineHeight: "2rem !important",
            letterSpacing: "0.15px !important",
          },
        }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(2.5)} !important` }}>
        <Box sx={{ mb: 5.75, display: "flex", alignItems: "center" }}>
          <CustomAvatar
            skin="light"
            variant="rounded"
            sx={{ mr: 4, width: 50, height: 50 }}
          >
            <Icon icon="bi:cash-coin" fontSize="2rem" />
          </CustomAvatar>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4">
              {getPriceCurrentFiat(profitCount, 0)}
            </Typography>
            <Typography variant="caption">Всего прибыли c учетом внутренних переводов</Typography>
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableBody>
              {formattedList.map((row) => {
                return (
                  <TableRow
                    key={row.title}
                    sx={{
                      "&:last-of-type td": { border: 0 },
                      "& .MuiTableCell-root": {
                        "&:last-of-type": { pr: 0 },
                        "&:first-of-type": { pl: 0 },
                        py: (theme) => `${theme.spacing(2.75)} !important`,
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": { mr: 1.8, color: `${row.color}.main` },
                        }}
                      >
                        <Typography
                          sx={{ color: "text.primary", fontWeight: 600 }}
                        >
                          {row.title}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 600, color: "text.primary" }}
                      >
                        {getPriceCurrentFiat(row.amount, 0)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default observer(StatProfit);
