export default function (kits, walletId) {
  if (kits.length == 0 || !walletId) return [];

  let result = [...kits];

  result.sort((a, b) => {
    let aNumber, bNumber;

    if (a.isGeneral) {
      let wallets = a.wallets;
      let wa = wallets.find((c) => c.wallet + "" === walletId + "");
      aNumber = wa.number;
    } else {
      aNumber = a.number;
    }

    if (b.isGeneral) {
      let wallets = b.wallets;
      let wb = wallets.find((c) => c.wallet + "" === walletId + "");
      bNumber = wb.number;
    } else {
      bNumber = b.number;
    }

    return aNumber - bNumber > 0 ? 1 : -1;
  });
  return result;
}
