// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Ui
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

function FieldItem(props) {
  let { name, value, showCopy, style = {} } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        maxWidth: "100%",
        ...style,
      }}
    >
      {!!name && (
        <Typography sx={{ mr: 2 }} variant="body2">
          {name}:
        </Typography>
      )}

      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
        <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
          {value}
        </Typography>
        {showCopy && typeof value == "string" && (
          <CopyClipboardWrap value={value.trim()} />
        )}
      </Box>
    </Box>
  );
}

export default FieldItem;
