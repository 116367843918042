// React
import { useState, useEffect } from "react";

// Mui
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// Custom
import PageWrap from "src/components/PageWrap";

// ** Icon
import Icon from "src/@core/components/icon";

// Modules
import AddKitModal from "./modules/AddKitModal";
import FiatKit from "./modules/FiatKit";
import DialogTransferBalance from "./modules/DialogTransferBalance";

// Services
import CurrenciesService from "src/service/Currencies";
import WalletService from "src/service/Wallet";

function General() {
  // States
  let [showAddKit, setShowAddKit] = useState(false);
  let [kits, setKits] = useState([]);
  let [fiatCurrencies, setFiatCurrencies] = useState([]);

  // Transfer states
  let [transferCurrency, setTransferCurrency] = useState(null);
  let [showTransferSum, setShowTransferSum] = useState(false);
  let [transferSumData, setTransferSumData] = useState({
    id: undefined,
    isKit: false,
  });


  // Hooks
  useEffect(() => {
    CurrenciesService.getList({
      populate: ["wallet"],
      query: {
        isCrypto: false
      }
    }).then((r) => setFiatCurrencies(r.data));
    WalletService.getKits({ isGeneral: true }).then((r) => setKits(r.data));
  }, []);

  // Handlers
   // Transfer Balance Handler
   const openTransferSum = (upd, currency) => {
    setTransferSumData(upd);
    setShowTransferSum(true);
    setTransferCurrency(currency);
  };
  const closeTransferSum = () => {
    setTransferSumData({ id: undefined, isKit: false });
    setShowTransferSum(false);
    setTransferCurrency(null);
  };


  const toggleShowAddKit = () => {
    setShowAddKit(!showAddKit);
  };

  const deleteItemHandler = (id) => {
    setKits((list) => list.filter((k) => k._id !== id));
  };
  const addItemHandler = (kit) => {
    setKits((list) => [kit, ...list]);
  };


  return (
    <PageWrap title="Общие комплекты">
      <AddKitModal
        title="Добавить общий комплект"
        isGlobal
        currencies={fiatCurrencies}
        open={showAddKit}
        addItemHandler={addItemHandler}
        handleClose={toggleShowAddKit}
      />
      <DialogTransferBalance
        transferCurrency={transferCurrency}
        takeIsKit={transferSumData.isKit}
        takeId={transferSumData.id}
        show={showTransferSum}
        closeHandler={closeTransferSum}
      />
      <Button
        startIcon={<Icon icon="material-symbols:add" />}
        variant="contained"
        sx={{ height: "100%" }}
        size="large"
        onClick={toggleShowAddKit}
      >
        Добавить
      </Button>

      <Box sx={{ mt: 4 }}>
        {kits.map((k) => {
          return (
            <FiatKit
              deleteItemHandler={deleteItemHandler}
              openTransferSum={openTransferSum}
              key={k._id}
              data={k}
              style={{ mb: 2 }}
              isGeneral
            />
          );
        })}
      </Box>
    </PageWrap>
  );
}

export default General;
