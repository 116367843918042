import { useState, useEffect } from "react";
// Mui
import {
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Divider,
  TextField,
} from "@mui/material";

// Router
import { useNavigate } from "react-router-dom";
// utils
import formattedValue from "src/utils/currency/formattedValue";

// Services
import CurrenciesService from "src/service/Currencies";
import DebtService from "src/service/Debt";

// Form
import { useForm, Controller } from "react-hook-form";

// Componets
import FieldItem from "src/components/Currency/FieldItem";
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";

// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

// Notify
import toast from "react-hot-toast";

// Socket
import { socket } from "src/socket";

function ChangeDebt({ debtId }) {
  // States
  const [loaded, setLoaded] = useState(false);
  const [debt, setDebt] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [currencyId, setCurrencyId] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);

  // Validation
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      sum: "",
    },
  });

  // Calc
  let currency = currencies.find((c) => c._id === currencyId);
  let code = currency?.code;

  // Hooks
  useEffect(() => {
    setLoaded(false);
    DebtService.getOne(debtId).then((r) => {
      setDebt(r.data);
      setValue("name", r.data.name);
      setValue("sum", r.data.sum);
      setCurrencyId(r.data.currency._id);
      setLoaded(true);
    });
    CurrenciesService.getList({}).then((r) => setCurrencies(r.data));

    socket.on("update_debt", (upd) => {
      setDebt((debt) => (debt._id == upd._id ? upd : debt));
    });

    // update_debt

    return () => {
      socket.off("update_debt");
    };
  }, []);

  const navigate = useNavigate();

  // Handlers
  const changePayedHandler = () => {
    let newPayed = !debt.payed;
    DebtService.changeOne({
      _id: debt._id,
      payed: newPayed,
    }).then((r) => {
      toast.success(`Задолженность ${newPayed ? "оплачена" : "не оплачена"}!`);
    });
  };

  const onSubmit = handleSubmit((d) => {
    let updData = {
      sum: +d.sum,
      name: d.name,
      currency: currencyId,
      _id: debt._id,
    };
    setIsSubmiting(true);
    DebtService.changeOne(updData)
      .then((r) => {
        toast.success(`Задолженность измененна!`);
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  });

  if (!loaded || !currencies.length) return loadedComponent;

  let payed = debt.payed;

  return (
    <Box sx={{ px: 4, pt: 2 }}>
      <Box sx={{ mb: 3 }}>
        <CustomChip
          skin="light"
          label={payed ? "Оплачено" : "Не оплачено"}
          color={payed ? "success" : "error"}
          sx={{
            width: "min-content",
            fontSize: "1rem",
            fontWeight: 600,
            borderRadius: "5px",
          }}
        />
      </Box>

      <Box component="form" noValidate onSubmit={onSubmit}>
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              size="small"
              label="Название"
              placeholder="Введите название долга"
              id="name"
              type="text"
              error={Boolean(errors.name?.message)}
              {...register("name", {
                required: "Обязательное поле!",
              })}
              helperText={errors.name?.message}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <SimpleSelectCurrency
          label="Валюта"
          value={currencyId}
          size="small"
          onChange={setCurrencyId}
          currencies={currencies}
          containerStyle={{ mb: 3 }}
        />
        <Controller
          name={"sum"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              size="small"
              label={`Сумма ${code}`}
              placeholder="Введите сумму долга"
              id="sum"
              type="number"
              error={Boolean(errors.sum?.message)}
              {...register("sum", {
                required: "Обязательное поле!",
              })}
              helperText={errors.sum?.message}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Button
            fullWidth
            onClick={changePayedHandler}
            sx={{ mb: 3 }}
            variant={payed ? "outlined" : "contained"}
            color={payed ? "error" : "primary"}
          >
            {payed ? "Средства не получены" : "Средства получены"}
          </Button>
        </Grid>
      </Grid>

      <Card sx={{ bgcolor: (t) => t.palette.background.default }}>
        <Box sx={{ px: 3, pt: 2 }}>
          <FieldItem name="Email" value={debt.email} showCopy />
          <FieldItem name="IP" value={debt.ip} showCopy />
          <FieldItem
            name="Заявка"
            value={
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`?orderId=${debt.order}`);
                }}
                color="primary"
              >
                {debt.order}
              </Typography>
            }
            showCopy
          />
        </Box>

        <Divider />
        <Box sx={{ px: 3, pb: 2, maxHeight: "300px", overflowY: "scroll" }}>
          {debt.fields.map((f, i) => {
            return (
              <FieldItem
                key={i}
                name={f.name}
                value={formattedValue(f.value, f.validation)}
                showCopy
              />
            );
          })}
        </Box>
      </Card>
      <Button
        onClick={onSubmit}
        startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmiting}
        size="large"
        type="submit"
        variant="contained"
        sx={{ mt: 4, mb: 3}}
        fullWidth
      >
        Сохранить
      </Button>
    </Box>
  );
}

const loadedComponent = (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <CircularProgress />
  </Box>
);

export default ChangeDebt;
