// ** React Imports
import { useState, forwardRef, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

// Services
import CurrenciesService from "src/service/Currencies";
import ExchangeApiSettingService from "src/service/ExchangeApiSetting";

// Toast
import toast from "react-hot-toast";

// Form
import { useForm, Controller } from "react-hook-form";

// Constants
import CONSTANTS from "src/constants/index";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

// ** Store
import { observer } from "mobx-react-lite";

// Custom components
import ImageUploader from "src/components/ImageUploader/ImageUploader";

let timeId = "";
let timeId2 = "";

const DialogAddCurrency = ({ show, setShow }) => {
  // ** States
  const [selectedCode, setSelectedCode] = useState(null);
  const [isSubmiting, setSubmiting] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);
  const [imageName, setImageName] = useState("");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    let d = {
      name: data.name,
      code: selectedCode,
      isCrypto,
      image: imageName,
    };

    setSubmiting(true);
    CurrenciesService.addCurrency(d)
      .then(() => {
        handleClose();
        toast.success("Валюта добавлена!");
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  const handleClose = () => {
    setShow(false);
    setIsCrypto(false);
    setSelectedCode();
    setValue("name", "");
    setSubmiting(false);
  };

  const uploadSuccesHandler = (imgName) => {
    setImageName(imgName);
    setSuccessUpload(true);
    timeId = setTimeout(() => {
      setSuccessUpload(false);
    }, 2000);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={show}
        maxWidth="xs"
        scroll="body"
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <DialogContent
          sx={{
            pb: 6,
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
              Добавить валюту
            </Typography>
            <Typography variant="body2">Добавьте уникальную валюту</Typography>
          </Box>

          <Collapse sx={{ mb: 4 }} in={successUpload}>
            <Alert
              action={
                <IconButton
                  size="small"
                  color="inherit"
                  aria-label="close"
                  onClick={() => setSuccessUpload(false)}
                >
                  <Icon icon="mdi:close" fontSize="inherit" />
                </IconButton>
              }
            >
              Изображение загружено!
            </Alert>
          </Collapse>

          <ImageUploader
            uploadSuccesHandler={uploadSuccesHandler}
            sx={{ mb: 4 }}
          />

          <form noValidate onSubmit={onSubmit}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  label="Имя"
                  placeholder="Название"
                  autoFocus
                  id="name"
                  type="text"
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.name?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <FormControlLabel
              sx={{ mt: 3 }}
              label="Криптовалюта"
              control={
                <Checkbox
                  checked={isCrypto}
                  onChange={(e) => setIsCrypto(e.target.checked)}
                  name="controlled"
                />
              }
            />
            <SelectCode
              isCrypto={isCrypto}
              code={selectedCode}
              setCode={setSelectedCode}
            />
          </form>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
        >
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            variant="contained"
            sx={{ mr: 2 }}
            onClick={onSubmit}
          >
            Добавить
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function SelectCode({ isCrypto, code, setCode }) {
  let { fiats } = CONSTANTS;
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);

  function fetchCurrencies() {
    setLoading(true);
    ExchangeApiSettingService.getConnectedCurrencies()
      .then((r) => {
        setCurrencies(r.data);
        setCode(r.data[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let codes = isCrypto ? currencies : fiats;

  useEffect(() => {
    setCode("");
    if (isCrypto) {
      if (!currencies.length) {
        fetchCurrencies();
      } else {
        setCode(codes[0]);
      }
    } else {
      setCode(codes[0]);
    }
  }, [isCrypto]);

  if (loading)
    return (
      <Box
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );

  return (
    <Box sx={{ mt: 3 }}>
      <Autocomplete
        value={code}
        defaultValue={codes[0]}
        options={codes}
        getOptionLabel={(option) => {
          if (isCrypto) {
            let item = currencies.find((c) => option === c);
            if (!item) return "-";
            return item;
          } else {
            return option;
          }
        }}
        onChange={(e, value) => {
          setCode(value);
        }}
        renderInput={(params) => <TextField {...params} label="Валюта" />}
      />
    </Box>
  );
}

export default observer(DialogAddCurrency);
