import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

// Form
import { useForm, Controller } from "react-hook-form";

// ** Service Import
import CardCommentService from "src/service/CardComment";

// ** Toaster Import
import toast from "react-hot-toast";


function CardCommentDialog({ data, open, handleDialogToggle, addItem, changeItem }) {
  // ** States
  const [isSubmiting, setSubmiting] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: data || {
      admin: "",
      rus: "",
      eng: "",
    },
  });

  useEffect(() => {
    if (data) {
      setValue("admin", data.admin);
      setValue("rus", data.rus);
      setValue("eng", data.eng);
    } else {
      setValue("admin", "");
      setValue("rus", "");
      setValue("eng", "");
    }
  }, [open]);

  const isChange = !!data;
  const itemId = data?.id;

  const onSubmit = handleSubmit((data) => {
    setSubmiting(true);

    let fetchFunc = isChange
      ? CardCommentService.change
      : CardCommentService.add;

    fetchFunc(Object.assign({}, data, { id: itemId }))
      .then((res) => {
        if (isChange) {
          toast.success("Комментарий изменен!");
          changeItem(res.data);
          handleDialogToggle();

        } else {
          toast.success("Комментарий добавлен!");
          addItem(res.data);
          handleDialogToggle();
        }
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <>
      <Dialog fullWidth maxWidth="sm" onClose={handleDialogToggle} open={open}>
        <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
          <Typography variant="h5" component="span" sx={{ mb: 2 }}>
            {data ? "Изменить" : "Добавить"} комментарий
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ pb: 12, mx: "auto" }}>
          <Box
            component="form"
            noValidate
            onSubmit={onSubmit}
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Controller
              name={"admin"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  label="CRM"
                  sx={{ mb: 3, maxWidth: 450 }}
                  placeholder="Введите комментарий для CRM"
                  autoFocus
                  id="admin"
                  type="text"
                  error={Boolean(errors.admin?.message)}
                  {...register("admin", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.admin?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Controller
              name={"rus"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  label="Клиент (РУС)"
                  sx={{ mb: 3, maxWidth: 450 }}
                  placeholder="Введите комментарий для Клиента (РУС)"
                  id="rus"
                  type="text"
                  error={Boolean(errors.rus?.message)}
                  {...register("rus", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.rus?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            <Controller
              name={"eng"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  label="Клиент (ENG)"
                  sx={{ mb: 3, maxWidth: 450 }}
                  placeholder="Введите комментарий для Клиента (ENG)"
                  id="eng"
                  type="text"
                  error={Boolean(errors.eng?.message)}
                  {...register("eng", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.eng?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            <Box
              className="demo-space-x"
              sx={{ "& > :last-child": { mr: "0 !important" } }}
            >
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
                size="large"
                type="submit"
                variant="contained"
              >
                {data ? "Сохранить" : "Добавить"}
              </Button>
              <Button
                type="reset"
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleDialogToggle}
              >
                Отменить
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default CardCommentDialog;
