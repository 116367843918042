// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import { toast } from "react-hot-toast";

// Service
import CurrencyService from "src/service/Currencies";

// Form
import { useForm, Controller } from "react-hook-form";

function Notifications(props) {
  let { style = {}, info } = props;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      take: info?.notifications?.take || "",
      give: info?.notifications?.give || "",
    },
  });

  // Handlers
  const onSubmit = handleSubmit((data) => {
    let fetchObj = {
      _id: info._id,
      notifications: data,
      action: 'notifications'
    };
    CurrencyService.infoChange(fetchObj).then((r) => {
      toast.success("Уведомления измененны!");
    });
  });

  //   notifications

  return (
    <CardContent sx={{ ...style }}>
      <Typography variant="h6" sx={{mb: 4}}>Уведомления</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              name={"take"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="При отдачи"
                  placeholder="Введите текст уведомления при отдачи валюты"
                  id="take"
                  type="text"
                  error={Boolean(errors.take?.message)}
                  {...register("take", {})}
                  helperText={errors.take?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={"give"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="При получении"
                  placeholder="Введите текст уведомления при получении валюты"
                  id="give"
                  type="text"
                  error={Boolean(errors.give?.message)}
                  {...register("give", {})}
                  helperText={errors.give?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <Button
            endIcon={<Icon icon="material-symbols:save-outline" />}
            variant="contained"
            onClick={onSubmit}
          >
            Сохранить
          </Button>
        </Box>
    </CardContent>
  );
}

export default Notifications;
