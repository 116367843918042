// ** React Imports
import { useEffect } from "react";

// ** Next Import
// import { useRouter } from 'next/router'
import { useLocation, useNavigate } from "react-router-dom";

// ** Hooks Import
import { useAuth } from "src/hooks/useAuth";

const AuthGuard = (props) => {
  const { children, fallback } = props;
  const auth = useAuth();
  // router
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    () => {
      if (auth.user === null) {
        if (location.pathname !== "/login") {
          navigate("/login");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );
  if (auth.loading || auth.user === null) {
    return fallback;
  }

  return <>{children}</>;
};

export default AuthGuard;
