// ** React Imports
import { useState, forwardRef, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

// Custom components
import ImageUploader from "src/components/ImageUploader/ImageUploader";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import toast from "react-hot-toast";

let timeId = "";

// Services
// import CurrencyService from "src/service/Currencies";

export default function ModalChangeIcon(props) {
  let { show, handleClose, setImageName, onSubmit } = props;
  const [isSubmiting, setSubmiting] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);

  //Handlers
  const uploadSuccesHandler = (imgName) => {
    setImageName(imgName);
    setSuccessUpload(true);
    timeId = setTimeout(() => {
      setSuccessUpload(false);
    }, 2000);
  };

 

  return (
    <Dialog open={show} maxWidth="xs" scroll="body" onClose={handleClose}>
      <DialogContent
        sx={{
          pb: 6,
          px: { xs: 8, sm: 15 },
          pt: { xs: 8, sm: 12.5 },
          position: "relative",
        }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon icon="mdi:close" />
        </IconButton>
        <Box sx={{ mb: 4, textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
            Добавить изображение
          </Typography>
        </Box>
        <Collapse sx={{ mb: 4 }} in={successUpload}>
          <Alert
            action={
              <IconButton
                size="small"
                color="inherit"
                aria-label="close"
                onClick={() => setSuccessUpload(false)}
              >
                <Icon icon="mdi:close" fontSize="inherit" />
              </IconButton>
            }
          >
            Изображение загружено!
          </Alert>
        </Collapse>
        <ImageUploader
          purpose="card"
          uploadSuccesHandler={uploadSuccesHandler}
          sx={{ mb: 4 }}
        />
      </DialogContent>
      <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
        <Button
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
          variant="contained"
          sx={{ mr: 2 }}
          onClick={onSubmit}
        >
          Отправить
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
}
