import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Components
import CurrencySelect from "src/components/CurrencySelect";

// ** Icon
import Icon from "src/@core/components/icon";
//Services
import CurrencyService from "src/service/Currencies";
import WalletService from "src/service/Wallet";

// Validation
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";

function AddKitModal(props) {
  let {
    open,
    handleClose,
    isGlobal = false,
    title = "",
    addItemHandler,
    currencies,
    currency,
  } = props;
  // State
  const [isSubmiting, setSubmiting] = useState(false);
  const [generalCurrencyId, setGeneralCurrencyId] = useState("");


  // Validation
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let queryObj = {
      name: d.name,
      currency: isGlobal ? generalCurrencyId : currency._id,
      isGeneral: isGlobal,
    };
    setSubmiting(true);
    WalletService.createKit(queryObj)
      .then((r) => {
        addItemHandler(r.data);
        toast.success("Комплект создан!");
        handleClose();
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            px: { xs: 8, sm: 12 },
            py: { xs: 8, sm: 12 },
            position: "relative",
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
          <Box sx={{ mb: 8, textAlign: "center" }}>
            <Typography variant="h6" sx={{ mb: 3, lineHeight: "2rem" }}>
              {title}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name={"name"}
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  label="Название"
                  placeholder="Введите название комплекта"
                  id="name"
                  type="text"
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.name?.message}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            {isGlobal && (
              <CurrencySelect
                containerStyle={{ mt: 4 }}
                label="Валюта комплекта"
                placeholder="Выберите валюту"
                currencies={currencies}
                value={generalCurrencyId}
                setValue={(v) => {
                  setGeneralCurrencyId(v);
                }}
              />
            )}
          </form>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 4, sm: 6 }, justifyContent: "center" }}>
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            variant="contained"
            sx={{ mr: 2 }}
            type="submit"
            onClick={onSubmit}
          >
            Добавить
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}



export default AddKitModal;
