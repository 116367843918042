import axios from "axios";

class Card {
  getById(id) {
    return axios.get(`/cards/${id}`);
  }
  add(data) {
    return axios.post("/cards", data);
  }
  change(data) {
    return axios.put("/cards", data);
  }
  search(params) {
    return axios.get("/cards/search", { params });
  }
  getList(params) {
    return axios.get("/cards", { params });
  }
  // change
  changeNumber(id, number) {
    return axios.put(`/cards/number`, { number, id });
  }
  verify(id) {
    return axios.put(`/cards/verify`, { id });
  }
  setCurrency(id, currency) {
    return axios.put(`/cards/currency`, { id, currency });
  }
  reject(id, comment) {
    return axios.put(`/cards/reject`, { id, comment });
  }
  ban(id) {
    return axios.put(`/cards/ban`, { id });
  }
  archive(id) {
    return axios.put(`/cards/archive`, { id });
  }
}

export default new Card();
