// ** MUI Imports
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Router

import { Link } from "react-router-dom";

function InfoRaw(props) {
  let {
    iconName,
    iconSize = 24,
    text,
    variantText = "body2",
    textColor,
    style = {},
    chip = null,
    link = "",
  } = props;

  // Calc
  let isLink = !!link;

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...style }}>
      <Icon fontSize={iconSize} icon={iconName} />
      <Typography
        color={isLink ? "primary" : textColor}
        component={isLink ? Link : undefined}
        to={link}
        sx={{ ml: 1.5 }}
        variant={variantText}
      >
        {text}
      </Typography>
      {chip}
    </Box>
  );
}

export default InfoRaw;
