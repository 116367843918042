// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";

const Avatar = styled(CustomAvatar)(({ theme }) => ({
  width: 35,
  height: 35,
  //   marginRight: theme.spacing(4),
}));

function StepTitle(props) {
  let { number, text, style = {}, disabledComponent = false } = props;

  let addedStyles = {};

  if (disabledComponent) {
    addedStyles = {
      "pointer-events": "none",
      cursor: "default",
      opacity: 0.32,
    };
  }

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", ...addedStyles, ...style }}
    >
      <Avatar skin="light" color="primary" variant="rounded">
        <Typography color="primary" fontWeight="bold">
          {number}
        </Typography>
      </Avatar>
      <Typography sx={{ ml: 2 }} variant="body1">
        {text}
      </Typography>
    </Box>
  );
}

export default StepTitle;
