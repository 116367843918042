import axios from "axios";

class Auth {
  login(data) {
    return axios.post("/admin/login", data);
  }
  getProfile() {
    return axios.get("/admin/profile");
  }
  createAdmin(data) {
    return axios.post("/admin", data);
  }
  changeAdmin(data) {
    return axios.put("/admin", data);
  }
  changeAdminPassword(data) {
    return axios.put("/admin/password", data);
  }
  getAdminList() {
    return axios.get("/admin");
  }
  getAdminOne(id) {
    return axios.get("/admin/one", { params: { id: id } });
  }
  // Session
  adminGetSession(id) {
    return axios.get("/admin/session", { params: { id: id } });
  }
  adminResetSession(id) {
    return axios.put("/admin/session", { id });
  }
}

export default new Auth();
