import { useState, useEffect } from "react";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Components
import ClientService from "src/service/Client";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { toast } from "react-hot-toast";

function ClientReferrer(props) {
  let { clientId, dncr, updateData, referrerEmail, referrerId } = props;

  const changeDncrHandler = () => {
    let newDncr = !dncr;
    ClientService.change(clientId, { dncr: newDncr }).then(() => {
      updateData({ dncr: newDncr });
      toast.success(
        `Начисление рефералу ${newDncr ? "выключено" : "включено"}!`
      );
    });
  };

  return (
    <CardContent sx={{ my: 1 }}>
      <Divider sx={{ mt: (theme) => `${theme.spacing(2)} !important` }} />
      <Typography sx={{ mb: 2 }} variant="subtitle1">
        Реферал
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1">{referrerEmail}</Typography>
        <IconButton
          onClick={changeDncrHandler}
          color={dncr ? "error" : "success"}
          sx={{ borderRadius: 1 }}
        >
          <Icon
            icon={dncr ? "tabler:cash-banknote-off" : "tabler:cash-banknote"}
          />
        </IconButton>
      </Box>
    </CardContent>
  );
}

export default ClientReferrer;
