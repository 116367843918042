import React, { useState, useEffect, useCallback } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Router
import { useParams } from "react-router-dom";

// ** Config Websites
import WEBSITES from "src/configs/websites";

// ** Toaster Import
import toast from "react-hot-toast";

// Services
import ContentService from "src/service/Content";

// Modules
import CustomEditor from "src/modules/Editor";
import { DialogContent } from "@mui/material";

// Form
import { useForm, Controller } from "react-hook-form";

// Timers
let timer1;
let timer2;

function FAQ() {
  // State
  const [showEdit, setShowEdit] = useState(false);
  let [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const params = useParams();

  const site = WEBSITES.find((s) => s.code === params.site);

  let siteCode = site.code;

  // API
  useEffect(() => {
    ContentService.getAllArticle(site.id, "faq").then((r) => {
      setList(r.data);
    });
  }, [siteCode]);

  // Vars
  const columns = [
    {
      flex: 0.45,
      field: "title.rus",
      headerName: "РУС",
      minWidth: 200,
      renderCell: ({ row }) => row.title.rus,
    },
    {
      flex: 0.45,
      field: "title.eng",
      headerName: "ENG",
      minWidth: 200,
      renderCell: ({ row }) => row.title.eng,
    },
    {
      flex: 0.1,
      field: "actions",
      headerName: "Действие",
      minWidth: 100,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              setSelectedItem(row);
              setShowEdit(true);
            }}
          >
            <Icon icon="mdi:pencil-outline" />
          </IconButton>
          <IconButton onClick={() => deleteItemHandler(row.id)}>
            <Icon icon="mdi:delete-outline" />
          </IconButton>
        </Box>
      ),
    },
  ];

  // ** Handlers
  const handleShowEditToggle = () => {
    setShowEdit(!showEdit);
  };

  const addItemHandler = useCallback((item) => {
    setList([item, ...list]);
  });
  const changeItemHandler = useCallback((item) => {
    setList((list) => list.map((i) => (i._id === item._id ? item : i)));
  });

  const deleteItemHandler = useCallback((id) => {
    ContentService.deleteArticle(id).then(() => {
      setList((list) => list.filter((i) => i._id !== id));
      toast.success("FAQ удалена!");
    });
  });

  return (
    <Card>
      <EditNewsDialog
        siteName={site.name}
        sc={site.id}
        data={selectedItem}
        show={showEdit}
        toggleShow={() => {
          handleShowEditToggle();
          setSelectedItem(null);
        }}
        addItem={addItemHandler}
        changeItem={changeItemHandler}
      />
      <CardHeader
        title={`FAQ (${site.name})`}
        action={
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setSelectedItem(null);
                handleShowEditToggle();
              }}
            >
              Добавить
            </Button>
          </div>
        }
      />
      <Box sx={{ height: 500 }}>
        <DataGrid
          components={{
            noRowsOverlay: <p>wd</p>,
          }}
          hideFooterPagination
          disableSelectionOnClick
          columns={columns}
          rows={list.map((i) => Object.assign({}, i, { id: i._id }))}
        />
      </Box>
    </Card>
  );
}

function EditNewsDialog({
  show,
  toggleShow,
  data,
  sc,
  siteName,
  addItem,
  changeItem,
}) {
  // ** States
  const [isSubmiting, setSubmiting] = useState(false);

  let [body, setBody] = useState({
    rus: data ? data.body.rus : "",
    eng: data ? data.body.eng : "",
  });
  // Errors state
  let [rusError, setRusError] = useState(false);
  let [engError, setEngError] = useState(false);

  // Validation

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      rusTitle: data ? data.title.rus : "",
      engTitle: data ? data.title.eng : "",
    },
  });

  // Hooks
  useEffect(() => {
    if (data && show) {
      setValue("rusTitle", data.title.rus);
      setValue("engTitle", data.title.eng);
      setBody({
        rus: data.body.rus,
        eng: data.body.eng,
      });
    }
  }, [show]);

  // Handlers
  const changeBodyHandler = (name, value) => {
    setBody(Object.assign({}, body, { [name]: value }));
  };

  const closeHandler = () => {
    toggleShow();
    reset();
    setBody({
      rus: "",
      eng: "",
    });
  };

  //
  let isChange = !!data;
  let itemId = data?._id;

  const onSubmit = handleSubmit((data) => {
    // validate body
    if (!body.rus) {
      setRusError(true);
      clearTimeout(timer1);
      timer1 = setTimeout(() => {
        setRusError(false);
      }, 3000);
      return;
    }

    if (!body.eng) {
      setEngError(true);
      clearTimeout(timer2);
      timer2 = setTimeout(() => {
        setEngError(false);
      }, 3000);
      return;
    }

    let fetchData = {
      title: {
        rus: data.rusTitle,
        eng: data.engTitle,
      },
      body: {
        rus: body.rus,
        eng: body.eng,
      },
    };

    if (!isChange) {
      fetchData.sc = sc;
      fetchData.destination = "faq";
    } else {
      fetchData._id = itemId;
    }

    let fetchFunc = isChange
      ? ContentService.changeArticle
      : ContentService.createArticle;

    setSubmiting(true);

    fetchFunc(fetchData)
      .then((r) => {
        if (isChange) {
          changeItem(r.data);
          toast.success("FAQ изменена!");
        } else {
          addItem(r.data);
          toast.success("FAQ создана!");
        }
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <>
      <Dialog
        fullScreen
        onClose={closeHandler}
        aria-labelledby="full-screen-dialog-title"
        open={show}
      >
        <DialogTitle id="full-screen-dialog-title">
          <Typography variant="h5" component="span">
            {data ? "Изменить" : "Добавить"} FAQ ({siteName})
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeHandler}
            sx={{ top: 8, right: 10, position: "absolute", color: "grey.500" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box component="form" noValidate onSubmit={onSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ mb: 4 }} variant="body1">
                  Русский
                </Typography>
                <Controller
                  name={"rusTitle"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ mb: 4 }}
                      fullWidth
                      label="РУС"
                      placeholder="Введите вопрос на русском"
                      id="rusTitle"
                      type="text"
                      error={Boolean(errors.rusTitle?.message)}
                      {...register("rusTitle", {
                        required: "Обязательное поле!",
                      })}
                      helperText={errors.rusTitle?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <Collapse in={rusError} sx={{ mb: 3 }}>
                  <Alert severity="error">Введите ответ!</Alert>
                </Collapse>

                <CustomEditor
                  value={data ? data.body.rus : ""}
                  onChange={(v) => changeBodyHandler("rus", v)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ mb: 4 }} variant="body1">
                  English
                </Typography>
                <Controller
                  name={"engTitle"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ mb: 4 }}
                      fullWidth
                      label="ENG"
                      placeholder="Введите вопрос на английском"
                      id="engTitle"
                      type="text"
                      error={Boolean(errors.engTitle?.message)}
                      {...register("engTitle", {
                        required: "Обязательное поле!",
                      })}
                      helperText={errors.engTitle?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <Collapse in={engError} sx={{ mb: 3 }}>
                  <Alert severity="error">Введите ответ!</Alert>
                </Collapse>
                <CustomEditor
                  value={data ? data.body.eng : ""}
                  onChange={(v) => changeBodyHandler("eng", v)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            p: (theme) => `${theme.spacing(3)} !important`,
          }}
        >
          <Button
            type="reset"
            size="large"
            variant="outlined"
            color="secondary"
            onClick={closeHandler}
          >
            Отменить
          </Button>
          <Button
            onClick={onSubmit}
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            size="large"
            type="submit"
            variant="contained"
          >
            {data ? "Сохранить" : "Добавить"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FAQ;
