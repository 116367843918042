// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";

// route
import { Link, useNavigate } from "react-router-dom";

// ** Services
import ClientService from "src/service/Client";

// Event
import emitter from "src/EventEmitter";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Third Party Imports
import { useForm, Controller } from "react-hook-form";

// TOast
import toast from "react-hot-toast";

// Config
import WEBSITES from "src/configs/websites";

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3, 4),
  justifyContent: "space-between",
  backgroundColor: theme.palette.background.default,
}));

const defaultValues = {
  email: "",
  name: "",
  password: "",
  telegram: "",
};

const AddClient = (props) => {
  // ** Props
  const { open, toggle } = props;

  const navigate = useNavigate();

  // ** State
  const [site, setSite] = useState(WEBSITES[0].id);
  const [isSubmiting, setSubmiting] = useState(false);

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const handleClose = () => {
    toggle();
    reset();
  };

  const onSubmit = handleSubmit((data) => {
    let fetchObj = Object.assign({}, data, { sc: site });

    setSubmiting(true);

    ClientService.add(fetchObj)
      .then((r) => {
        let result = r.data;

        emitter.emit("snackbar", {
          open: true,
          message: "Пользователь успешно создан",
          severity: "success",
        });
        handleClose();
        navigate(`/clients/${result._id}`);
      })
      .catch((e) => {
        let message = e?.response?.data?.message;


        emitter.emit("snackbar", {
          open: true,
          message,
          severity: "error",
        });
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Drawer
      open={open}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: true }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: 300, sm: 400 } } }}
    >
      <Header>
        <Typography variant="h6">Добавить пользователя</Typography>
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Header>
      <Box sx={{ p: 5 }}>
        <form noValidate onSubmit={onSubmit}>
          <Controller
            name={"email"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                autoFocus
                fullWidth
                placeholder="test@example.com"
                id="email"
                type="email"
                label="Email"
                sx={{ mb: 4 }}
                error={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "Введите Email!",
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Некорректный Email!",
                  },
                })}
                helperText={errors.email?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"name"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                placeholder="Ivan Ivanov"
                fullWidth
                id="name"
                type="text"
                label="Имя"
                sx={{ mb: 4 }}
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Введите Имя!",
                })}
                helperText={errors.name?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"telegram"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                placeholder="@nickname (необязательно)"
                fullWidth
                id="telegram"
                type="text"
                label="Ник Telegram"
                sx={{ mb: 4 }}
                error={Boolean(errors.telegram?.message)}
                {...register("telegram", {})}
                helperText={errors.telegram?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"password"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                placeholder="password"
                fullWidth
                id="password"
                type="text"
                label="Пароль"
                sx={{ mb: 4 }}
                error={Boolean(errors.password?.message)}
                {...register("password", {
                  required: "Введите Пароль!",
                  minLength: {
                    message: "Мин. длина: 6 символов",
                    value: 6,
                  },
                  maxLength: 100,
                })}
                helperText={errors.password?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel id="site-select">Сайт</InputLabel>
            <Select
              fullWidth
              value={site}
              id="site-select"
              label="Сайт"
              labelId="site-select"
              onChange={(e) => setSite(e.target.value)}
              inputProps={{ placeholder: "Выберите сайт" }}
            >
              {WEBSITES.map((s) => {
                return (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
              size="large"
              fullWidth
              type="submit"
              variant="contained"
            >
              Добавить
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default AddClient;
