import { useState, useCallback } from "react";

// Mui Imports
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// Configs
import WEBSITES from "src/configs/websites";

// ** Icon Imports
import Icon from "src/@core/components/icon";

const SiteFilterList = [
  {
    value: "",
    label: "Все",
  },
  ...WEBSITES.map((s) => {
    return {
      value: s.id,
      label: s.name,
    };
  }),
];

const StatusFilterList = [
  {
    value: "",
    label: "Все",
  },
  {
    value: "default",
    label: "Активный",
  },

  {
    value: "blocked",
    label: "Заблокирован",
  },
  {
    value: "deleted",
    label: "Удален",
  },
];

const RequisiteStatusList = [
  {
    value: "",
    label: "Все",
  },
  {
    value: "default",
    label: "Обычный",
  },
  {
    value: "permanent",
    label: "Постоянный",
  },
  {
    value: "risk",
    label: "С высоким риском",
  },
];

const VerifyStatusList = [
  {
    value: "",
    label: "Все",
  },
  {
    value: "verified",
    label: "Верифицированный",
  },
  {
    value: "unverified",
    label: "Не верифицированный",
  },
];

const Filter = ({ data, handleChange, handleClear, handleFind }) => {
  // const handleStatusChange = useCallback((e) => {
  //   setStatus(e.target.value);
  // });

  // const handleSiteChange = useCallback((e) => {
  //   setSite(e.target.value);
  // }, []);

  // const handleRequisiteChange = useCallback((e) => {
  //   setRequisite(e.target.value);
  // });

  // const handleVerifyChange = useCallback((e) => {
  //   setVerify(e.target.value);
  // });

  return (
    <CardContent>
      <Grid container spacing={6}>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="site-select">Сайт</InputLabel>
            <Select
              fullWidth
              value={data.site}
              id="select-site"
              label="Сайт"
              labelId="site-select"
              onChange={(e) => handleChange("site", e.target.value)}
              inputProps={{ placeholder: "Выберите сайт" }}
            >
              {SiteFilterList.map((s) => {
                return (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="status-client">Статус</InputLabel>
            <Select
              fullWidth
              value={data.status}
              id="status-client"
              label="Статус"
              labelId="tatus-client"
              onChange={(e) => handleChange("status", e.target.value)}
              inputProps={{ placeholder: "Выберите статус" }}
            >
              {StatusFilterList.map((s) => {
                return (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="requisite-status">Лояльность</InputLabel>
            <Select
              fullWidth
              value={data.loyalty}
              id="requisite-status"
              label="Лояльность"
              labelId="requisite-status"
              onChange={(e) => handleChange("loyalty", e.target.value)}
              inputProps={{ placeholder: "Выберите статус" }}
            >
              {RequisiteStatusList.map((s) => {
                return (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="email-verify">Верификация почты</InputLabel>
            <Select
              fullWidth
              value={data.verify}
              id="email-verify"
              label="Верификация почты"
              labelId="email-verify"
              onChange={(e) => handleChange("verify", e.target.value)}
              inputProps={{ placeholder: "Выберите статус" }}
            >
              {VerifyStatusList.map((s) => {
                return (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: "flex" }}>
        <Button
          endIcon={
            <Icon icon="material-symbols:find-in-page-outline-rounded" />
          }
          onClick={handleFind}
          variant="contained"
        >
          Применить
        </Button>
        <Button
          endIcon={<Icon icon="ic:outline-delete-outline" />}
          sx={{ ml: 4 }}
          onClick={handleClear}
          variant="outlined"
        >
          Очистить
        </Button>
      </Box>
    </CardContent>
  );
};

export default Filter;
