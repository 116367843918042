import { useState, useEffect } from "react";
// MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Modules
import AddToBlackList from "./AddToBlackList";
import TableBlacklist from "./TableBlacklist";

// Ui
import PageWrap from "src/components/PageWrap";

// Services
import BlacklistService from "src/service/Blacklist";

import { toast } from "react-hot-toast";

// Constants
const typesBlacklist = [
  {
    name: "Email",
    value: "email",
  },
  {
    name: "IP",
    value: "ip",
  },
  {
    name: "Реквизит",
    value: "requisite",
  },
];

function BlackList() {
  let [list, setList] = useState([]);

  // Hooks
  useEffect(() => {
    BlacklistService.getAll().then((r) => setList(r.data));
  }, []);

  // Handlers
  const addItemHandler = (item) => {
    setList((list) => [item, ...list]);
  };

  const deletItemHandler = (id) => {
    BlacklistService.delete(id).then(() => {
      setList((list) => list.filter((i) => i._id !== id));
      toast.success("Удалено из ЧС!");
    });
  };

  return (
    <PageWrap title="Черный список">
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <AddToBlackList
            addItemHandler={addItemHandler}
            typesBlacklist={typesBlacklist}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TableBlacklist
            deletItemHandler={deletItemHandler}
            typesBlacklist={typesBlacklist}
            list={list}
          />
        </Grid>
      </Grid>
    </PageWrap>
  );
}

export default BlackList;
