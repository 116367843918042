// Mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { IconButton } from "@mui/material";

// Modules
// import FilterBlacklist from "./FilterBlacklist";

const columns = [
  { id: "mean", label: "Вид", maxWidth: 170 },
  { id: "value", label: "Значение" },
  { id: "delete", label: "" },
];

function TableBlacklist(props) {
  let { list, typesBlacklist, deletItemHandler } = props;
  return (
    <Card>
      <CardHeader
        sx={{ pb: 3.25 }}
        title="Список"
        titleTypographyProps={{ variant: "h6" }}
      />
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => {
              let name = typesBlacklist.find((t) => t.value === row.mean).name;

              return (
                <TableRow
                  key={row._id}
                  sx={{
                    "&:last-of-type td, &:last-of-type th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell sx={{ py: "0px !important" }} align="right">
                    <IconButton
                      onClick={() => deletItemHandler(row._id)}
                      color="error"
                    >
                      <Icon icon="material-symbols:delete-forever-rounded" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default TableBlacklist;
