import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";


// Config
import ConfigApi from "src/configs/api";

// ** Icon
import Icon from "src/@core/components/icon";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

//Toast
import { toast } from "react-hot-toast";

// Services
import ExchangeService from "src/service/Exchange";

// Socket
import { socket } from "src/socket";

// Time
import moment from "moment";
import { IconButton } from "@mui/material";

// Modueles
import PairInfo from "./modules/PairInfo";
import Change from './modules/Change'

function CourseCard(props) {
  let { style, showTarget, data, deleteItem } = props;
  let [exchange, setExchange] = useState(data);

  let takeMainCur = exchange.tk.cur;
  let giveMainCur = exchange.gv.cur;

  // Hooks
  useEffect(() => {
    socket.on(`exchange_upd_${exchange._id}`, (e) => {
      setExchange(e);
    });
    socket.on(`exchange_upd_part_${exchange._id}`, (upd) => {
      setExchange((e) => {
        return Object.assign({}, e, upd);
      });
    });
    //
    return () => {
      socket.off(`exchange_upd_part_${exchange._id}`);
      socket.off(`exchange_upd_${exchange._id}`);
    };
  }, []);

  // Handlers
  const changeExchangeHandler = (upd) => {
    ExchangeService.changeExchange({
      _id: exchange._id,
      ...upd,
    }).then(() => {
      toast.success("Обменная пара изменена!");
    });
  };

  // //Calc
  // let isbn = exchange.rate.isbn;
  // let isChanged =
  //   +exchange.limit.mn !== +mn ||
  //   +exchange.limit.mx !== +mx ||
  //   +exchange.rate.prc !== +prc;

  return (
    <Card sx={{ ...style }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <PairInfo
              exchange={exchange}
              takeMainCur={takeMainCur}
              giveMainCur={giveMainCur}
            />
          </Grid>
          {/* <Divider orientation="vertical" flexItem /> */}
          <Grid item xs={12} md={7}>
            <Change
            exchange={exchange}
            changeExchangeHandler={changeExchangeHandler}
            />
            {/* <Grid container columns={15} alignItems="center" spacing={4}>
              <Grid item xs={1.5} sx={{ pr: 1 }}>
                <IconButton
                  sx={{
                    transform: `rotate(${exchange.rate.isbn ? 0 : 180}deg)`,
                    borderRadius: 0,
                    p: 0.5,
                    margin: "0px auto",
                  }}
                  onClick={() =>
                    changeExchangeHandler({
                      rate: {
                        isbn: !isbn,
                      },
                    })
                  }
                  color={exchange.rate.isbn ? "success" : "error"}
                >
                  <Icon icon="mdi:arrow-top-bold-box-outline" />
                </IconButton>
              </Grid>
              <Grid item xs={isChanged ? 4 : 4.5}>
                <TextField
                  size="small"
                  fullWidth
                  value={prc}
                  label="Процент"
                  type="number"
                  onChange={(e) => setPrc(e.target.value)}
                />
              </Grid>
              <Grid item xs={isChanged ? 4 : 4.5}>
                <TextField
                  size="small"
                  fullWidth
                  value={mn}
                  type="number"
                  label={`Мин. ${takeMainCur.code}`}
                  onChange={(e) => setMn(e.target.value)}
                />
              </Grid>
              <Grid item xs={isChanged ? 4 : 4.5}>
                <TextField
                  size="small"
                  fullWidth
                  value={mx}
                  type="number"
                  label={`Макс. ${takeMainCur.code}`}
                  onChange={(e) => setMx(e.target.value)}
                />
              </Grid>
              {isChanged && (
                <Grid item xs={1.5}>
                  <IconButton
                    onClick={() => {
                      changeExchangeHandler({
                        limit: {
                          mn: Math.abs(+mn),
                          mx: Math.abs(+mx),
                        },
                        rate: {
                          prc: Math.abs(+prc),
                        },
                      });
                    }}
                    sx={{
                      margin: "0px auto",
                      borderRadius: 0,
                      p: 0.5,
                    }}
                    color="primary"
                  >
                    <Icon icon="mingcute:save-line" />
                  </IconButton>
                </Grid>
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </CardContent>
      {/* {showTarget && <Divider sx={{ my: "0 !important" }} />}
      <Collapse in={showTarget} timeout="auto" unmountOnExit>
        <ExchangeTarget deleteItem={deleteItem} exchangeId={exchange._id} />
      </Collapse> */}
    </Card>
  );
}

function ExchangeTarget({ exchangeId, deleteItem }) {
  let [data, setData] = useState({
    target: 0,
    minPercent: 0,
    step: 0,
    enabled: false,
  });

  let [target, setTarget] = useState(0);
  let [minPercent, setMinPercent] = useState(0);
  let [step, setStep] = useState(0);
  // Hooks

  useEffect(() => {
    ExchangeService.getExchangeTarget(exchangeId).then((r) => {
      if (!r.data) return;
      setData(r.data);
      setTarget(r.data.target);
      setMinPercent(r.data.minPercent);
      setStep(r.data.step);
    });
  }, []);

  // Calc
  let isChanged =
    +data.target !== +target ||
    +data.minPercent !== +minPercent ||
    +data.step !== +step;

  let enabled = data.enabled;

  // Handlers
  const saveHandler = () => {
    ExchangeService.changeExchangeTarget(exchangeId, {
      target: +target,
      minPercent,
      step,
    }).then((r) => {
      setData(r.data);
      setTarget(r.data.target);
      setMinPercent(r.data.minPercent);
      setStep(r.data.step);
      toast.success("Данные сохраненны!");
    });
  };

  const deleteHandler = () => {
    if (!window.confirm("Удалить таргет?")) return;
    ExchangeService.deleteExchangeTarget(exchangeId).then((r) => {
      toast.success("Таргет удален!");
      deleteItem(exchangeId);
    });
    //
  };

  const toggleEnabled = (enabled) => {
    ExchangeService.changeExchangeTarget(exchangeId, {
      enabled,
    }).then((r) => {
      setData(Object.assign({}, data, { enabled }));
      toast.success(`Таргет ${enabled ? "включен" : "выключен"}!`);
    });
  };

  return (
    <CardContent>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              {/* <TextField
                size="small"
                fullWidth
                value={target}
                label="Цель"
                onChange={(e) => setTarget(e.target.value)}
              /> */}
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                value={step}
                label="Шаг"
                onChange={(e) => setStep(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                value={minPercent}
                label="Порог"
                onChange={(e) => setMinPercent(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                color={"error"}
                variant="outlined"
                onClick={deleteHandler}
                sx={{
                  borderRadius: 1,
                  mr: 4,
                  color: "error",
                }}
                fullWidth
              >
                <Icon icon={"mdi:trash"} />
              </IconButton>
              <IconButton
                color={enabled ? "primary" : "secondary"}
                variant="contained"
                onClick={() => toggleEnabled(!enabled)}
                sx={{
                  borderRadius: 1,
                  backgroundColor: (theme) => theme.palette.background.default,
                  color: enabled ? "primary.dark" : "secondary.light",
                }}
                fullWidth
              >
                <Icon
                  icon={
                    enabled
                      ? "pepicons-pop:power-circle-filled"
                      : "pepicons-pop:power-circle-off"
                  }
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {isChanged && (
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              sx={{ p: 1.5, minWidth: 38 }}
              color="primary"
              onClick={saveHandler}
            >
              <Icon icon="material-symbols:save-outline-rounded" />
            </Button>
          </Box>
        )}
      </Box>
    </CardContent>
  );
}

export default CourseCard;
