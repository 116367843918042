import axios from "axios";

class ExchangeApiSetting {
  getListExchanges() {
    return axios.get("/exchange-api-setting/exchange/codes");
  }
  addExchange(data) {
    return axios.post("/exchange-api-setting/exchange", data);
  }
  getExchanges() {
    return axios.get("/exchange-api-setting/exchange");
  }
  toggleExchange(data) {
    return axios.put("/exchange-api-setting/exchange", data);
  }
  deleteExchange(id) {
    return axios.delete("/exchange-api-setting/exchange", { data: { id } });
  }
  //
  getAllCurrenciesExchange(code) {
    return axios.get("/exchange-api-setting/currency/pairs", {
      params: { code: code },
    });
  }
  exchangeAddCurrency(data) {
    return axios.post("/exchange-api-setting/currency", data);
  }
  exchangeDeleteCurrency(data) {
    return axios.delete("/exchange-api-setting/currency", { data });
  }
  getConnectedCurrencies() {
    return axios.get("/exchange-api-setting/currency/connected");
  }
}

export default new ExchangeApiSetting();
