import { useEffect, useState } from "react";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import OrderService from "src/service/Order";

// Modules
import OrderCard from "src/modules/Orders/OrderCard/OrderCard";

// Socket
import { socket } from "src/socket";

function Orders(props) {
  let { clientId } = props;
  // States
  let [orders, setOrders] = useState([]);

  useEffect(() => {
    OrderService.getList({
      filter: {
        client: clientId,
      },
      pagination: {
        limit: 300,
        skip: 0,
      },
    }).then((r) => setOrders(r.data));

    socket.on("order_add", (o) => {
      setOrders((list) => [o, ...list]);
    });
    socket.on("order_upd_item", (u) => {
      setOrders((list) => {
        return list.map((o) => {
          return o._id == u._id ? Object.assign(o, u) : o;
        });
      });
    });
    return () => {
      socket.off("order_upd_item");
      socket.off("order_add");
    };
  }, []);

  return (
    <Box>
      <Grid container spacing={4}>
        {orders.map((o) => {
          return (
            <Grid item xs={12} md={6} key={o._id}>
              <OrderCard key={o._id} status={o?.status} data={o} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Orders;
