import { useState, useEffect } from "react";
// Mui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// ** Icon
import Icon from "src/@core/components/icon";

// Services
import WalletService from "src/service/Wallet";
import { toast } from "react-hot-toast";

function GeneralSelect({ currency, addItemHandler, deleteItemHandler }) {
  let [showKits, setShowKits] = useState(false);


  return (
    <Card sx={{ mt: 6 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">Общие комплекты</Typography>
          </Box>
          <IconButton onClick={() => setShowKits(!showKits)}>
            <Icon icon="material-symbols:expand-more-rounded" />
          </IconButton>
        </Box>
        <Collapse in={showKits} timeout="auto" unmountOnExit>
          <GeneralSelectContent
            addItemHandler={addItemHandler}
            deleteItemHandler={deleteItemHandler}
            currencyId={currency?._id}
            walletId={currency?.wallet?._id}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}

function GeneralSelectContent({ walletId, currencyId, deleteItemHandler,addItemHandler  }) {
  let [list, setList] = useState([]);

  // Hooks
  useEffect(() => {
    WalletService.getGeneralKits().then(({ data }) => {
      setList(data);
    });
  }, []);

  // Handlers
  const updateItemHandler = (upd) => {
    setList((list) => list.map((item) => (item._id == upd._id ? upd : item)));
  };

  return (
    <Box>
      <Grid container spacing={4}>
        {list.map((item) => {
          return (
            <Grid key={item._id} item xs={6} md={4}>
              <GeneralKitItem
                updateItem={updateItemHandler}
                currencyId={currencyId}
                walletId={walletId}
                data={item}
                addItemHandler={addItemHandler}
                deleteItemHandler={deleteItemHandler}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function GeneralKitItem(props) {
  let { data, walletId, currencyId, updateItem, addItemHandler, deleteItemHandler } = props;
  // States
  let isActive = data.wallets.find((w) => w.wallet == walletId);
  isActive = !!isActive;


  const toggleActivate = (checked) => {
    WalletService.activateGeneralKit({
      currencyId,
      kitId: data._id,
    }).then(({ data }) => {
      if(checked){
        addItemHandler(data)
      } else {
        deleteItemHandler(data._id)
      }
      updateItem(data);
      toast.success(`Общий комплект ${checked ? "включен" : "выключен"}`);
    });
    // console.log({ checked });
  };

  return (
    <FormControlLabel
      label={data.name}
      control={
        <Checkbox
          checked={isActive}
          onChange={(e) => toggleActivate(e.target.checked)}
          name={data._id}
        />
      }
    />
  );
}

export default GeneralSelect;
