import { useState, useEffect } from "react";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import MuiTimeline from "@mui/lab/Timeline";

// ** Icon Imports
import Icon from "src/@core/components/icon";

import moment from "moment";

// Services
import HistoryService from "src/service/History";

// Styled Timeline component
const Timeline = styled(MuiTimeline)({
  paddingLeft: 0,
  paddingRight: 0,
  "& .MuiTimelineItem-root": {
    width: "100%",
    "&:before": {
      display: "none",
    },
  },
});

function GeneralHistory(props) {
  let { id, mode } = props;

  let [loaded, setLoaded] = useState(false);
  let [list, setList] = useState([]);

  useEffect(() => {
    HistoryService.generalsGet({
      id,
      mode,
    }).then((r) => {
      setList(r.data);
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return (
      <Box
        sx={{
          display: "flex",
          mt: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Timeline>
        {list.map((h, i) => {
          return (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined" />
                {list.length == i + 1 ? null  : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent
                sx={{ "& svg": { verticalAlign: "bottom", mx: 4 } }}
              >
                <Typography variant="body2" sx={{ color: "text.primary" }}>
                  <span>{h.text}</span>
                </Typography>
                <Typography variant="caption">
                  {moment(h.createdAt).format("DD.MM.YYYY, HH:mm")}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
}

export default GeneralHistory;
