import { useState } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

// ** Icon
import Icon from "src/@core/components/icon";
import { Collapse } from "@mui/material";

// Helpers
import addNestedField from "src/helpers/addNestedField";

// Toast
import { toast } from "react-hot-toast";

// Exchange Service
import ExchangeService from "src/service/Exchange";

function Control(props) {
  let {
    showTarget,
    setShowTarget,
    quoteSearch,
    setQuoteSearch,
    currency,
    site,
    onlyTarget,
    setOnlyTarget,
  } = props;
  // State
  let [isCommon, setIsCommon] = useState(false);
  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={6} md={9}>
            {/* <FormControlLabel
              label="Таргет"
              control={
                <Checkbox
                  checked={showTarget}
                  onChange={(e) => setShowTarget(e.target.checked)}
                  name="basic-checked"
                />
              }
            /> */}

            {/* {showTarget && (
              <FormControlLabel
                label="Только таргетируемые"
                control={
                  <Checkbox
                    checked={onlyTarget}
                    onChange={(e) => setOnlyTarget(e.target.checked)}
                    name="basic-checked"
                  />
                }
              />
            )} */}
            <FormControlLabel
              label="Общее"
              control={
                <Checkbox
                  checked={isCommon}
                  onChange={(e) => setIsCommon(e.target.checked)}
                  name="basic-checked"
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              size="small"
              fullWidth
              value={quoteSearch}
              placeholder="Поиск котируемой валюты"
              onChange={(e) => setQuoteSearch(e.target.value)}
            />
          </Grid>
        </Grid>

        <Collapse in={isCommon && !!currency} timeout="auto" unmountOnExit>
          <CommonSetExchange site={site} currency={currency} />
        </Collapse>
      </CardContent>
    </Card>
  );
}

function CommonSetExchange({ currency, site }) {
  let [useIsbn, setUseIsbn] = useState(false);
  let [isbn, setIsbn] = useState(false);
  let [prc, setPrc] = useState("");
  let [mn, setMn] = useState("");
  let [mx, setMx] = useState("");

  let main = currency.cur;

  // Handlers
  const changeExchangeHandler = () => {
    // check box
    let update = {};
    if (useIsbn) {
      update = addNestedField(isbn, "rate.isbn", update);
    }
    if (prc !== "") {
      update = addNestedField(+prc, "rate.prc", update);
    }
    if (mn !== "") {
      update = addNestedField(+mn, "limit.mn", update);
    }
    if (mx !== "") {
      update = addNestedField(+mx, "limit.mx", update);
    }

    ExchangeService.changeListExchange({
      sc: site,
      tk: currency._id,
      update,
    }).then(() => {
      toast.success("Обменные пары измененны!");
      clearFields();
    });
  };

  const clearFields = () => {
    setPrc("");
    setMn("");
    setMx("");
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Установить для всех пар
      </Typography>
      <Grid container columns={17} spacing={2} justifyContent="space-between">
        <Grid item xs={3} md={2}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <IconButton
              sx={{
                transform: `rotate(${isbn ? 0 : 180}deg)`,
                margin: "0px auto",
              }}
              onClick={() => {
                setIsbn(!isbn);
              }}
              color={isbn ? "success" : "error"}
            >
              <Icon icon="mdi:arrow-top-bold-box-outline" />
            </IconButton>
            <Checkbox
              checked={useIsbn}
              onChange={(e) => setUseIsbn(e.target.checked)}
              sx={{ mr: 1 }}
              name="basic-checked"
            />
          </Box>
        </Grid>
        <Grid item xs={4} md={4.3}>
          <TextField
            size="small"
            fullWidth
            value={prc}
            label="Процент"
            type="number"
            onChange={(e) => setPrc(e.target.value)}
          />
        </Grid>
        <Grid item xs={4} md={4.3}>
          <TextField
            size="small"
            fullWidth
            value={mn}
            type="number"
            label={`Мин. ${main.code}`}
            onChange={(e) => setMn(e.target.value)}
          />
        </Grid>
        <Grid item xs={4} md={4.3}>
          <TextField
            size="small"
            fullWidth
            value={mx}
            type="number"
            label={`Макс. ${main.code}`}
            onChange={(e) => setMx(e.target.value)}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <IconButton onClick={changeExchangeHandler} color="primary">
            <Icon icon="mingcute:save-line" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Control;
