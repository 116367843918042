import axios from "axios";

class Debt {
  getPreCreateInfo(orderId) {
    return axios.get("/debt/pre-create", { params: { orderId } });
  }
  create(data) {
    return axios.post("/debt", data);
  }
  getAll() {
    return axios.get("/debt");
  }
  getOne(id) {
    return axios.get("/debt/one", { params: { id } });
  }
  changeOne(data) {
    return axios.put("/debt", data);
  }
}

export default new Debt();
