// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";
import FormControlLabel from "@mui/material/FormControlLabel";

// Services
import ServiceRolePermission from "src/service/RolePermission";
import { CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";

const roleList = [
  {
    name: "Заявки/Видимость за предыдущие дни, включая текущий (если 0 - без ограничений)",
    value: "order.view-days",
    isNumber: true,
  },
  {
    name: "Заявки/Отображать реферальные начисления",
    value: "order.view-referral-accrual",
  },
  {
    name: "Панель/Статистика/Счетчики",
    value: "dashboard.stats.сounts",
  },
  {
    name: "Панель/Статистика/Счетчики финансов",
    value: "dashboard.stats.finance-сounts",
  },
  {
    name: "Валюты",
    value: "currencies",
  },
  {
    name: "Настройки/Черный список",
    value: "settings.black-list",
  },
  {
    name: "Настройки/Комментарии к карте",
    value: "settings.card-comments",
  },
  {
    name: "Настройки/Валюты и курсы",
    value: "settings.currency-and-rate",
  },
  {
    name: "Настройки/Системные настройки",
    value: "settings.system-settings",
  },
  {
    name: "Настройки/Локализация",
    value: "settings.localization",
  },
  {
    name: "Контент",
    value: "content",
  },
  {
    name: "Вознаграждения",
    value: "rewards",
  },
  {
    name: "Финансы/Долги",
    value: "finance.debts",
  },
  {
    name: "Финансы/Статистика",
    value: "finance.statistics",
  },
  {
    name: "История заявки",
    value: "history.one-order",
  },
];

let initObj = arrayToObject(roleList);

const RolesCards = (props) => {
  let { data, open, closeHandler, addItemHandler, changeItemHandler } = props;

  // Calc
  let isNew = !data;
  // ** States

  const [name, setName] = useState("");
  const [settingObj, setSettingObj] = useState(initObj);
  const [isSubmitting, setSubmitting] = useState(false);
  const [nameError, setNameError] = useState("");

  //Hooks
  useEffect(() => {
    if (!data) return;
    let dataSetObj = data.rules.reduce(
      (obj, cur) => ({ ...obj, [cur.code]: cur.value }),
      {}
    );
    setSettingObj(dataSetObj);
    setName(data.name);
  }, [data]);

  const handleClose = () => {
    closeHandler();
    setSettingObj(initObj);
    setName("");
  };

  const changeObjectValue = (id, value) => {
    setSettingObj((obj) => Object.assign({}, obj, { [id]: value }));
  };
  const changeNameHandler = (text) => {
    setNameError("");
    setName(text);
  };

  const createHandler = () => {
    if (!name) {
      setNameError("Обязательное поле");
      return;
    }

    let rules = [];
    for (let code in settingObj) {
      let value = settingObj[code];
      rules.push({
        code,
        value,
      });
    }

    setSubmitting(true);

    if (isNew) {
      ServiceRolePermission.create({
        name,
        rules,
      })
        .then((r) => {
          addItemHandler(r.data);
          toast.success("Новая роль добавлена!");
          handleClose();
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      ServiceRolePermission.change({
        _id: data._id,
        name,
        rules,
      })
        .then((r) => {
          changeItemHandler(r.data);
          toast.success("Роль изменена!");
          handleClose();
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <Typography variant="h5" component="span">
            {`${isNew ? "Добавить" : "Изменить"} роль`}
          </Typography>
          <Typography variant="body2">Установите доступ для роли</Typography>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 6, sm: 12 } }}>
          <Box sx={{ my: 4 }}>
            <FormControl fullWidth>
              <TextField
                error={!!nameError}
                helperText={nameError}
                label="Название роли"
                placeholder="Введите название роли"
                value={name}
                onChange={(e) => changeNameHandler(e.target.value)}
              />
            </FormControl>
          </Box>
          <Typography variant="h6">Доступно для роли</Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {roleList.map((r, i) => {
                  let editor = (
                    <FormControlLabel
                      label={r.name}
                      control={
                        <Checkbox
                          size="small"
                          id={r.value}
                          onChange={() =>
                            changeObjectValue(r.value, !settingObj[r.value])
                          }
                          checked={settingObj[r.value]}
                        />
                      }
                    />
                  );

                  if (r.isNumber) {
                    editor = (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          value={settingObj[r.value]}
                          onChange={(e) =>
                            changeObjectValue(r.value, e.target.value)
                          }
                          type="number"
                          sx={{ width: "70px" }}
                          size="small"
                        />
                        <Typography sx={{ ml: 3, wordBreak: "break-all" }}>
                          {r.name}
                        </Typography>
                      </Box>
                    );
                  }

                  return (
                    <TableRow key={i}>
                      <TableCell
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        {editor}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions
          sx={{ pt: 0, display: "flex", justifyContent: "center" }}
        >
          <Box className="demo-space-x">
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={createHandler}
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress /> : null}
            >
              Сохранить
            </Button>
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            >
              Отменить
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function arrayToObject(array) {
  let result = {};

  array.forEach((i) => {
    result[i.value] = i.isNumber ? 0 : false;
  });

  return result;
}

export default RolesCards;
