import { useState } from "react";
//MUi
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { DialogContent } from "@mui/material";

// Toast
import { toast } from "react-hot-toast";

// Validation
import { useForm, Controller } from "react-hook-form";

// Services
import WalletService from "src/service/Wallet";

function DialogChangeBalance(props) {
  let { action, show, closeHandler, isKit, _id } = props;
  // States
  const [isSubmiting, setSubmiting] = useState(false);

  let title = "";
  let submitBtn = "";

  (function () {
    switch (action) {
      case "add":
        title = "Добавить сумму";
        submitBtn = "Добавить";
        break;
      case "sub":
        title = "Вычесть сумму";
        submitBtn = "Вывести";
        break;
    }
  })();

  // Validation
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      sum: "",
      comment: "",
    },
  });

  const onSubmit = handleSubmit((d) => {

    setSubmiting(true);
    WalletService.changeBalance({
      ...d,
      sum: action == "add" ? Math.abs(d.sum) : -Math.abs(d.sum),
      isKit,
      _id,
    })
      .then(() => {
        handleClose();
        toast.success("Баланс изменен!");
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  // Handlers
  const handleClose = () => {
    reset();
    closeHandler();
  };

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={show} onClose={handleClose}>
        <DialogContent
          sx={{
            px: { xs: 8, sm: 12 },
            py: { xs: 8, sm: 12 },
            position: "relative",
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography variant="h6" sx={{ mb: 3, lineHeight: "2rem" }}>
              {title}
            </Typography>
          </Box>
          <form noValidate>
            <Controller
              name={"sum"}
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  label="Сумма"
                  placeholder="Введите сумму"
                  id="sum"
                  type="number"
                  error={Boolean(errors.sum?.message)}
                  {...register("sum", {
                    required: "Обязательное поле!",
                    validate: (v) => {
                      if (v < 0 || v == 0) {
                        return "Значение некорректно!";
                      }
                    },
                  })}
                  helperText={errors.sum?.message}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <Controller
              name={"comment"}
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{ mt: 4 }}
                  multiline
                  rows={4}
                  fullWidth
                  label="Комментарий"
                  placeholder="Введите комментарий"
                  id="comment"
                  type="number"
                  error={Boolean(errors.comment?.message)}
                  {...register("comment", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.comment?.message}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 4, sm: 6 }, justifyContent: "center" }}>
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            variant="contained"
            sx={{ mr: 2 }}
            type="submit"
            onClick={onSubmit}
          >
            {submitBtn}
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DialogChangeBalance;
