import { useState, useEffect } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { useMediaQuery, useTheme } from "@mui/material";
// Custom Components
import PageWrap from "src/components/PageWrap";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Config
import WEBSITES from "src/configs/websites";

// Utils
import getSiteById from "src/utils/site/getSiteById";
import searchCourse from "src/utils/courses/searchCourse";

// Services
import ExchangeService from "src/service/Exchange";

// Modules
import TargetCard from "./TargetCard";

// Components
import SelectSiteList from "src/components/Site/SelectSiteList";
import SelectSite from "src/components/Site/SelectSite";
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";

// Services
import SiteCurrencyService from "src/service/SiteCurrency";

// Notify
import toast from "react-hot-toast";

function Target() {
  const [selectedSiteId, setSelectedSiteId] = useState(WEBSITES[0].id);

  // Hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PageWrap title={`Таргет`}>
      <Grid container spacing={4}>
        {/* <Grid item xs={12} md={3}>
          <Card
            sx={{
              position: "sticky",
              top: "70px",
              py: 3,
              px: isMobile ? 3 : 0,
            }}
          >
            {isMobile ? (
              <SelectSite value={selectedSiteId} onChange={setSelectedSiteId} />
            ) : (
              <SelectSiteList
                value={selectedSiteId}
                onChange={setSelectedSiteId}
              />
            )}
          </Card>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <SiteTargetCourses id={selectedSiteId} />
        </Grid>
      </Grid>
    </PageWrap>
  );
}

function SiteTargetCourses({ id }) {
  let [list, setList] = useState([]);
  let [search, setSearch] = useState("");
  let [open, setOpen] = useState(false);

  // Calc
  let courses = searchCourse(list, search);

  // Handlers
  const addCourse = (c) => {
    setList((list) => [c, ...list]);
  };

  const deleteCourse = (excId) => {
    setList((list) => list.filter((e) => e._id !== excId));
  };

  // hooks
  useEffect(() => {
    ExchangeService.getExchangeList({
      sc: id,
      enbl: true,
      target: { $exists: true },
    }).then((r) => {
      setList(r.data);
    });
  }, [id]);

  return (
    <Box>
      <ModalAddTarget
        addExchange={addCourse}
        id={id}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <Card sx={{ mb: 4 }}>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            py: `16px !important`,
          }}
        >
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setOpen(true)}
                startIcon={<Icon icon="mdi:delete-outline" />}
              >
                Добавить
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label="Поиск"
                size="small"
                fullWidth
                value={search}
                placeholder="Название валюты или код"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {courses.map((c) => (
        <TargetCard
          deleteItem={deleteCourse}
          key={c._id}
          data={c}
          style={{ mb: 2, bgcolor: (t) => t.pall }}
        />
      ))}
    </Box>
  );
}

function ModalAddTarget({ open, handleClose, id, addExchange }) {
  // State
  let [baseSiteCurrencies, setBaseSiteCurrencies] = useState([]);
  let [selectedBaseSiteCurrencyId, setSelectedBaseSiteCurrencyId] =
    useState("");
  let [selectedQuoteSiteCurrencyId, setSelectedQuoteSiteCurrencyId] =
    useState("");
  let [exchanges, setExchanges] = useState([]);
  let [isSubmiting, setIsSubmiting] = useState(false);
  // Hooks
  useEffect(() => {
    SiteCurrencyService.getSiteCurrencyList({ sc: id, enbl: true }).then((r) =>
      setBaseSiteCurrencies(r.data)
    );
  }, [id]);

  useEffect(() => {
    if (selectedBaseSiteCurrencyId) {
      setExchanges([]);
      ExchangeService.getExchangeList({
        sc: id,
        tk: selectedBaseSiteCurrencyId,
        enbl: true,
      }).then((r) => setExchanges(r.data));
    }

    return () => {
      setExchanges([]);
    };
  }, [selectedBaseSiteCurrencyId]);

  // // Calc
  let takeSiteCurrencies = baseSiteCurrencies.map((c) => {
    return {
      _id: c._id,
      name: c.cur.name,
      image: c.cur.image,
    };
  });

  let giveSiteCurrencies = exchanges.map((e) => {
    return {
      _id: e.gv._id,
      name: e.gv.cur.name,
      image: e.gv.cur.image,
    };
  });

  if (takeSiteCurrencies.length) {
    if (!selectedBaseSiteCurrencyId) {
      setSelectedBaseSiteCurrencyId(takeSiteCurrencies[0]._id);
    } else if (
      !takeSiteCurrencies.find((c) => c._id === selectedBaseSiteCurrencyId)
    ) {
      setSelectedBaseSiteCurrencyId(takeSiteCurrencies[0]._id);
    }
  }

  if (exchanges.length) {
    if (!selectedQuoteSiteCurrencyId) {
      setSelectedQuoteSiteCurrencyId(giveSiteCurrencies[0]._id);
    } else if (
      !giveSiteCurrencies.find((c) => c._id === selectedQuoteSiteCurrencyId)
    ) {
      setSelectedQuoteSiteCurrencyId(giveSiteCurrencies[0]._id);
    }
  }

  let exchange = exchanges.find((e) => {
    console.log(e.tk._id, "tk");
    return (
      e.tk._id + "" === selectedBaseSiteCurrencyId &&
      e.gv._id + "" === selectedQuoteSiteCurrencyId
    );
  });

  let hasExchange = !!exchange;

  // Handelrs
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmiting(true);
    ExchangeService.changeExchangeTarget(exchange._id, {
      target: 0,
      minPercent: 0,
      step: 0,
    })
      .then((r) => {
        addExchange(exchange);
        toast.success("Таргет добавлен!");
        handleClose();
      })
      .finally(() => setIsSubmiting(false));
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle id="max-width-dialog-title" textAlign="center">
        Добавить таргет
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <SimpleSelectCurrency
            label="Отдаю"
            containerStyle={{ mt: 2 }}
            currencies={takeSiteCurrencies}
            value={selectedBaseSiteCurrencyId}
            onChange={setSelectedBaseSiteCurrencyId}
          />
          <SimpleSelectCurrency
            label="Получаю"
            containerStyle={{ mt: 4 }}
            currencies={giveSiteCurrencies}
            value={selectedQuoteSiteCurrencyId}
            onChange={setSelectedQuoteSiteCurrencyId}
          />

          {hasExchange && (
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
                size="large"
                type="submit"
                variant="contained"
                sx={{ mr: 2 }}
              >
                Добавить
              </Button>
              <Button
                sx={{ mr: 2 }}
                type="reset"
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Box>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default Target;
