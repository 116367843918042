import axios from "axios";

class Reward {
  getList(params) {
    return axios.get("/reward", { params });
  }
  getOne(id) {
    return axios.get(`/reward/${id}`);
  }
  change(data){
    return axios.put(`/reward`, data);
  }
}



export default new Reward();
