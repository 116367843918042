import { useState, useEffect } from "react";
//Mui
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Configs
import WEBSITES from "src/configs/websites";
import { CardContent } from "@mui/material";

import ClientService from "src/service/Client";

import { sv } from "date-fns/locale";

// Modules
import CalcOrder from "./CalcOrder";

;

// Calc
const SiteSelectList = WEBSITES.map((s) => {
  return {
    value: s.id,
    label: s.name,
  };
});

function AddOrderDialog(props) {
  let { show, handleClose, clientData } = props;
  // States
  //   let [site, setSite] = useState(SiteSelectList[0].value);

  const [isSubmiting, setSubmiting] = useState(false);
  const [selectClientError, setSelectClientError] = useState("");
  const [users, setUsers] = useState([]);
  const [client, setClient] = useState(clientData || null);

  function closeHandler() {
    handleClose();
  }

  //Calc

  // Handlers
  const handleClientSearchChange = (event, value) => {
    setSelectClientError("");
    if (value.length < 3) return;
    ClientService.search(value).then((r) => {
      let list = r.data.map((c) => {
        let siteName = SiteSelectList.find((s) => s.value == c.sc)?.label;
        return {
          title: `${c.email} | (${siteName})`,
          value: c._id,
          data: c,
        };
      });
      setUsers(list);
    });
  };

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: "500px" } }}
      open={show}
      onClose={handleClose}
    >
      <DialogTitle sx={{ textAlign: "center", fontSize: "1.5rem !important" }}>
        Создать заявку
      </DialogTitle>
      <form noValidate>
        <DialogContent>
          {!clientData && (
            <Autocomplete
              sx={{ mt: 4 }}
              options={users}
              fullWidth
              value={client?._id}
              onChange={(e, v) => {
                setClient(v ? v.data : null);
              }}
              onInputChange={handleClientSearchChange}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Клиент"
                  error={Boolean(selectClientError)}
                  helperText={selectClientError}
                />
              )}
            />
          )}
          {client && <CalcOrder sc={client.sc} handleClose={handleClose}
          clientId={client?._id}
          />}
        </DialogContent>
      </form>
    </Dialog>
  );
}

export default AddOrderDialog;
