import { useState, useEffect } from "react";

// *MUI*
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Store
import { observer } from "mobx-react-lite";
import ServiceSettingsStore from "src/store/ServiceSettings";

function SwitchCurrencyValue({ sx = {} }) {
  let cur = ServiceSettingsStore.getSettlementСurrency;

  const handleAlignment = (event, cur) => {
    if (!cur) return;
    ServiceSettingsStore.setSettlementСurrency(cur);
  };

  return (
    <ToggleButtonGroup
      sx={sx}
      value={cur}
      exclusive
      size="small"
      onChange={handleAlignment}
      aria-label="select cur"
    >
      <ToggleButton value="RUB" aria-label="RUB CUR">
        <Icon icon="mingcute:currency-rubel-line" />
      </ToggleButton>
      <ToggleButton value="USD" aria-label="USD CUR">
        <Icon icon="uil:usd-circle" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default observer(SwitchCurrencyValue);
