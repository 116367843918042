import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Modules
import GeneralHistory from "src/modules/GeneralHistory";

function History(props) {
  let { open, handleClose, orderId } = props;
  return (
    <Drawer
      open={open}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: false }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 500 } } }}
    >
      <Box
        sx={{
          py: 3,
          pb: 0,
          px: 4,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">История заявки</Typography>
        <IconButton onClick={handleClose} sx={{ color: "text.primary" }}>
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Box>
      <Box sx={{ py:0, px: 4 }}>
        <GeneralHistory id={orderId} mode="order" />
      </Box>
    </Drawer>
  );
}

export default History;
