// ** Next Import
import { Link } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

// ** Theme Config Import
import themeConfig from "src/configs/themeConfig";

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  marginRight: theme.spacing(8),
}));

const AppBarContent = (props) => {
  // ** Props
  const {
    appBarContent: userAppBarContent,
    appBarBranding: userAppBarBranding,
  } = props;

  // ** Hooks
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {userAppBarBranding ? (
        userAppBarBranding(props)
      ) : (
        <StyledLink to="/">
          
          <Typography
            variant="h6"
            sx={{ ml: 2, fontWeight: 700, lineHeight: 1.2 }}
          >
            {themeConfig.templateName}
          </Typography>
        </StyledLink>
      )}
      {/* dw */}
      {userAppBarContent ? userAppBarContent(props) : null}
    </Box>
  );
};

export default AppBarContent;
