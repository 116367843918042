import axios from "axios";

class SiteCurrency {
  addSiteCurrency(data) {
    return axios.post("/site-currency", data);
  }
  getSiteCurrencyList(params) {
    return axios.get(`/site-currency`, { params });
  }
  changeSiteCurrency(data) {
    return axios.put(`/site-currency`, data);
  }
  sortSiteCurrencyList(data) {
    return axios.put(`/site-currency/sort`, data);
  }
}

export default new SiteCurrency();
