import { useState, useEffect, Fragment } from "react";

// Mui
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { ListItemSecondaryAction } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import OrderService from "src/service/Order";

// Toast
import { toast } from "react-hot-toast";

// Services
import WalletService from "src/service/Wallet";
import RewardService from "src/service/Reward";

// Modules
import WalletSelect from "src/modules/Wallets/WalletSelect";

// Helpers
import sortWallet from "src/helpers/wallet/sortWallet";

function Resolve(props) {
  let { reward, sum, handleClose, style = {} } = props;

  // States
  let [kits, setKits] = useState([]);
  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    WalletService.getOutcomeKitsList({ currency: reward.currency._id }).then(
      (r) => {
        setKits(r.data);
        setLoaded(true);
      }
    );
  }, []);

  if (!loaded)
    return (
      <Box
        sx={{
          mt: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Card
      sx={{
        bgcolor: (t) => t.palette.background.default,
        mt: 3,
        ...style,
      }}
    >
      <CardContent sx={{ py: 2, pb: "8px !important", px: 2 }}>
        {kits.length ? (
          <KitsSelection
            reward={reward}
            handleClose={handleClose}
            sum={reward.sum}
            kits={kits}
            isCrypto={reward.currency.isCrypto}
          />
        ) : (
          notKitsTitle
        )}
      </CardContent>
    </Card>
  );
}

const notKitsTitle = (
  <Typography variant="body1" textAlign="center">
    Нет комплектов для отдачи
  </Typography>
);

function KitsSelection(props) {
  let { kits, isCrypto, sum, handleClose, reward } = props;

  // States
  const [selectetWallets, setSelectedWallets] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  // Handlers
  const handlerAddWallet = (added) => {
    let exist = selectetWallets.find((i) => i._id == added._id);

    if (exist) return;

    let kit = kits.find((k) => k._id == added.kit);

    let newWallet = {
      _id: added._id,
      name: added.name,
      kitName: kit.name,
      sum: 0,
      cms: 0,
    };
    setSelectedWallets((wallets) => [newWallet, ...wallets]);
  };

  const handlerDeleteWallet = (id) => {
    setSelectedWallets((wallets) => wallets.filter((w) => w._id !== id));
  };

  const handlerChangeWallet = (upd) => {
    setSelectedWallets((wallets) =>
      wallets.map((w) => (upd._id == w._id ? upd : w))
    );
  };

  // Api handlers
  const confirmSendPaymentHandler = () => {
    let list = selectetWallets.map((w) => {
      return {
        amount: w.sum,
        commission: w.cms,
        kitName: w.kitName,
        requisiteName: w.name,
        id: w._id,
      };
    });

    setSubmitting(true);

    RewardService.change({
      id: reward._id,
      status: 102,
      requisites: list,
    }).then(() => {
      toast.success("Вывод вознаграждения выполнен!");
      setSubmitting(false);
    });
  };

  let hasWallets = selectetWallets.length > 0;

  return (
    <Box>
      <Typography sx={{ mb: 3 }} variant="body1">
        {`Выберите ${isCrypto ? "комплекты" : "реквизиты"}  :`}
      </Typography>

      <WalletSelect
        isOutcome
        isCryptoOutcome={false}
        kits={kits}
        code={"RUB"}
        setHandler={handlerAddWallet}
      />

      {/* <KitsList kits={kits} addHandler={handlerAddWallet} isCrypto={isCrypto} /> */}

      <Typography sx={{ mb: 3 }} variant="body1">
        {`Выбранные ${isCrypto ? "комплекты" : "реквизиты"}  :`}
      </Typography>

      {selectetWallets.map((w) => {
        return (
          <SelectedItem
            deleteHandler={handlerDeleteWallet}
            changeHandler={handlerChangeWallet}
            key={w._id}
            sum={sum}
            data={w}
            isCrypto={isCrypto}
          />
        );
      })}

      <Grid sx={{ mt: 3 }} justifyContent="space-between" container spacing={4}>
        <Grid item xs={6}>
          {hasWallets && (
            <Button
              fullWidth
              sx={{ fontSize: "0.8rem" }}
              variant="contained"
              color="primary"
              onClick={confirmSendPaymentHandler}
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress /> : null}
            >
              Подтвердить отправление
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            sx={{ fontSize: "0.8rem" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Отменить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function KitsList(props) {
  let { kits = [], isCrypto = false, addHandler } = props;

  // States
  let [openKitId, setOpenKitId] = useState("");

  // Handlers
  const kitClickHandler = (kit) => {
    if (isCrypto) {
      addHandler(kit);
    } else {
      setOpenKitId(openKitId == kit._id ? "" : kit._id);
    }
  };
  const requisiteClickHandler = (req) => {
    if (!isCrypto) {
      addHandler(req);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <List dense>
        {sortWallet(kits).map((k) => {
          return (
            <Fragment>
              <ListItem key={k._id} disablePadding>
                <ListItemButton onClick={() => kitClickHandler(k)}>
                  <ListItemText
                    primary={
                      <Typography
                        // variant="body1"
                        sx={{ color: k.enabled ? "inherit" : "text.secondary" }}
                      >
                        {k.name}
                      </Typography>
                    }
                  />

                  <ListItemSecondaryAction
                    sx={{
                      mr: isCrypto ? undefined : 8,
                      color: k.enabled ? "inherit" : "text.secondary",
                    }}
                  >
                    {k.balance}
                  </ListItemSecondaryAction>

                  {!isCrypto && (
                    <Icon
                      icon={
                        openKitId === k._id
                          ? "mdi:chevron-up"
                          : "mdi:chevron-down"
                      }
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse in={openKitId === k._id} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                  {sortWallet(k.requisites).map((r) => {
                    return (
                      <ListItem key={r._id} disablePadding>
                        <ListItemButton
                          onClick={() => requisiteClickHandler(r)}
                          sx={{ pl: 8 }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  color: r.enabled
                                    ? "inherit"
                                    : "text.secondary",
                                }}
                              >
                                {r.name}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction
                            sx={{
                              color: r.enabled ? "inherit" : "text.secondary",
                            }}
                          >
                            {r.balance}
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </Fragment>
          );
        })}
      </List>
    </Box>
  );
}

function SelectedItem(props) {
  let { isCrypto, data, deleteHandler, changeHandler, sum } = props;
  let name = data.name;
  return (
    <Card sx={{ mb: 2.5 }}>
      <CardContent
        sx={{
          p: 2,
          pb: "12px !important",
        }}
      >
        <Typography sx={{ mb: 2 }} variant="body2">{`${
          isCrypto ? "Комплект" : "Реквизит"
        }: ${name}`}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={() =>
              changeHandler(
                Object.assign({}, data, {
                  _id: data._id,
                  sum: sum,
                })
              )
            }
            color="primary"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="mdi:tray-full" />
          </IconButton>
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={data.sum}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        sum: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label="Сумма"
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={data.cms}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        cms: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label="Комиссия"
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
          <IconButton
            onClick={() => deleteHandler(data._id)}
            color="error"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="ic:round-delete" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Resolve;
