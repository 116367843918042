import { useEffect, useState } from "react";
// Mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import toast from "react-hot-toast";

// Service
import CurrencyService from "src/service/Currencies";

function CurrencyFields(props) {
  let { info } = props;
  let [data, setData] = useState(info);

  let [showFieldDialog, setShowFieldDialog] = useState(false);
  let [dialogDirection, setDialogDirection] = useState("");
  let [dialogItem, setDialogItem] = useState(null);

  // handlers
  const showFieldDialogOpenHandler = (direction, data) => {
    setShowFieldDialog(true);
    setDialogDirection(direction);
    if (data) {
      setDialogItem(data);
    }
  };
  const showFieldDialogCloseHandler = () => {
    setShowFieldDialog(false);
    setDialogDirection("");
    setDialogItem(null);
  };
  // Data handlers
  const addItemHandler = (direction, item) => {
    let fields = Object.assign({}, data.fields, {
      [direction]: [...data.fields[direction], item],
    });
    setData(Object.assign({}, data, { fields }));
  };
  const changeItemHandler = (direction, item) => {
    let updateList = data.fields[direction];
    updateList = updateList.map((f) => (f._id == item._id ? item : f));
    let fields = Object.assign({}, data.fields, { [direction]: updateList });
    setData(Object.assign({}, data, { fields }));
  };
  const deleteItemHandler = (direction, id) => {
    let updateList = data.fields[direction];
    updateList = updateList.filter((f) => f._id !== id);
    let fields = Object.assign({}, data.fields, { [direction]: updateList });
    setData(Object.assign({}, data, { fields }));
  };

  const deleteFieldHandler = (direction, fieldId) => {
    if (!window.confirm(`Удалить поле?${direction == 'req' ? ' Данные полей РПП будут также удалены!' : ''}`)) return;

    let reqData = {
      _id: info._id,
      action: "fields",
      mode: "delete",
      direction: direction,
      data: {
        _id: fieldId,
      },
    };
    CurrencyService.infoChange(reqData).then(() => {
      deleteItemHandler(direction, fieldId);
      toast.success("Поле удалено!");
    });
  };

  return (
    <CardContent>
      <FieldDialog
        item={dialogItem}
        addItemHandler={addItemHandler}
        info={data}
        show={showFieldDialog}
        dialogDirection={dialogDirection}
        handleClose={showFieldDialogCloseHandler}
        changeItemHandler={changeItemHandler}
      />
      <Typography variant="h6" sx={{ mb: 4 }}>
        Поля
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <DirectionSection
            deleteFieldHandler={deleteFieldHandler}
            showFieldDialogOpenHandler={showFieldDialogOpenHandler}
            name="Для приема"
            direction={"take"}
            list={data.fields.take}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DirectionSection
            deleteFieldHandler={deleteFieldHandler}
            showFieldDialogOpenHandler={showFieldDialogOpenHandler}
            direction={"req"}
            name="Для РПП"
            list={data.fields.req}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DirectionSection
            showFieldDialogOpenHandler={showFieldDialogOpenHandler}
            direction={"give"}
            name="Для отдачи"
            list={data.fields.give}
            deleteFieldHandler={deleteFieldHandler}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}

function DirectionSection({
  name = "",
  showFieldDialogOpenHandler,
  list,
  deleteFieldHandler,
  direction,
}) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{name}</Typography>

        <IconButton
          onClick={() => showFieldDialogOpenHandler(direction)}
          color="primary"
        >
          <Icon icon="material-symbols:add" />
        </IconButton>
      </Box>
      <List>
        {list.map((f) => (
          <FieldItem
            showFieldDialogOpenHandler={showFieldDialogOpenHandler}
            direction={direction}
            deleteFieldHandler={deleteFieldHandler}
            name={f.name}
            _id={f._id}
            data={f}
          />
        ))}
      </List>
    </Box>
  );
}

function FieldItem({
  name = "",
  data,
  deleteFieldHandler,
  direction,
  _id,
  showFieldDialogOpenHandler,
}) {
  return (
    <ListItem disablePadding>
      <ListItemText primary={name} />
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => showFieldDialogOpenHandler(direction, data)}
          edge="end"
        >
          <Icon icon="material-symbols:edit" fontSize={20} />
        </IconButton>
        <IconButton
          onClick={() => deleteFieldHandler(direction, _id)}
          color="error"
          sx={{ ml: 4 }}
          edge="end"
        >
          <Icon icon="material-symbols:delete-forever-rounded" fontSize={20} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function FieldDialog(props) {
  let {
    show,
    handleClose,
    dialogDirection,
    info,
    addItemHandler,
    item,
    changeItemHandler,
  } = props;

  let [validation, setValidation] = useState("");
  let [name, setName] = useState("");
  let [isSubmiting, setSubmiting] = useState(false);

  useEffect(() => {
    if (!show) return;
    if (item) {
      setName(item.name);
      setValidation(item.validation);
    }
  }, [show]);

  // Handlers
  function closeHandler() {
    handleClose();
    setName("");
    setValidation("");
  }

  const onSubmit = () => {
    let isChange = !!item;
    // changeItemHandler
    let reqData = {
      _id: info._id,
      action: "fields",
      mode: isChange ? "change" : "add",
      direction: dialogDirection,
      data: {
        name,
        validation,
        _id: isChange ? item._id : undefined,
      },
    };
    setSubmiting(true);
    CurrencyService.infoChange(reqData)
      .then((r) => {
        toast.success(`Поле ${isChange ? "измененно" : "добавлено"}!`);
        if (isChange) {
          changeItemHandler(
            dialogDirection,
            Object.assign({}, item, { name, validation })
          );
        } else {
          addItemHandler(dialogDirection, r.data);
        }
        closeHandler();
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  return (
    <Box>
      <Dialog
        fullWidth
        open={show}
        maxWidth="xs"
        scroll="body"
        onClose={closeHandler}
      >
        <DialogContent>
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography variant="h5" sx={{ mb: 7, lineHeight: "2rem" }}>
              Добавить поле
            </Typography>
          </Box>
          <Box>
            <TextField
              label="Название"
              placeholder="Введите название поля"
              fullWidth
              sx={{ mt: 2 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Typography sx={{ mt: 4, mb: 4 }} variant="h6">
              Валидация
            </Typography>
            <RadioGroup
              column
              value={validation}
              onChange={(e) => setValidation(e.target.value)}
            >
              <FormControlLabel
                control={<Radio size="small" />}
                label="Без валидации"
                value=""
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="Номер карты"
                value="card"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="Адрес кошелька"
                value="address"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="Номер телефона"
                value="phone"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="Только числа"
                value="numbers"
              />
            </RadioGroup>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
        >
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            variant="contained"
            sx={{ mr: 2 }}
            onClick={onSubmit}
            type="submit"
          >
            {item ? "Сохранить" : "Добавить"}
          </Button>
          <Button variant="outlined" color="secondary" onClick={closeHandler}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CurrencyFields;
