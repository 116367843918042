// Custom Components
import PageWrap from "src/components/PageWrap";

// Modules
import CoursesTableFilter from "./CoursesTableFilter";

function CoursesTable() {
  return (
    <PageWrap title="Таблица курсов">
      <CoursesTableFilter />
    </PageWrap>
  );
}

export default CoursesTable;
