import { useState, useEffect, Fragment } from "react";

// Mui
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { ListItemSecondaryAction } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import OrderService from "src/service/Order";

// Toast
import { toast } from "react-hot-toast";

// Services
import WalletService from "src/service/Wallet";

// helpers

import sortWallet from "src/helpers/wallet/sortWallet";

// Modules
import WalletSelect from "src/modules/Wallets/WalletSelect";

function ChooseWalletSendPayment(props) {
  let { currency, sum, orderId, handleClose, code } = props;

  // States
  let [kits, setKits] = useState([]);
  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    WalletService.getOutcomeKitsList({ currency: currency._id }).then((r) => {
      setKits(r.data);
      setLoaded(true);
    });
  }, []);

  if (!loaded)
    return (
      <Box
        sx={{
          mt: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      {kits.length ? (
        <KitsSelection
          orderId={orderId}
          handleClose={handleClose}
          sum={sum}
          kits={kits}
          code={code}
          isCrypto={currency.isCrypto}
        />
      ) : (
        notKitsTitle
      )}
    </Box>
  );
}

const notKitsTitle = (
  <Typography variant="body1" textAlign="center">
    Нет комплектов для отдачи
  </Typography>
);

function KitsSelection(props) {
  let { kits, isCrypto, sum, handleClose, orderId, code } = props;

  // States
  const [selectetWallets, setSelectedWallets] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  // Handlers
  const handlerAddWallet = (added) => {
    let exist = selectetWallets.find((i) => i._id == added._id);

    if (exist) return;

    let newWallet = {
      _id: added._id,
      name: added.name,
      sum: 0,
      cms: 0,
    };
    setSelectedWallets((wallets) => [newWallet, ...wallets]);
  };

  const handlerDeleteWallet = (id) => {
    setSelectedWallets((wallets) => wallets.filter((w) => w._id !== id));
  };

  const handlerChangeWallet = (upd) => {
    setSelectedWallets((wallets) =>
      wallets.map((w) => (upd._id == w._id ? upd : w))
    );
  };

  // Api handlers
  const confirmSendPaymentHandler = () => {
    setSubmitting(true);
    OrderService.confirmSend(orderId, {
      list: selectetWallets,
    })
      .then(() => {
        toast.success("Отправка средств клиенту подтверждена!");
        handleClose();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  let hasWallets = selectetWallets.length > 0;

  return (
    <Box>
      <Box sx={{ p: 3, pb: 1 }}>
        <Typography sx={{ mb: 3 }} variant="body1">
          {`Выберите ${isCrypto ? "комплекты" : "реквизиты"}  :`}
        </Typography>
      </Box>

      <WalletSelect
        isOutcome
        isCryptoOutcome={isCrypto}
        kits={kits}
        code={code}
        setHandler={handlerAddWallet}
      />


      <Box sx={{ p: 3, pt: 1 }}>
        <Typography sx={{ mb: 3 }} variant="body1">
          {`Выбранные ${isCrypto ? "комплекты" : "реквизиты"}  :`}
        </Typography>

        {selectetWallets.map((w) => {
          return (
            <SelectedItem
              deleteHandler={handlerDeleteWallet}
              changeHandler={handlerChangeWallet}
              key={w._id}
              sum={sum}
              data={w}
              isCrypto={isCrypto}
            />
          );
        })}

        <Grid
          sx={{ mt: 3 }}
          justifyContent="space-between"
          container
          spacing={4}
        >
          <Grid item xs={6}>
            {hasWallets && (
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="contained"
                color="primary"
                onClick={confirmSendPaymentHandler}
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress /> : null}
              >
                Подтвердить отправление
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ fontSize: "0.8rem" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Отменить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}



function SelectedItem(props) {
  let { isCrypto, data, deleteHandler, changeHandler, sum } = props;
  let name = data.name;
  return (
    <Card sx={{ mb: 2.5 }}>
      <CardContent
        sx={{
          p: 2,
          pb: "12px !important",
        }}
      >
        <Typography sx={{ mb: 2 }} variant="body2">{`${
          isCrypto ? "Комплект" : "Реквизит"
        }: ${name}`}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={() =>
              changeHandler(
                Object.assign({}, data, {
                  _id: data._id,
                  sum: sum,
                })
              )
            }
            color="primary"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="mdi:tray-full" />
          </IconButton>
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={data.sum}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        sum: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label="Сумма"
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={data.cms}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        cms: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label="Комиссия"
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
          <IconButton
            onClick={() => deleteHandler(data._id)}
            color="error"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="ic:round-delete" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ChooseWalletSendPayment;
