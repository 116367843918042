import { useState, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import { toast } from "react-hot-toast";

// Services
import CurrencyService from "src/service/Currencies";

function MoreSettings(props) {
  let { isCrypto, data } = props;

  // State
  const [settings, setSettings] = useState(data);

  // Handlers
  const changeSettingsHandler = (name, value) => {
    setSettings((s) => Object.assign({}, s, { [name]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    CurrencyService.settingsChange(settings).then((r) =>
      toast.success("Доп. настройки измененны!")
    );
  };

  if (!settings) return null;

  return (
    <Card>
      <CardHeader title="Доп. настройки" />
      <Divider sx={{ m: "0 !important" }} />
      <CardContent>
        <Grid container spacing={4}>
          {!isCrypto && (
            <Grid item xs={6}>
              <FormControlLabel
                label="Банковская карта"
                control={
                  <Checkbox
                    checked={settings.isBank}
                    onChange={(e) =>
                      changeSettingsHandler("isBank", e.target.checked)
                    }
                    name="controlled"
                  />
                }
              />
            </Grid>
          )}

          {settings.isBank && (
            <Grid item xs={6}>
              <FormControlLabel
                label="Верифицировать"
                control={
                  <Checkbox
                    checked={settings.isVerify}
                    onChange={(e) =>
                      changeSettingsHandler("isVerify", e.target.checked)
                    }
                    name="controlled"
                  />
                }
              />
            </Grid>
          )}
          {settings.isBank && (
            <Grid item xs={6}>
              <FormControlLabel
                label="Не привязывать к валюте"
                control={
                  <Checkbox
                    checked={settings.dontTie}
                    onChange={(e) =>
                      changeSettingsHandler("dontTie", e.target.checked)
                    }
                    name="controlled"
                  />
                }
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormControlLabel
              label="Оплата по API"
              control={
                <Checkbox
                  checked={settings.paymentLink}
                  onChange={(e) =>
                    changeSettingsHandler("paymentLink", e.target.checked)
                  }
                  name="controlled"
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              label="Комиссия BTC"
              control={
                <Checkbox
                  checked={settings.BTCfeeOn}
                  onChange={(e) =>
                    changeSettingsHandler("BTCfeeOn", e.target.checked)
                  }
                  name="controlled"
                />
              }
            />
          </Grid>
          {!isCrypto && (
            <Grid item xs={6}>
              <FormControlLabel
                label="Наличные"
                control={
                  <Checkbox
                    checked={settings.isCash}
                    onChange={(e) =>
                      changeSettingsHandler("isCash", e.target.checked)
                    }
                    name="controlled"
                  />
                }
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label="Обновление курса"
              placeholder="Введите время в мин."
              id="minutesUpdateRate"
              type="number"
              onChange={(e) =>
                changeSettingsHandler("minutesUpdateRate", e.target.value)
              }
              value={settings.minutesUpdateRate}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <Button
            endIcon={<Icon icon="material-symbols:save-outline" />}
            variant="contained"
            onClick={onSubmit}
          >
            Сохранить
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default MoreSettings;
