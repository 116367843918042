import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import CircularProgress from "@mui/material/CircularProgress";

// Components
import CurrencyPageHeader from "./CurrencyPageHeader";
import BasicSettings from "./BasicSettings";
import MoreSettings from "./MoreSettings";
import OtherSettings from "./OtherSettings/OtherSettings";
import CashCity from "./CashCity";
import BitcoinCommission from "./BitcoinCommission";

// import Nets from "./Nets";

// ** Router
import { useParams } from "react-router-dom";

// Socket
import { socket } from "src/socket";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Service
import CurrenciesService from "src/service/Currencies";

function Currency() {
  // State
  let [isBank, setIsBank] = useState(false);
  let [currency, setCurrency] = useState(null);
  const params = useParams();
  const currencyId = params.id;

  useEffect(() => {
    CurrenciesService.getOneFullInfo(currencyId).then((r) =>
      setCurrency(r.data)
    );
    socket.on(`currency_${currencyId}_part_upd`, (d) => {
      setCurrency((c) => Object.assign({}, c, d));
    });
    return () => {
      socket.off(`currency_${currencyId}_part_upd`);
    };
  }, []);

  if (!currency) {
    return (
      <Box
        sx={{
          p: 5,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <CurrencyPageHeader
        currencyId={currency._id}
        name={currency.name}
        code={currency.code}
        price={currency.value}
        image={currency.image}
        precision={currency.precision}
      />
      <Grid sx={{ mb: 4 }} container spacing={4}>
        <Grid item xs={12} md={6}>
          <BasicSettings currency={currency} />
        </Grid>
        <Grid item xs={12} md={6}>
          <MoreSettings isCrypto={currency.isCrypto} data={currency.settings} />
        </Grid>
      </Grid>
      <Grid sx={{ mb: 4 }} container spacing={4}>
        {currency?.settings?.isCash && (
          <Grid item xs={12} md={6}>
            <CashCity
              infoId={currency?.info?._id}
              cashCityCode={currency?.info?.cashCityCode}
            />
          </Grid>
        )}
        {currency.code == "BTC" && (
          <Grid item xs={12} md={6}>
            <BitcoinCommission />
          </Grid>
        )}
      </Grid>
      <Card>
        <CardHeader title="Остальные настройки" />
        <Divider sx={{ my: "0px !important" }} />
        <OtherSettings currency={currency} />
      </Card>
    </Box>
  );
}

export default Currency;
