import axios from "axios";

class CardComment {
  getAll() {
    return axios.get("/card-comment");
  }
  add(data){
    return axios.post("/card-comment", data);
  }
  change(data){
    return axios.put("/card-comment", data);
  }
  delete(id) {
    return axios.delete("/card-comment", { data: { id } });
  }

}

export default new CardComment();
