import { useState, useEffect } from "react";
// Mui
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Validation
import { useForm, Controller } from "react-hook-form";

// Services
import CurrencyService from "src/service/Currencies";

// Socket
import { socket } from "src/socket";

// Toast
import { toast } from "react-hot-toast";

// Congi
import ConfigApi from "src/configs/api";
import { Typography } from "@mui/material";

function UnificationNets() {
  let [codes, setCodes] = useState([]);
  let [currencies, setCurrencies] = useState([]);
  const [currentTab, setCurrentTab] = useState("");
  // Dialog
  let [showDialog, setShowDialog] = useState(false);
  let [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    CurrencyService.getList({ isCrypto: true }).then((r) => {
      let list = getOnlyMultipleCurrenciesCode(r.data);
      setCodes(list);
      setCurrencies(r.data);
      setCurrentTab(list[0]);
    });

    socket.on("currency_item_part_upd", (upd) => {
      setCurrencies((list) =>
        list.map((c) => (c._id == upd._id ? Object.assign({}, c, upd) : c))
      );
    });
    return () => {
      socket.off("currency_item_part_upd");
    };

    // currency_item_part_upd
  }, []);

  // Handlers
  const openDialogHandler = (cur) => {
    setSelectedCurrency(cur);
    setShowDialog(true);
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
    setSelectedCurrency(null);
  };

  //

  return (
    <Card>
      <AddNetDialog
        open={showDialog}
        closeHandler={closeDialogHandler}
        currency={selectedCurrency}
      />
      <CardHeader title={"Объединение сетей"} />
      <CardContent>
        <TabContext value={currentTab}>
          <TabList
            onChange={(_, v) => setCurrentTab(v)}
            variant="scrollable"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {codes.map((c) => (
              <Tab value={c} label={c.toUpperCase()} />
            ))}
          </TabList>
          {codes.map((c) => {
            let codeCurrencies = currencies.filter((cur) => cur.code === c);
            return (
              <TabPanel value={c}>
                <CodeTabPanel
                  openDialogHandler={openDialogHandler}
                  code={c.code}
                  currencies={codeCurrencies}
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </CardContent>
    </Card>
  );
}

function CodeTabPanel(props) {
  let { code, currencies, openDialogHandler } = props;

  let nets = currencies.filter((cur) => cur.isNet);
  let notNets = currencies.filter((cur) => !cur.isNet);

  return (
    <List
      dense
      sx={{
        maxHeight: "50vh",
        overflowY: "auto",
      }}
    >
      <Typography sx={{ mb: 4 }} variant="body1">
        Обьединенные
      </Typography>
      {nets.map((c) => {
        return <CurrencyCard data={c} />;
      })}
      <Divider sx={{ my: "0px !important" }} />
      <Typography sx={{ mb: 4, mt: 2 }} variant="body1">
        Отдельные
      </Typography>
      {notNets.map((c) => {
        return <CurrencyCard openDialogHandler={openDialogHandler} data={c} />;
      })}
    </List>
  );
}

function CurrencyCard(props) {
  let { data, openDialogHandler } = props;

  let isNet = data.isNet;

  // Handlers
  const deleteNetItem = () => {
    CurrencyService.netsUnified({
      currency: data._id,
      action: "sub",
    }).then(() => {
      toast.success("Валюта отделена!");
    });
  };

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + data.image}
          alt={data.name}
          sx={{ height: 36, width: 36 }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={data.name}
        secondary={isNet ? data.netCode : undefined}
      />
      <ListItemSecondaryAction>
        {isNet ? (
          <IconButton color="error" onClick={deleteNetItem} edge="end">
            <Icon icon="material-symbols:delete-forever" />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            onClick={() => openDialogHandler(data)}
            edge="end"
          >
            <Icon icon="mdi:plus" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function AddNetDialog(props) {
  let { open, closeHandler, currency } = props;
  // States
  let [isSubmiting, setSubmiting] = useState(false);

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
    mode: "onSubmit",
  });

  // Handlers

  const onSubmit = handleSubmit((d) => {
    setSubmiting(true);
    CurrencyService.netsUnified({
      currency: currency._id,
      action: "add",
      code: d.code,
    })
      .then(() => {
        toast.success("Валюта добавлена!");
        handleClose();
      })
      .finally(() => setSubmiting(false));
  });

  const handleClose = () => {
    closeHandler();
    setValue("code", "");
  };

  const CurrencyInfo = ({ currency }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + currency.image}
          alt={currency.name}
          sx={{ height: 36, width: 36, mr: 2 }}
        />
        <Typography variant="h6">{currency.name}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        <DialogTitle>Добавить сеть</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <Box
              className="demo-space-x"
              sx={{ "& > :last-child": { mr: "0 !important" } }}
            >
              {currency && <CurrencyInfo currency={currency} />}

              <Controller
                name={"code"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    placeholder="TRC 20"
                    id="code"
                    type="text"
                    label="Код сети"
                    error={Boolean(errors.code?.message)}
                    {...register("code", {
                      required: "Введите код сети!",
                    })}
                    helperText={errors.code?.message}
                    onChange={(e) => onChange(e.target.value.toUpperCase())}
                    value={value}
                  />
                )}
              />
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
                size="large"
                type="submit"
                variant="contained"
              >
                Добавить
              </Button>
              <Button
                type="reset"
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

function getOnlyMultipleCurrenciesCode(currencies) {
  let codes = currencies.map((c) => c.code);

  const codeItems = {}; // здесь будет храниться промежуточный результат

  for (const code of codes) {
    // если элемент уже был, то прибавляем 1, если нет - устанавливаем 1
    codeItems[code] = codeItems[code] ? codeItems[code] + 1 : 1;
  }

  // обрабатываем ключи объекта, отфильтровываем все, что меньше 1
  return Object.keys(codeItems).filter((item) => codeItems[item] > 1);
}
export default UnificationNets;
