import axios from "axios";

class RolePermission {
  getAll() {
    return axios.get("/role-permission");
  }
  create(data) {
    return axios.post("/role-permission", data);
  }
  change(data) {
    return axios.put("/role-permission", data);
  }
}

export default new RolePermission();
