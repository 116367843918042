// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";
import CustomAvatar from "src/@core/components/mui/avatar";

// Store
import { observer } from "mobx-react-lite";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Helpers
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

function Capitalization({sum}) {
  return (
    <Card>
      <CardContent sx={{ display: "flex", alignItems: 'center' }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <CustomAvatar skin="light" variant="rounded" color={'primary'}>
           <Icon icon="mdi:bank-outline" />
          </CustomAvatar>
        </Box>
        <Box sx={{ml: 3}}>
          <Typography variant="h6" sx={{ mr: 1.75 }}>
            {getPriceCurrentFiat(sum, 0)}
          </Typography>
          <Typography variant="body2">Капитализация</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default observer(Capitalization);
