import axios from "axios";

class SiteShiftPrice {
  getAll() {
    return axios.get("/site-shift-price");
  }
  change(id, upd) {
    return axios.put(`/site-shift-price/${id}`, upd);
  }
}

export default new SiteShiftPrice();
