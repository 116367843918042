// Mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


// ** Icon Imports
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";

const SiteFilterList = [
    {
      value: "",
      label: "Все",
    },
    ...WEBSITES.map((s) => {
      return {
        value: s.id,
        label: s.name,
      };
    }),
  ];

function CoursesTableFilter() {
  return (
    <Card>
      <CardHeader title="Поиск по фильтру" />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="site-select">Сайт</InputLabel>
              <Select
                fullWidth
                // value={data.site}
                id="select-site"
                label="Сайт"
                labelId="site-select"
                // onChange={(e) => handleChange("site", e.target.value)}
                inputProps={{ placeholder: "Выберите сайт" }}
              >
                {SiteFilterList.map((s) => {
                  return (
                    <MenuItem key={s.value} value={s.value}>
                      {s.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, display: "flex" }}>
        <Button
          endIcon={
            <Icon icon="material-symbols:find-in-page-outline-rounded" />
          }
        //   onClick={handleFind}
          variant="contained"
        >
          Применить
        </Button>
        <Button
          endIcon={<Icon icon="ic:outline-delete-outline" />}
          sx={{ ml: 4 }}
        //   onClick={handleClear}
          variant="outlined"
        >
          Очистить
        </Button>
      </Box>
      </CardContent>
    </Card>
  );
}

export default CoursesTableFilter;
