// ** Contexts
import { AuthProvider } from "src/context/AuthContext";
import {
  SettingsConsumer,
  SettingsProvider,
} from "src/@core/context/settingsContext";
import { useAuth } from "src/hooks/useAuth";

// ** Config Imports
import { defaultACLObj } from "src/configs/acl";

// ** Third Party Import
import { Toaster } from "react-hot-toast";

// ** Styled Components
import ReactHotToast from "src/@core/styles/libs/react-hot-toast";

// ** Component Imports
import UserLayout from "src/layouts/UserLayout";

import ThemeComponent from "src/@core/theme/ThemeComponent";
import AclGuard from "src/@core/components/auth/AclGuard";
import AuthGuard from "src/@core/components/auth/AuthGuard";

// ** Spinner Import
import Spinner from "src/@core/components/spinner";

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css";
import "src/iconify-bundle/icons-bundle-react";

// ** Global css styles
import "./styles/globals.css";

// ** Router
import Router from "./router";
// import { Outlet } from "react-router-dom";

// ** Snackbar
import Snackbar from "./modules/Snackbar";

// Modules
import CardDrawer from 'src/modules/Cards/CardDrawer/CardDrawer'
import OrderDrawer from 'src/modules/Orders/OrderDrawer/OrderDrawer'
import RewardDrawer from 'src/modules/Rewards/RewardDrawer/RewardDrawer'
import DebtDrawer from 'src/modules/Debt/DebtDrawer/DebtDrawer'


import './socket';

const Guard = ({ children, authGuard }) => {
  if (!authGuard) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
  }
};

function App() {
  const auth = useAuth();


  // Variables

  const Component = {};
  const contentHeightFixed = Component.contentHeightFixed ?? false;

  const isAuth = !!auth.user;


  const getLayout = !isAuth
    ? (f) => f
    : (page) => <UserLayout>{page}</UserLayout>;

  const authGuard = Component.authGuard ?? false;
  // const authGuard = false;
  const aclAbilities = Component.acl ?? defaultACLObj;

  return (
    <div>
      <AuthProvider>
        <SettingsProvider {...{ thisSetTheme: "ff" }}>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>

                  <Guard authGuard={authGuard}>
                    <AclGuard
                      aclAbilities={aclAbilities}
                      // guestGuard={guestGuard}
                    >
                      <CardDrawer/>
                      <OrderDrawer/>
                      <RewardDrawer/>
                      <DebtDrawer/>
                      <Router />
                      {/* {getLayout(<Router />)} */}
                    </AclGuard>
                  </Guard>
                  <ReactHotToast>
                    <Toaster
                      position={settings.toastPosition}
                      toastOptions={{
                        className: "react-hot-toast",
                      }}
                    />
                  </ReactHotToast>
                </ThemeComponent>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </AuthProvider>
      <Snackbar />
    </div>
  );
}

export default App;
