import { useEffect, useState } from "react";

// Mui
import { Box, Grid } from "@mui/material";

// Modules
import Filter from "./Filter";

// Configs
import WEBSITES from "src/configs/websites";

// Modules
import RewardCard from "src/modules/Rewards/RewardCard";

// Services
import RewardService from "src/service/Reward";

import { socket } from "src/socket";

// CONSTS
const WEBSITES_LIST = [
  {
    value: "all",
    name: "Все",
  },
  ...WEBSITES.map((w) => ({
    name: w.name,
    value: w.id,
  })),
];

const STATUS_LIST = [
  {
    value: "all",
    name: "Все",
  },
  {
    value: 101,
    name: "Новые",
  },
  {
    value: 102,
    name: "Выполненные",
  },
  {
    value: 103,
    name: "Отклоненные",
  },
];

function Rewards() {
  // States
  const [site, setSite] = useState(WEBSITES_LIST[0].value);
  const [status, setStatus] = useState(STATUS_LIST[0].value);
  const [rewards, setRewards] = useState([]);

  // Hooks
  useEffect(() => {
    socket.on("add_reward", (r) => {
      setRewards((list) => [r, ...list]);
    });
    socket.on("update_reward", (r) => {
      setRewards((list) => list.map((i) => (i._id == r._id ? r : i)));
    });

    return () => {
      socket.off("add_reward");
      socket.off("update_reward");
    }; 
  }, []);

  useEffect(() => {
    RewardService.getList({
      sc: site,
      status,
    }).then((r) => {
      setRewards(r.data);
    });
  }, [status, site]);

  return (
    <Box>
      <Filter
        site={site}
        setSite={setSite}
        status={status}
        setStatus={setStatus}
        WEBSITES={WEBSITES_LIST}
        STATUSES={STATUS_LIST}
      />
      <Grid sx={{ mt: 4 }} container spacing={4}>
        {rewards.map((o) => {
          return (
            <Grid key={o._id} item xs={12} sm={6} md={3}>
              <RewardCard data={o} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Rewards;
