import config from "./configs/api";
import io from "socket.io-client";

let socket = io(config.SERVER, {
  transports: ["polling", "websocket", "flashsocket"],
  reconnection: true,
  pingInterval: 1000,
  pingTimeout: 1500,
});

socket.on("connect", function () {
  // console.log("Socket подключен!");
  authenticate();
});

socket.on("disconnect", function (e) {
  console.log(e);
  // console.log("Socket отключен!");
});
socket.on("connect_error", (error) => {
  // console.log("Ошибка подключения к сокету:", error);
});
socket.on("reconnect", function () {
  // console.log("Socket переподключение!");
});

function authenticate() {
  const token = localStorage.getItem("token");

  if (token) {
    socket.emit("auth_admin", token);
  }
}

export { authenticate, socket };

export default socket;
