import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CardActionArea from "@mui/material/CardActionArea";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
//
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

// Components
import CurrencySelect from "src/components/CurrencySelect";
// Helpers
import cardNumberAddSpaces from "src/helpers/cardNumberAddSpaces";
import filterCurrenciesWithCard from "src/helpers/card/filterCurrenciesWithCard";
import keepOnlyNumbers from "src/helpers/card/keepOnlyNumbers";

// Components
import ViewImage from "src/components/ViewImage";

import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

// Mask
import InputMask from "react-input-mask";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Config
import CONFIG_API from "src/configs/api";

// Service
import CardCommentService from "src/service/CardComment";
import CurrencyService from "src/service/Currencies";
import CardService from "src/service/Card";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";

// Modules
import CardCurrency from "../CardCurrency";
import CardFromMobile from "../CardFromMobile";
import CardChip from "../CardChip";

function CardControl(props) {
  let { card, isOrder } = props;
  // Calc
  let statusText = getStatusText(card.status);

  let showImages = isOrder ? card.status !== 102 : true;

  return (
    <CardContent>
      <TopCardComponent isOrder={isOrder} card={card} statusText={statusText} />
      {showImages && (
        <ImagesComponent images={card.images || []} image={card.image} />
      )}

      <ControlsComponent
        isOrder={isOrder}
        card={card}
        statusText={statusText}
      />
    </CardContent>
  );
}

function ControlsComponent(props) {
  let { card, statusText, isOrder } = props;

  let [controlName, setControlName] = useState("");
  let [isSubmiting, setSubmiting] = useState("");

  // Calcs
  let isVerify = statusText == "verification";
  let isResolve = statusText == "resolved";
  let isReject = statusText == "rejected";
  let isBan = statusText == "banned";
  let showSetBank = isOrder ? isVerify : isVerify || isResolve;

  // Api handlers
  const verifyCardHandler = () => {
    setSubmiting("verify");
    CardService.verify(card._id)
      .then(() => {
        toast.success("Карта одобрена!");
      })
      .finally(() => setSubmiting(""));
  };
  const banCardHandler = () => {
    setSubmiting("ban");
    let isBan = card.status == 104;
    CardService.ban(card._id)
      .then(() => {
        toast.success(`Карта ${isBan ? "разблокирована" : "заблокирована"} !`);
      })
      .finally(() => setSubmiting(""));
  };

  let controlComponent = (
    <Grid sx={{ mt: 2 }} container spacing={4}>
      {isVerify && (
        <Grid item xs={6}>
          <Button
            onClick={verifyCardHandler}
            disabled={isSubmiting == "verify"}
            startIcon={isSubmiting == "verify" ? <CircularProgress /> : null}
            fullWidth
            variant="contained"
          >
            Верифицировать
          </Button>
        </Grid>
      )}
      {showSetBank && (
        <Grid item xs={6}>
          <Button
            onClick={() => setControlName("direction")}
            fullWidth
            variant="contained"
          >
            Установить банк
          </Button>
        </Grid>
      )}
      {isVerify && (
        <Grid item xs={6}>
          <Button
            onClick={() => setControlName("reject")}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отказать
          </Button>
        </Grid>
      )}
      {(isVerify || isResolve || isReject) && (
        <Grid item xs={6}>
          <Button
            onClick={banCardHandler}
            fullWidth
            color="error"
            variant="outlined"
            disabled={isSubmiting == "ban"}
            startIcon={isSubmiting == "ban" ? <CircularProgress /> : null}
          >
            Заблокировать
          </Button>
        </Grid>
      )}
      {isBan && (
        <Grid item xs={6}>
          <Button
            disabled={isSubmiting == "ban"}
            startIcon={isSubmiting == "ban" ? <CircularProgress /> : null}
            onClick={banCardHandler}
            fullWidth
            variant="contained"
          >
            Разблокировать
          </Button>
        </Grid>
      )}
    </Grid>
  );

  switch (controlName) {
    case "direction":
      controlComponent = (
        <ResolveCurrencySelect
          handleClose={() => setControlName("")}
          card={card}
        />
      );
      break;
    case "reject":
      controlComponent = (
        <RejectCommentSelect
          handleClose={() => setControlName("")}
          card={card}
        />
      );
      break;
  }

  return <Box>{controlComponent}</Box>;
}

function TopCardComponent(props) {
  let { statusText, card, isOrder } = props;

  // States
  let [isEditNumber, setIsEditNumber] = useState(false);
  let [cardNumber, setCardNumber] = useState(card.number);

  // Hooks
  useEffect(() => {
    setCardNumber(card.number);
  }, [card.number]);

  // Handlers

  const saveNewNumber = () => {
    CardService.changeNumber(card._id, cardNumber).then(() => {
      toast.success("Номер карты изменен!");
      setIsEditNumber(false);
    });
  };

  let commentText = card?.comment?.admin;

  // Components
  let showNumberComponent = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Номер карты:</Typography>
        <Typography sx={{ ml: 2 }} variant="body1">
          {cardNumberAddSpaces(card.number)}
        </Typography>
        <CopyClipboardWrap value={card.number} />
      </Box>

      {card.status == 101 && (
        <IconButton
          onClick={() => {
            setIsEditNumber(true);
          }}
          color="primary"
          sx={{ ml: 4 }}
        >
          <Icon icon="ic:round-edit" />
        </IconButton>
      )}
    </Box>
  );
  let editNumberComponent = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <InputMask
        fullWidth
        label={"Номер карты"}
        size="small"
        maskChar=" "
        mask="9999 9999 9999 9999 99"
        value={cardNumber}
        onChange={(e) => setCardNumber(e.target.value)}
        type="text"
      >
        {(e) => <TextField {...e} />}
      </InputMask>

      {keepOnlyNumbers(cardNumber.trim().replaceAll(" ", "")) !==
        card.number && (
        <IconButton onClick={saveNewNumber} color="primary" sx={{ ml: 4 }}>
          <Icon icon="ic:outline-save" />
        </IconButton>
      )}

      <IconButton
        onClick={() => {
          setIsEditNumber(false);
        }}
        color="error"
        sx={{ ml: 4 }}
      >
        <Icon icon="material-symbols:cancel-outline" />
      </IconButton>
    </Box>
  );

  let showComponent = null;

  switch (statusText) {
    case "verification":
      showComponent = (
        <Box>
          {!isOrder && (
            <Typography sx={{ mb: 2 }} variant="body1">
              Верифицируйте карту
            </Typography>
          )}

          {isEditNumber ? editNumberComponent : showNumberComponent}
        </Box>
      );
      break;
    case "rejected":
      showComponent = (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">Комментарий:</Typography>
          <Typography sx={{ ml: 2 }} variant="body1">
            {commentText}
          </Typography>
        </Box>
      );
  }

  let topSection = null;

  if (card.currency || card.isMobile) {
    topSection = (
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <CardCurrency
          style={{
            bgcolor: (t) => t.palette.background.paper,
            flexGrow: 1,
          }}
          currency={card.currency}
        />
        {card.isMobile && <CardFromMobile style={{ ml: 1 }} />}
      </Box>
    );
  }

  let bottomSection = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Номер карты:</Typography>
        <Typography sx={{ ml: 2 }} variant="body1">
          {cardNumberAddSpaces(card.number)}
        </Typography>
        <CopyClipboardWrap value={card.number} />
      </Box>
      <CardChip size="small" status={card.status} />
    </Box>
  );

  return (
    <Box sx={{ mb: 2 }}>
      {isOrder && topSection}
      {showComponent}
      {isOrder && card.status !== 101 && bottomSection}
    </Box>
  );
}

function ImagesComponent(props) {
  let { image, images } = props;

  // States
  let [showImage, setShowImage] = useState(false);
  let [showImages, setShowImages] = useState(false);
  let [urlImage, setUrlImage] = useState(false);

  // Handlers
  const closeShowImage = () => {
    setShowImage(false);
    setUrlImage("");
  };
  const openShowImage = (url) => {
    setUrlImage(url);
    setShowImage(true);
  };

  let imgsrc = CONFIG_API.PUBLIC_IMAGES + image;

  let imagesComponent = null;

  if (images.length) {
    imagesComponent = (
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">
              Раннее загруженные изображения
            </Typography>
          </Box>
          <IconButton onClick={() => setShowImages(!showImages)}>
            <Icon icon="material-symbols:expand-more-rounded" />
          </IconButton>
        </Box>
        <Collapse in={showImages} timeout="auto" unmountOnExit>
          <Grid sx={{ mt: 2 }} container spacing={4}>
            {images.map((img, i) => {
              let srcImage = CONFIG_API.PUBLIC_IMAGES + img;
              return (
                <Grid item xs={6} md={4} key={i}>
                  <CardActionArea onClick={() => openShowImage(srcImage)}>
                    <img
                      src={srcImage}
                      style={{
                        width: "100%",
                        borderRadius: 4,
                      }}
                    />
                  </CardActionArea>
                </Grid>
              );
            })}
          </Grid>
        </Collapse>
      </Box>
    );
  }

  return (
    <Box sx={{ my: 2 }}>
      <ViewImage
        show={showImage}
        handleClose={closeShowImage}
        value={urlImage}
      />
      <CardActionArea onClick={() => openShowImage(imgsrc)}>
        <img
          src={imgsrc}
          style={{
            width: "100%",
            borderRadius: 4,
          }}
        />
      </CardActionArea>
      {imagesComponent}
    </Box>
  );
}

function ResolveCurrencySelect(props) {
  let { handleClose, card } = props;

  // States
  const [bank, setBank] = useState("");
  const [cardCurrencies, setCardCurrencies] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);

  // Hooks
  useEffect(() => {
    CurrencyService.getList({ populate: ["settings", "info"] }).then((r) => {
      setCardCurrencies(filterCurrenciesWithCard(r.data));
    });
  }, []);

  // Calc

  const resolveCurrencyHandler = () => {
    setIsSubmiting(true);
    CardService.setCurrency(card._id, bank)
      .then(() => {
        toast.success("Валюта привязана!");
        handleClose();
      })
      .finally(() => setIsSubmiting(false));
  };

  return (
    <Box sx={{ mt: 4 }}>
      <CurrencySelect
        size="small"
        containerStyle={{ mt: 4 }}
        label="Банк Карты"
        placeholder="Выберите банк"
        currencies={cardCurrencies}
        value={bank}
        setValue={setBank}
      />
      <Grid sx={{ mt: 2 }} container spacing={4}>
        <Grid item xs={6}>
          <Button
            onClick={resolveCurrencyHandler}
            fullWidth
            variant="contained"
            disabled={isSubmiting}
            startIcon={isSubmiting ? <CircularProgress /> : null}
          >
            Верифицировать
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleClose}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function RejectCommentSelect(props) {
  let { handleClose, card } = props;

  // States
  let [isSubmiting, setIsSubmiting] = useState(false);
  let [comments, setComments] = useState([]);
  let [selected, setSelected] = useState(null);
  let [selfComment, setSelfComment] = useState("");
  let [selfCommentError, setSelfCommentError] = useState("");
  useEffect(() => {
    CardCommentService.getAll().then((r) => {
      setComments(r.data);
      setSelected(r.data[0]?._id);
    });
  }, []);

  // Calc
  let commentList = comments.map((c) => {
    return {
      name: c.admin,
      _id: c._id,
    };
  });
  commentList = [
    ...commentList,
    {
      name: "Свой комментарий",
      _id: "self",
    },
  ];
  // Handlers
  const rejectHandler = () => {
    if (selected == "self" && !selfComment) {
      setSelfCommentError("Введите комментарий!");
      return;
    }
    setIsSubmiting(true);
    CardService.reject(card._id, {
      _id: selected,
      text: selfComment,
    })
      .then(() => {
        toast.success("Карта отклоненна!");
        handleClose();
      })
      .finally(() => setIsSubmiting(false));
  };

  return (
    <Box sx={{ mt: 4 }}>
      <RadioGroup
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
      >
        {commentList.map((c) => {
          return (
            <FormControlLabel
              value={c._id}
              label={c.name}
              labelPlacement="left"
              sx={{ mr: 8.8 }}
              control={<Radio />}
            />
          );
        })}
      </RadioGroup>
      {selected == "self" && (
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          size="small"
          error={Boolean(selfCommentError)}
          helperText={selfCommentError}
          label="Комментарий"
          value={selfComment}
          onChange={(e) => {
            setSelfComment(e.target.value);
            setSelfCommentError("");
          }}
        />
      )}

      <Grid sx={{ mt: 2 }} container spacing={4}>
        <Grid item xs={6}>
          <Button
            onClick={rejectHandler}
            fullWidth
            variant="contained"
            disabled={isSubmiting}
            startIcon={isSubmiting ? <CircularProgress /> : null}
          >
            Отказать
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleClose}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CardControl;

function getStatusText(status) {
  switch (status) {
    case 101:
      return "verification";
    case 102:
      return "resolved";
    case 103:
      return "rejected";
    case 104:
      return "banned";
    default:
      return "";
  }
}

{
  /* 
  <div class="card-step" v-if="isVerification">

            <div class="step-content" v-if="card.info && card.info.currency && isVerification">
                <span class="label"> Направление по заявке : {{ card.info.currency }} </span>
            </div>

            <div class="step-content" v-if="isVerification">
                <div class="card-number-wrap" v-if="!isEditNumber">
                    <span class="label">Номер карты:</span>
                    <span class="card-number">{{ card.number }}</span>

                    <svg @click="isEditNumber = true" xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="1 1 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                    <div v-if="card.fromMobile" class="status status-mobile">
                        <p>С телефона</p>
                    </div>
                </div>

                <div class="edit-number" v-if="isEditNumber">
                    <my-input class="spacing" label="Номер карты" v-model.trim="card.number"></my-input>

                    <svg class="save-icon" @click="changeNumber()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-2.5 -5.5 24 24" fill="none" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                </div>
                <div v-if="this.card.images.length >= 0" class="oldPhoto">
                    <div @click="showImages = !showImages" class="">Раннее загруженные изображения</div>
                    <div v-if="showImages" class="">
                        <div class="" v-for="item in card.images" :key="item">
                            <img class="image" :src="getImage(item)" alt="Фотография" />
                        </div>
                    </div>
                </div>

                <img v-if="image" class="image" :src="image" alt="Фотография" />

                <div class="action inner">
                    <div v-if="!showDirection" class="button-group">
                        <my-button class="outline cell cell--6-col cell--12-col-tablet cell--12-col-phone" title="Верификация карты" value="Верифицировать карту" @click.native="verificationCard()" v-if="!type.show"></my-button>
                        <my-button class="outline cell cell--6-col cell--12-col-tablet cell--12-col-phone" :title="directionText" :value="directionText" @click.native="showDirection = true" v-if="!type.show"></my-button>

                        <my-button class="outline cell cell--6-col cell--12-col-tablet cell--12-col-phone" title="Комментарий системы" value="Отказать" @click.native="type.show = true" v-if="!type.show"></my-button>
                        <my-button class="outline cell cell--6-col cell--12-col-tablet cell--12-col-phone" title="Забанить карту" value="Отказать в обслуживание" @click.native="bannedCard()" v-if="!type.show"></my-button>
                    </div>

                    <div class="grid grid--no-spacing verification-action" v-if="type.show">
                        <div v-for="comment in comments" :key="comment._id" class="radio">
                            <input type="radio" :id="comment._id" :value="comment._id" v-model="type.mode" />
                            <label :for="comment._id">{{ comment.admin }}</label>
                        </div>

                        <div class="radio">
                            <input type="radio" id="other" value="admin" v-model="type.mode" />
                            <label for="other">Другое</label>
                        </div>

                        <div class="cell cell--12-col cell--8-col-tablet cell--4-col-phone" v-if="type.mode === 'admin'">
                            <my-input v-model="type.text" label="Комментарий"></my-input>
                        </div>

                        <div class="cell cell--12-col cell--12-col-tablet cell--12-col-phone">
                            <my-button class="outline" value="Отклонить верификацию" @click.native="rejectCard()"></my-button>
                            <my-button class="outline" value="Отмена" @click.native="type.show = false"></my-button>
                        </div>
                    </div>

                    <div v-if="showDirection && !type.show" class="direction-block">
                        <p v-if="directionError" class="error-message">{{ directionError }}</p>
                        <div class="grid grid--no-spacing verification-action">
                            <select v-model="direction" class="select-direction">
                                <option class="requisites" v-for="direction in directions" :value="direction" :key="direction._id">
                                    {{ direction.name }}
                                </option>
                            </select>
                        </div>
                        <div class="button-group">
                            <my-button class="outline" value="Верифицировать" @click.native="directionCard()"></my-button>
                            <my-button class="outline" value="Отмена" @click.native="showDirection = false"></my-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-content">
                <div class="comment" v-if="isRejected">Комментарий: {{ card.comment.admin }}</div>
                <div v-if="!isVerification && this.card.images.length > 0" class="oldPhoto">
                    <div @click="showImages = !showImages" class="">Раннее загруженные изображения</div>
                    <div v-if="showImages" class="">
                        <div class="" v-for="item in card.images" :key="item">
                            <img class="image" :src="getImage(item)" alt="Фотография" />
                        </div>
                    </div>
                </div>

                <div class="" v-if="!isVerification">
                    <img v-if="image" class="image" :src="image" alt="Фотография" />
                </div>

                <div v-if="!isVerification" class="bannedCard">
                    <my-button class="outline cell cell--6-col cell--12-col-tablet cell--12-col-phone" :title="directionText" :value="directionText" @click.native="showDirection = true" v-if="isResolved && !showDirection"></my-button>

                    <my-button v-if="!isBanned && !showDirection" class="outline" value="Отказать в обслуживание" @click.native="bannedCard()"></my-button>
                    <my-button style="margin-left: 1rem" v-if="isBanned" class="outline" value="Разблокировать карту" @click.native="bannedCard()"></my-button>

                    <div v-if="showDirection" class="direction-block">
                        <p v-if="directionError" class="error-message">{{ directionError }}</p>
                        <div class="grid grid--no-spacing verification-action">
                            <select v-model="direction" class="select-direction">
                                <option class="requisites" v-for="direction in directions" :value="direction" :key="direction._id">
                                    {{ direction.name }}
                                </option>
                            </select>
                        </div>
                        <div class="button-group">
                            <my-button class="outline" value="Верифицировать" @click.native="directionCard()"></my-button>
                            <my-button class="outline" value="Отмена" @click.native="showDirection = false"></my-button>
                        </div>
                    </div>
                </div>
            </div>
  */
}
