import axios from "axios";

class SystemSettings {
  get() {
    return axios.get("/system-setting");
  }
  change(data) {
    return axios.put("/system-setting", data);
  }
}

export default new SystemSettings();
