import { useState, useCallback } from "react";
// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";

import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";

import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";


// ** Third Party Imports
import { useForm, Controller } from "react-hook-form";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { CardActions } from "@mui/material";

//Toast
import toast from "react-hot-toast";

// Services
import ClientService from "src/service/Client";

function ChangePassword({ id }) {
  // State
  let [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  let [isSubmiting, setSubmiting] = useState(false);

  // Handlers
  const toggleShowPasswordHandler = useCallback((name) => {
    setShowPassword(
      Object.assign({}, showPassword, { [name]: !showPassword[name] })
    );
  });

  const {
    reset,
    control,
    setValue,
    setError,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "onSubmit",
  });

  const onSubmit = handleSubmit((data) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", { message: "Пароли не совпадают!" });
      return;
    }
    setSubmiting(true);
    ClientService.setNewPassword(id, data.password)
      .then(() => {
        toast.success("Пароль изменен!");
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <Card>
      <form noValidate onSubmit={onSubmit}>
        <CardHeader title="Изменение пароля" />
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Новый пароль"
                    value={value}
                    id="user-view-security-new-password"
                    onChange={onChange}
                    type={showPassword.password ? "text" : "password"}
                    error={Boolean(errors.password?.message)}
                    {...register("password", {
                      required: "Введите пароль",
                      minLength: {
                        message: "Мин. длина: 6 символов",
                        value: 6,
                      },
                      maxLength: 100,
                    })}
                    helperText={errors.password?.message}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => toggleShowPasswordHandler("password")}
                          aria-label="toggle password visibility"
                        >
                          <Icon
                            icon={
                              showPassword.password
                                ? "mdi:eye-outline"
                                : "mdi:eye-off-outline"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name={"confirmPassword"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Подтверждение нового пароля"
                    value={value}
                    id="user-view-security-new-password"
                    onChange={onChange}
                    type={showPassword.confirmPassword ? "text" : "password"}
                    error={Boolean(errors.confirmPassword?.message)}
                    {...register("confirmPassword", {
                      required: "Введите пароль",
                      minLength: {
                        message: "Мин. длина: 6 символов",
                        value: 6,
                      },
                      maxLength: 100,
                    })}
                    helperText={errors.confirmPassword?.message}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => toggleShowPasswordHandler("password")}
                          aria-label="toggle password visibility"
                        >
                          <Icon
                            icon={
                              showPassword.confirmPassword
                                ? "mdi:eye-outline"
                                : "mdi:eye-off-outline"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            type="submit"
            variant="contained"
          >
            Сохранить
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}

export default ChangePassword;
