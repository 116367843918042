import React, { useState, useCallback } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Custom componenents
import CustomChip from "src/@core/components/mui/chip";
import CustomAvatar from "src/@core/components/mui/avatar";
// ** Custom Components Imports

// Configs
import WEBSITES from "src/configs/websites";

// Configs
import CONSTANTS from "src/constants/index";
// Helpers
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

// Components
import EditClientModal from "./EditClientModal";
import ClientAccrual from "./ClientAccrual";
import ClientReferrer from "./ClientReferrer";

// Components
import ClientService from "src/service/Client";
// ** Toaster Import
import toast from "react-hot-toast";
//
import moment from "moment";
import "moment/locale/ru";
import { observer } from "mobx-react-lite";

// Modules
import ClientLoyaltyChange from "src/modules/Clients/ClientLoyalty";

// ** Vars

const ClientDetails = ({ data, updateData }) => {
  // State
  let [showEdit, setShowEdit] = useState(false);
  // Spinners
  let [spinner, setSpinner] = useState({
    delete: false,
    restore: false,
  });

  // Calc
  let isOnline = data?.info?.online;

  // Handlers
  const clearSpinnersHandler = () => {
    setSpinner({ delete: false, restore: false });
  };

  const handleChangeShowEdit = useCallback(() => {
    setShowEdit(!showEdit);
  });

  const changeBannedHandler = (banned) => {
    ClientService.change(data._id, { banned }).then(() => {
      updateData({ banned });
      toast.success(`Клиент ${banned ? "заблокирован" : "разблокирован"}!`);
    });
  };

  const changeVerifiedHandler = () => {
    ClientService.setVerified(data._id).then(() => {
      updateData({ verified: true });
      toast.success("Клиент подтвержден!");
    });
  };

  const deleteClientHandler = () => {
    setSpinner(Object.assign({}, spinner, { delete: true }));
    ClientService.change(data._id, { deleted: true })
      .then(() => {
        updateData({ deleted: true });
        toast.success(`Клиент удален!`);
      })
      .finally(() => {
        clearSpinnersHandler();
      });
  };

  const restoreClientHandler = () => {
    setSpinner(Object.assign({}, spinner, { restore: true }));
    ClientService.change(data._id, { deleted: false })
      .then(() => {
        updateData({ deleted: false });
        toast.success(`Клиент восстановлен!`);
      })
      .finally(() => {
        clearSpinnersHandler();
      });
  };

  let loyaltyList = Object.entries(CONSTANTS.loalty).map((l) => ({
    name: l[1],
    value: l[0],
  }));

  let isDeleted = data.deleted;

  const flagIcon = (
    <Icon
      icon={
        data.locale == "eng"
          ? "twemoji:flag-united-kingdom"
          : "twemoji:flag-russia"
      }
    />
  );

  return (
    <Grid container spacing={6}>
      {/* Modal edit client */}
      <EditClientModal
        data={data}
        show={showEdit}
        updateData={updateData}
        handleClose={handleChangeShowEdit}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6"  sx={{wordBreak: 'break-all'}}>{data.email}</Typography>

                <CustomChip
                  sx={{ p: 0.5, ml: 2 }}
                  label={isOnline ? "Online" : "Offline"}
                  skin="light"
                  color={isOnline ? "success" : "error"}
                />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={changeVerifiedHandler}
                  disabled={data.verified}
                  sx={{
                    "&:disabled": {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                >
                  <Icon
                    color="success"
                    icon={
                      data.verified
                        ? "ri:mail-check-line"
                        : "ri:mail-close-line"
                    }
                    fontSize={22}
                  />
                </IconButton>

                <IconButton
                  onClick={() => changeBannedHandler(!data.banned)}
                  size="small"
                  sx={{ borderRadius: 1, ml: 2 }}
                  color={data.banned ? "error" : "success"}
                >
                  <Icon
                    color={data.banned ? "success" : "error"}
                    icon={
                      data.banned
                        ? "material-symbols:lock-outline"
                        : "material-symbols:lock-open-outline"
                    }
                    fontSize={22}
                  />
                </IconButton>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Icon icon="dashicons:admin-site-alt3" fontSize={20} />
                <Typography sx={{ ml: 1, mr: 3 }} variant="subtitle1" noWrap>
                  {WEBSITES.find((s) => s.id === data.sc).name}
                </Typography>

                {flagIcon}
              </Box>
              <ClientLoyaltyChange init={data.loyalty} clientId={data._id} />
            </Box>
          </CardContent>
          {data.exchangeCounter && (
            <ClientCounter data={data.exchangeCounter} />
          )}
          {data.verified && data.referral && data.accrual && (
            <ClientAccrual
              updateData={updateData}
              clientId={data._id}
              accrual={data.accrual}
              referral={data.referral}
            />
          )}

          {data.referrer && data.referrer._id && (
            <ClientReferrer
              referrerEmail={data.referrer.email}
              referrerId={data.referrer._id}
              updateData={updateData}
              clientId={data._id}
              dncr={data.dncr}
            />
          )}

          <CardContent>
            <Divider sx={{ mt: (theme) => `${theme.spacing(4)} !important` }} />
            <Typography variant="subtitle1">О клиенте</Typography>
            <Box sx={{ pt: 2, pb: 1 }}>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  Имя:
                </Typography>
                <Typography variant="body2">{data.name}</Typography>
              </Box>
              {data.telegram && (
                <Box sx={{ display: "flex", mb: 2.7 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mr: 2, color: "text.primary" }}
                  >
                    Telegram:
                  </Typography>
                  <Typography variant="body2">{data.telegram}</Typography>
                </Box>
              )}

              {data.phone && (
                <Box sx={{ display: "flex", mb: 2.7 }}>
                  <Typography
                    sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                  >
                    Номер телефона:
                  </Typography>
                  <Typography variant="body2">+{data.phone}</Typography>
                </Box>
              )}

              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  Зерегистрирован:
                </Typography>
                <Typography variant="body2">
                  {moment(data.createdAt).format("DD MMMM YYYY, в HH:mm")}
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            {/* */}

            {isDeleted ? (
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={restoreClientHandler}
                startIcon={
                  spinner.restore ? <CircularProgress size="1rem" /> : null
                }
                disabled={spinner.restore}
              >
                Восстановить
              </Button>
            ) : (
              <React.Fragment>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={handleChangeShowEdit}
                >
                  Изменить
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={deleteClientHandler}
                  startIcon={
                    spinner.delete ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={spinner.delete}
                >
                  Удалить
                </Button>
              </React.Fragment>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

function ClientCounter(props) {
  let { data } = props;

  return (
    <>
      <CardContent sx={{ my: 1 }}>
        <Divider sx={{ mt: (theme) => `${theme.spacing(4)} !important` }} />
        <Typography variant="subtitle1">Обмены</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ mr: 8, display: "flex", alignItems: "center" }}>
            <CustomAvatar
              color="success"
              skin="light"
              variant="rounded"
              sx={{ mr: 3 }}
            >
              <Icon icon="icon-park-outline:doc-success" />
            </CustomAvatar>
            <div>
              <Typography variant="h6" sx={{ lineHeight: 1.3 }}>
                {data.completed}
              </Typography>
              <Typography variant="body2">Выполненных </Typography>
            </div>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CustomAvatar
              color="error"
              skin="light"
              variant="rounded"
              sx={{ mr: 3 }}
            >
              <Icon icon="icon-park-outline:doc-fail" />
            </CustomAvatar>
            <div>
              <Typography variant="h6" sx={{ lineHeight: 1.3 }}>
                {data.rejected}
              </Typography>
              <Typography variant="body2">Отклоненных </Typography>
            </div>
          </Box>
        </Box>
        <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
          <CustomAvatar skin="light" variant="rounded" sx={{ mr: 3 }}>
            <Icon icon="ic:twotone-currency-exchange" />
          </CustomAvatar>
          <div>
            <Typography variant="h6" sx={{ lineHeight: 1.3 }}>
              {getPriceCurrentFiat(data.total)}
            </Typography>
            <Typography variant="body2">Сумма обменов </Typography>
          </div>
        </Box>
      </CardContent>
    </>
  );
}

export default observer(ClientDetails);
