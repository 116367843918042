export default function searchCourse(exchanges, search) {
  return search
    ? exchanges.filter((c) => {
        let takeCur = c.tk.cur;
        let giveCur = c.gv.cur;

        let s = search.toLowerCase();

        return (
          takeCur.name.toLowerCase().includes(s) ||
          takeCur.code.toLowerCase().includes(s) ||
          giveCur.name.toLowerCase().includes(s) ||
          giveCur.code.toLowerCase().includes(s)
        );
      })
    : exchanges;
}
