import calcWithPrecision from "./calcWithPrecision";

export default function getExchangeAmount(exchange, get, amount) {
  let rate = exchange.rate;
  let reverse = rate.rvs;
  let service = rate.srv;
  let takeCurrency = exchange.tk.cur;
  let giveCurrency = exchange.gv.cur;
  let precisionTake = takeCurrency.precision;
  let precisionGive = giveCurrency.precision;

  let result = 0;


  if (get == "give") {
    if (reverse === "l") {
      result = calcWithPrecision(amount / service, precisionGive);
    } else if (reverse === "r") {
      result = calcWithPrecision(service * amount, precisionGive);
    } else if (reverse === "n") {
      result = calcWithPrecision(amount, precisionGive);
    }


    //     if (inRub) {
    //       result = result * give.mvalue;
    //     }
  } else {
    if (reverse === "l") {
      result = calcWithPrecision(amount * service, precisionTake);
    } else if (reverse === "r") {
      result = calcWithPrecision(amount / service, precisionTake);
    } else if (reverse === "n") {
      result = calcWithPrecision(amount, precisionTake);
    }
    // if (inRub) {
    //   result = result * take.mvalue;
    // }
  }


  if (result && result > 0) {
    return result;
  } else {
    return 0;
  }
}
//
//
//
//   let take = exchange.currency.take;
//   let give = exchange.currency.give;

//   let result = 0;

//   if (get == "give") {

//   } else {

//   }

// }

//   tk: {
//     type: Types.ObjectId,
//     ref: "SiteCurrency",
//     required: true,
//   },
//   gv: {
//     type: Types.ObjectId,
//     ref: "SiteCurrency",
//     required: true,
//   },
//   limit: {
//     mn: {
//       type: Number,
//       default: 1,
//       min: [0, "Мин"],
//     },
//     mx: {
//       type: Number,
//       default: 1,
//       min: [0, "Мин"],
//     },
//   },
//   enbl: {
//     type: Boolean,
//     default: false,
//   },
//   del: {
//     type: Boolean,
//     default: false,
//   },
//   rate: {
//     prc: {
//       type: Number,
//       default: 0,
//       min: [0, "percenr error"],
//     },
//     isbn: {
//       type: Boolean,
//       default: true,
//     },
//     in: {
//       type: Number,
//       default: 1,
//       min: [0, "percenr error"],
//     },
//     srv: {
//       type: Number,
//       default: 1,
//       min: [0, "percenr error"],
//     },
//     rvs: {
//       type: String,
//       default: "n",
//       enum: ["l", "r", "n"],
//     },
//   },
