// ** MUI Imports
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

// Vars
let selectList = [
  {
    name: "Карусель",
    value: "carousel",
  },
  {
    name: "По мере заполнения",
    value: "full",
  },
  {
    name: "По наименьшему остатку",
    value: "smallest",
  },
  {
    name: "Равномерное распределение",
    value: "turn",
  },
];

function RoundSelect({ initValue, value, setValue, style = {} }) {
  return (
    <FormControl size="small" fullWidth sx={{ ...style }}>
      <InputLabel id="kit-round-label">Обход</InputLabel>
      <Select
        size="small"
        label="Обход"
        defaultValue={initValue}
        id="kit-round"
        labelId="kit-round-label"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {selectList.map((i) => (
          <MenuItem value={i.value} key={i.value}>
            {i.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RoundSelect;
