// States
import { useState, useEffect } from "react";

// Mui
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  CardHeader,
  Fab,
} from "@mui/material";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Modules
import RoleEdit from "./RoleEdit";

// Services
import ServiceRolePermission from "src/service/RolePermission";

function Roles() {
  let [openEdit, setOpenEdit] = useState(false);
  let [roleData, setRoleData] = useState(null);
  let [roleList, setRoleList] = useState([]);

  useEffect(() => {
    ServiceRolePermission.getAll().then((r) => setRoleList(r.data));
  }, []);

  const openEditHandler = (data) => {
    setRoleData(data);
    setOpenEdit(true);
  };

  // Item Handlers
  const addItemHandler = (newItem) => {
    setRoleList((list) => [newItem, ...list]);
  };

  const changeItemHandler = (item) => {
    setRoleList((list) => list.map((i) => (i._id == item._id ? item : i)));
  };

  return (
    <Card>
      <RoleEdit
        data={roleData}
        addItemHandler={addItemHandler}
        changeItemHandler={changeItemHandler}
        open={openEdit}
        closeHandler={() => {
          setOpenEdit(false);
          setRoleData(null);
        }}
      />
      <CardHeader
        title={"Роли"}
        {...{
          action: (
            <Fab
              color="primary"
              variant="extended"
              size="medium"
              sx={{ "& svg": { mr: 1 } }}
              onClick={() => setOpenEdit(true)}
            >
              <Icon icon="mdi:plus" />
              Добавить
            </Fab>
          ),
        }}
      />
      <List>
        {roleList.map((r) => {
          return (
            <ListItem key={r._id} disablePadding>
              <ListItemButton>
                <ListItemText primary={r.name} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => openEditHandler(r)} edge="end">
                    <Icon icon="material-symbols:edit" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}

export default Roles;
