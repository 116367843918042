import { useState, useEffect } from "react";
//MUi
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { useTheme, useMediaQuery, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { CardHeader } from "@mui/material";

// Custom components
import LoyaltySelect from "src/components/LoyaltySelect";
import RoundSelect from "./Ui/RoundSelect";
import ActiveSwitch from "./Ui/ActiveSwitch";
import CustomTextField from "./Ui/CustomTextField";
import BalanceInfo from "./Ui/BalanceInfo";
import FiatRequisite from "./FiatRequisite";

// Modules
import DialogChangeBalance from "./DialogChangeBalance";
import RequisitesWrap from "./RequisitesWrap";

// Services
import WalletService from "src/service/Wallet";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import { toast } from "react-hot-toast";

// Socket
import { socket } from "src/socket";

function FiatKit(props) {
  let {
    data,
    style = {},
    deleteItemHandler,
    isGeneral = false,
    openTransferSum,
    currency,
    specific = "",
  } = props;

  // States
  let [showMore, setShowMore] = useState(false);
  let [kit, setKit] = useState(data);
  let [name, setName] = useState(data.name);

  // Hooks
  useEffect(() => {
    socket.on(`update_kit_${data._id}`, updateKitHandler);
    return () => {
      socket.off(`update_kit_${data._id}`);
    };
  }, []);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // Handlers
  const updateKitHandler = (upd) => {
    setKit((k) => Object.assign({}, k, upd));
  };
  const changeLoyaltyHadnler = (loyalty) => {
    WalletService.changeKit({
      _id: kit._id,
      loyalty,
    }).then(() => {
      toast.success(`Уровень лояльности изменен!`);
    });
  };
  const changeRoundHadnler = (round) => {
    WalletService.changeKit({
      _id: kit._id,
      round,
    }).then(() => {
      toast.success(`Обход комлекта изменен!`);
    });
  };
  const changeEnabledHadnler = (enabled) => {
    WalletService.changeKit({
      _id: kit._id,
      enabled,
    }).then(() => {
      toast.success(`Комплект ${enabled ? "включен" : "выключен"}!`);
    });
  };
  const saveNameHandler = (v) => {
    WalletService.changeKit({
      _id: kit._id,
      name,
    }).then(() => {
      toast.success(`Комплект изменен!`);
    });
  };

  const deleteHandler = () => {
    if (!window.confirm("Удалить комлект?")) return;

    WalletService.deleteKit(kit._id).then((r) => {
      deleteItemHandler(kit._id);
      toast.success(`Комплект удален!`);
    });
  };

  const btns = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
          sx: "row-reverse",
          md: "row",
        },
        alignItems: "center",
      }}
    >
      <IconButton sx={{ mt: 2 }} onClick={() => setShowMore(!showMore)}>
        <Icon icon="material-symbols:expand-more-rounded" />
      </IconButton>

      {kit.balance <= 0 && (
        <IconButton
          onClick={deleteHandler}
          sx={{
            ml: 2,
            borderRadius: 1,
          }}
          color="error"
        >
          <Icon icon="ic:baseline-delete-forever" />
        </IconButton>
      )}
    </Box>
  );

  // Calc
  let kitCurrency = data.wallet.currency;
  let walletId = currency?.wallet?._id;
  let kitNumber = kit.isGeneral
    ? kit.wallets.find((w) => w.wallet + "" == walletId)?.number
    : kit.number;

  let addedStyle = {};

  if (!isGeneral && kit.isGeneral) {
    addedStyle = {
      "pointer-events": "none",
      opacity: 0.7,
      ml: 3,
    };
  }

  return (
    <Box sx={{ ...style, ...addedStyle }}>
      <Card>
        <CardContent>
          <Grid spacing={4} container>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ActiveSwitch
                  isKit
                  hideNumber={isGeneral}
                  number={kitNumber}
                  enabled={kit.enabled}
                  onChange={changeEnabledHadnler}
                  style={{ mr: 3 }}
                />
                <CustomTextField
                  label="Название"
                  placeholder="Введите название"
                  isChanged={name.trim() !== kit.name.trim()}
                  saveHandler={saveNameHandler}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>

              {isDesktop && btns}
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <LoyaltySelect
                initValue={kit.loyalty}
                value={kit.loyalty}
                setValue={changeLoyaltyHadnler}
                style={{ mb: 2 }}
              />
              <RoundSelect
                initValue={kit.round}
                value={kit.round}
                setValue={changeRoundHadnler}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <BalanceInfo
                balance={kit.balance}
                disabledBalance={kit.disabledBalance}
                isGeneral={isGeneral}
                currency={kitCurrency}
              />
            </Grid>
          </Grid>
          {!isDesktop && btns}
        </CardContent>
      </Card>
      <Collapse in={showMore} timeout="auto" unmountOnExit>
        <Requisites
          specific={specific}
          openTransferSum={openTransferSum}
          currency={kitCurrency}
          kit={kit}
        />
      </Collapse>
    </Box>
  );
}

function Requisites(props) {
  let { kit, currency, openTransferSum, specific } = props;
  // State
  const [showChangeBalance, setShowChangeBalance] = useState({
    show: false,
    action: "add",
    _id: "",
  });
  const [loaded, setLoaded] = useState(false);
  const [requisites, setRequisites] = useState([]);

  const toReq = (id) => {
    window.location.href = "#" + id;
  };

  //Hooks
  useEffect(() => {
    socket.on("update_item_req", (r) => {
      console.log("update item req");
      setRequisites((list) =>
        list.map((req) => (req._id == r._id ? Object.assign({}, req, r) : req))
      );
    });
    WalletService.getRequisiteList(kit._id).then((r) => {
      setRequisites(r.data);
      setLoaded(true);
    });

    return () => {
      socket.off(`update_item_req`);
    };
  }, []);

  // Handlers

  const openChangeBalance = (action, _id) => {
    setShowChangeBalance({
      show: true,
      action,
      _id,
    });
  };
  const closeChangeBalance = () => {
    setShowChangeBalance(Object.assign({}, showChangeBalance, { show: false }));
  };

  // Requisites
  const addRequisiteHandler = () => {
    WalletService.createRequisite({
      kit: kit._id,
    }).then((r) => {
      setRequisites((list) => [r.data, ...list]);
      toast.success(`Реквизит добавлен!`);
    });
  };

  const deleteRequisiteHandler = (id) => {
    if (!window.confirm("Удалить реквизит?")) return;
    WalletService.deleteRequisite(id).then((r) => {
      setRequisites((list) => list.filter((r) => r._id !== id));
      toast.success(`Реквизит удален!`);
    });
  };

  if (!loaded)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 3,
        }}
      >
        <CircularProgress />
      </Box>
    );

  // Calc
  let requisitesSorted = requisites.sort((a, b) => {
    return a.number - b.number;
  });

  return (
    <Box>
      <DialogChangeBalance
        action={showChangeBalance.action}
        show={showChangeBalance.show}
        closeHandler={closeChangeBalance}
        isKit={false}
        _id={showChangeBalance._id}
      />
      <Grid sx={{ mt: 2 }} container spacing={4}>
        <Grid item xs={12} md={2}>
          {!!requisitesSorted.length && (
            <Card>
              <CardHeader
                sx={{ px: 3, py: 2 }}
                title={<Typography variant="body1">Быстрый доступ</Typography>}
              />
              <List
                sx={{
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                  overflowX: {
                    xs: "scroll",
                    md: "none",
                  },
                  display: "flex",
                }}
                dense
              >
                {requisitesSorted.map((i) => {
                  let textColor = "inherit";
                  if (!i.enabled) {
                    textColor = "secondary";
                  }
                  if (i.blocked) {
                    textColor = "error";
                  }
                  return (
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ px: 3, py: 0 }}
                        onClick={() => toReq(i._id)}
                        key={i._id}
                      >
                        <ListItemText
                          color="secondary"
                          primary={
                            <Typography color={textColor} variant="body2">
                              {`${i.number}.${i.name || "-"}`}
                            </Typography>
                          }
                          secondary={
                            <Typography color={textColor} variant="body2">
                              {i.balance + ` ${currency.code}`}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} md={10}>
          <RequisitesWrap
            requisites={requisitesSorted}
            addHandler={addRequisiteHandler}
            RequisiteComponent={FiatRequisite}
            RequisiteComponentProps={{
              specific,
              addToBalanceHandler: (id) => openChangeBalance("add", id),
              subToBalanceHandler: (id) => openChangeBalance("sub", id),
              deleteHandler: deleteRequisiteHandler,
              openTransferSum: (id) =>
                openTransferSum({ id, isKit: false }, currency),
              currency: currency,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FiatKit;
