import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
// Forms
import { useForm } from "react-hook-form";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";
// Services
import SiteCurrencyService from "src/service/SiteCurrency";
import ExchangeService from "src/service/Exchange";

import WEBSITES from "src/configs/websites";

// Helpers
import calcExchangeAmount from "src/helpers/calcExchangeAmount";

// Services
import OrderService from "src/service/Order";

//
import Fields from "./Fields";

import toast from "react-hot-toast";

// Config
import ConfigApi from "src/configs/api";
// Выбор обменной пары c учетом сайта
// Ввод получаю и отдаю с калькуляцией
// заполнение полнй

// Modules
import UploadImg from "./UploadImg";

// Calc
const SiteSelectList = WEBSITES.map((s) => {
  return {
    value: s.id,
    label: s.name,
  };
});

function CalcOrder(props) {
  let { sc, handleClose, clientId } = props;
  const [takeAmount, setTakeAmount] = useState(0);
  const [giveAmount, setGiveAmount] = useState(0);
  let [baseCurrencies, setBaseCurrencies] = useState([]);
  let [baseId, setBaseId] = useState("");
  let [quoteId, setQuoteId] = useState("");
  let [exchanges, setExchanges] = useState([]);
  let [exchange, setExchange] = useState(null);
  let [isSubmiting, setIsSubmiting] = useState(false);
  let [takeFields, setTakeFields] = useState([]);
  let [giveFields, setGiveFields] = useState([]);
  let [imageName, setImageName] = useState("");
  let [showUploadImage, setShowUploadImage] = useState("");
  let siteName = SiteSelectList.find((s) => s.value == sc)?.label;

  let exchangeGiveCurrencies = exchanges.map((e) => e.gv);
  let exchangeId = exchanges.find(
    (e) => e.tk._id == baseId && e.gv._id == quoteId
  )?._id;

  // Hooks
  useEffect(() => {
    SiteCurrencyService.getSiteCurrencyList({ sc, enbl: true }).then((r) =>
      setBaseCurrencies(r.data)
    );
  }, [sc]);

  useEffect(() => {
    if (!baseId) return;
    ExchangeService.getExchangeList({
      sc: sc,
      tk: baseId,
      enbl: true,
    }).then((r) => setExchanges(r.data));
  }, [baseId]);

  useEffect(() => {
    if (!exchangeId) return;

    ExchangeService.getOneFullExchang(exchangeId).then((r) =>
      setExchange(r.data)
    );
  }, [exchangeId]);

  // Handlers
  const changeAmountsHandler = (direction, value) => {
    if (direction == "give") {
      setGiveAmount(value);
      let amountTake = calcExchangeAmount(exchange, "take", +value);
      setTakeAmount(amountTake);
    } else {
      setTakeAmount(value);
      let amountGive = calcExchangeAmount(exchange, "give", +value);
      setGiveAmount(amountGive);
    }
  };

  const closeUploadImage = () => {
    setShowUploadImage("");
    setImageName("");
  };

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // Submit

  const onSubmit = handleSubmit((data) => {
    setIsSubmiting(true);
    let tf = takeFields.map((f) => {
      return Object.assign({}, f, { value: data[f._id] });
    });
    let gf = giveFields.map((f) => {
      return Object.assign({}, f, { value: data[f._id] });
    });
    OrderService.create({
      exchange: exchange._id,
      takeAmount: +takeAmount,
      giveAmount: +giveAmount,
      cardImage: imageName || undefined,
      client: clientId,
      fields: {
        take: tf,
        give: gf,
      },
    })
      .then((r) => {
        let data = r.data;
        let status = data.status;
        if (status) {
          setShowUploadImage(status);
        } else {
          toast.success("Заявка создана!");
        }
        //
        // handleClose();
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  });

  let fieldsData = !!exchange
    ? {
        fields: {
          take: exchange?.tk?.cur?.info?.fields?.take,
          give: exchange?.gv?.cur?.info?.fields?.give,
        },
      }
    : null;

  useEffect(() => {
    if (!exchange) return;


    setTakeFields(fieldsData.fields.take);
    setGiveFields(fieldsData.fields.give);

    let list = [...fieldsData.fields.take, ...fieldsData.fields.give];

    reset({});
    let newValues = {};
    list.forEach((f) => {
      newValues[f._id] = "";
      setValue(f._id, "");
    });
  }, [exchange?._id]);

  return (
    <Box sx={{ mt: 2 }}>
      <UploadImg
      onSubmit={onSubmit}
        setImageName={setImageName}
        handleClose={closeUploadImage}
        show={!!showUploadImage}
      />
      <Typography sx={{ mb: 2 }} variant="h6">
        {siteName}
      </Typography>
      <Grid sx={{}} container spacing={4}>
        <Grid item xs={6}>
          <SelectDirectionCurrency
            label="Валюта отдаю"
            value={baseId}
            setValue={setBaseId}
            currencies={baseCurrencies}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectDirectionCurrency
            label="Валюта получаю"
            value={quoteId}
            setValue={setQuoteId}
            currencies={exchangeGiveCurrencies}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={4}>
        <Grid item xs={6}>
          <TextField
            value={takeAmount}
            onChange={(e) => changeAmountsHandler("take", e.target.value)}
            fullWidth
            label="Отдаю"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={giveAmount}
            onChange={(e) => changeAmountsHandler("give", e.target.value)}
            fullWidth
            label="Получаю"
          />
        </Grid>
      </Grid>
      {!!exchange && (
        <Fields
          exchangeId={exchange?._id}
          control={control}
          errors={errors}
          register={register}
          takeFields={takeFields}
          giveFields={giveFields}
        />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          type="submit"
          onClick={onSubmit}
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          Создать
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Отменить
        </Button>
      </Box>
    </Box>
  );
}

function SelectDirectionCurrency(props) {
  let { label, value, setValue, currencies } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        fullWidth
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
        renderValue={(value) => {
          let c = currencies.find((c) => c._id == value);
          if (!c) return "";
          let cur = c.cur;

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomAvatar
                skin="light"
                variant="rounded"
                sx={{ width: 24, height: 24, mr: 3 }}
              >
                <Avatar
                  src={ConfigApi.PUBLIC_IMAGES + cur.image}
                  alt={cur.name}
                  sx={{ height: 20, width: 20 }}
                />
              </CustomAvatar>
              {cur.name}
            </Box>
          );
        }}
      >
        {currencies.map((c) => {
          let cur = c.cur;
          return (
            <MenuItem
              value={c._id}
              key={c._id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CustomAvatar
                skin="light"
                variant="rounded"
                sx={{ width: 24, height: 24, mr: 3 }}
              >
                <Avatar
                  src={ConfigApi.PUBLIC_IMAGES + cur.image}
                  alt={cur.name}
                  sx={{ height: 20, width: 20 }}
                />
              </CustomAvatar>
              {cur.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default CalcOrder;
