import { useState } from "react";
// Mui
import {
  CardContent,
  CardHeader,
  Card,
  List,
  ListItem,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

// Service
import LocalizationService from "src/service/Localization";
import { toast } from "react-hot-toast";

function CurrencyName({ currencyNames }) {
  let [list, setList] = useState(currencyNames);
  let [isSubmit, setSubmit] = useState(false);

  // Handlers
  const changeItem = (text, id) => {
    setList((list) =>
      list.map((l) => {
        if (l._id == id) {
          return Object.assign({}, l, { eng: text });
        } else {
          return l;
        }
      })
    );
  };

  const saveHandler = () => {
    setSubmit(true);
    LocalizationService.changeData({
      currencyNames: list,
    }).then((r) => {
      setList(r.data.currencyNames);
      setSubmit(false);
      toast.success('Данные сохранены')
    });
  };

  return (
    <Card>
      <CardHeader sx={{ py: 2, px: 4 }} title="Название валют" />
      <List
        dense
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {list.map((i) => {
          return <ItemText changeItem={changeItem} key={i._id} {...i} />;
        })}
      </List>
      <CardContent>
        <Button
          onClick={saveHandler}
          disabled={isSubmit}
          startIcon={isSubmit ? <CircularProgress /> : null}
          variant="contained"
        >
          Сохранить
        </Button>
      </CardContent>
    </Card>
  );
}

function ItemText({ _id, eng, rus, changeItem }) {
  let [text, setText] = useState(eng);

  const onBlur = () => {
    changeItem(text, _id);
  };

  return (
    <ListItem>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={6}>
          <Typography variant="body1">{rus}</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            value={text}
            onBlur={onBlur}
            onChange={(e) => setText(e.target.value)}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default CurrencyName;
