import { useState, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import { toast } from "react-hot-toast";

// Services
import CurrencyService from "src/service/Currencies";
import SiteCurrencyService from "src/service/SiteCurrency";

// Custom components
import CurrencySelect from "src/components/CurrencySelect";

// Config
import WEBSITES from "src/configs/websites";

export default function DialogAddCurrency(props) {
  let { show, closeHandler, site } = props;

  // States
  const [selectedCur, setSelectedCur] = useState(null);
  const [isSubmiting, setSubmiting] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  const s = WEBSITES.find((s) => s.id === site);

  // Hooks
  useEffect(() => {
    CurrencyService.getList({}).then((r) => {
      setCurrencies(r.data);
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    setSubmiting(true);

    SiteCurrencyService.addSiteCurrency({
      id: site,
      currency: selectedCur,
    })
      .then(() => {
        toast.success("Валюта добавлена!");
        closeHandler();
      })
      .finally(() => setSubmiting(false));
  };

  return (
    <Box>
      <Dialog
        fullWidth
        open={show}
        maxWidth="xs"
        scroll="body"
        onClose={closeHandler}
      >
        <form onValidate onSubmit={onSubmit}>
          <DialogContent
            sx={{
              pb: 6,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: "relative",
            }}
          >
            <IconButton
              size="small"
              onClick={closeHandler}
              sx={{ position: "absolute", right: "1rem", top: "1rem" }}
            >
              <Icon icon="mdi:close" />
            </IconButton>
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
                Добавить валюту
              </Typography>
              <Typography variant="body2">
                Добавьте уникальную валюту которой еще нет у сайта {s.name}
              </Typography>
            </Box>
            <CurrencySelect
              label="Валюта"
              placeholder="Выбирете валюту"
              value={selectedCur}
              setValue={setSelectedCur}
              currencies={currencies}
            />
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
          >
            <Button
              startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmiting}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={onSubmit}
              type="submit"
            >
              Добавить
            </Button>
            <Button variant="outlined" color="secondary" onClick={closeHandler}>
              Отмена
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
