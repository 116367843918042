import axios from "axios";

class Info {
  getBtcRate() {
    return axios.get("/info/btc-rate");
  }
  getFiatPrice() {
    return axios.get("/info/fiat-price");
  }
}

export default new Info();
