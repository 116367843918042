import { useState, useEffect } from "react";
//MUi
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

import { useTheme, useMediaQuery } from "@mui/material";

// Custom Ui
import BalanceInfo from "./Ui/BalanceInfo";
import ActiveSwitch from "./Ui/ActiveSwitch";
import CustomTextField from "./Ui/CustomTextField";

// Modules
import CryptoRequisite from "./CryptoRequisite";
import RequisitesWrap from "./RequisitesWrap";

// Custom components
import LoyaltySelect from "src/components/LoyaltySelect";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import WalletService from "src/service/Wallet";

// Socket
import { socket } from "src/socket";
// Toast
import { toast } from "react-hot-toast";

// Modules
import DialogChangeBalance from "./DialogChangeBalance";

export default function CryptoKit(props) {
  let {
    data,
    currency,
    deleteItemHandler,
    style = {},
    openTransferSum,
  } = props;
  const theme = useTheme();


  // State
  let [name, setName] = useState(data.name);
  let [kit, setKit] = useState(data);
  let [showMore, setShowMore] = useState(false);
  const [showChangeBalance, setShowChangeBalance] = useState({
    show: false,
    action: "add",
    _id: "",
  });

  // Hooks
  useEffect(() => {
    socket.on(`update_kit_${data._id}`, updateKitHandler);
    return () => {
      socket.off(`update_kit_${data._id}`);
    };
  }, []);

  // Handlers
  const updateKitHandler = (upd) => {
    setKit((k) => Object.assign({}, k, upd));
  };
  const changeLoyaltyHadnler = (loyalty) => {
    WalletService.changeKit({
      _id: kit._id,
      loyalty,
    }).then(() => {
      toast.success(`Уровень лояльности изменен!`);
    });
  };
  const changeEnabledHadnler = (enabled) => {
    WalletService.changeKit({
      _id: kit._id,
      enabled,
    }).then(() => {
      toast.success(`Комплект ${enabled ? "включен" : "выключен"}!`);
    });
  };
  const saveNameHandler = (v) => {
    WalletService.changeKit({
      _id: kit._id,
      name,
    }).then(() => {
      toast.success(`Комплект изменен!`);
    });
  };

  const deleteHandler = () => {
    if (!window.confirm("Удалить комплект?")) return;

    WalletService.deleteKit(kit._id).then((r) => {
      deleteItemHandler(kit._id);
      toast.success(`Комплект удален!`);
    });
  };

  const openChangeBalance = (action, _id) => {
    setShowChangeBalance({
      show: true,
      action,
      _id,
    });
  };
  const closeChangeBalance = () => {
    setShowChangeBalance(Object.assign({}, showChangeBalance, { show: false }));
  };


  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const btns = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
          sx: "row-reverse",
          md: "row",
        },
        alignItems: "center",
      }}
    >
      <IconButton sx={{ mt: 2 }} onClick={() => setShowMore(!showMore)}>
        <Icon icon="material-symbols:expand-more-rounded" />
      </IconButton>

      {kit.balance <= 0 && (
        <IconButton
          onClick={deleteHandler}
          sx={{
            ml: 2,
            borderRadius: 1,
          }}
          color="error"
        >
          <Icon icon="ic:baseline-delete-forever" />
        </IconButton>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        ...style,
      }}
    >
      <DialogChangeBalance
        action={showChangeBalance.action}
        show={showChangeBalance.show}
        closeHandler={closeChangeBalance}
        isKit
        _id={showChangeBalance._id}
      />
      <Card>
        <CardContent>
          <Grid spacing={4} container>
            <Grid item xs={12} md={5}>
              <Box sx={{ display: "flex" }}>
                <ActiveSwitch
                  isKit
                  number={kit.number}
                  enabled={kit.enabled}
                  onChange={changeEnabledHadnler}
                  style={{ mr: 3 }}
                />
                <CustomTextField
                  label="Название"
                  placeholder="Введите название"
                  isChanged={name.trim() !== kit.name.trim()}
                  saveHandler={saveNameHandler}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              {isDesktop && btns}
            </Grid>
            <Grid item xs={12} md={2}>
              <LoyaltySelect
                initValue={kit.loyalty}
                value={kit.loyalty}
                setValue={changeLoyaltyHadnler}
                style={{ mb: 3 }}
              />
              <Button
                fullWidth
                onClick={() =>
                  openTransferSum({ id: kit._id, isKit: true }, currency)
                }
                startIcon={<Icon icon="fa6-solid:money-bill-transfer" />}
              >
                Перевод
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => openChangeBalance("add", kit._id, true)}
                startIcon={<Icon icon="bi:box-arrow-in-down" />}
              >
                Внести
              </Button>
              <Button
                sx={{ mt: 3 }}
                fullWidth
                variant="outlined"
                onClick={() => openChangeBalance("sub", kit._id, true)}
                startIcon={<Icon icon="bi:box-arrow-in-up" />}
              >
                Вывести
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <BalanceInfo
                balance={`${kit.balance}`}
                isGeneral={false}
                currency={currency}
                disabledBalance={kit.disabledBalance}
              />
            </Grid>
          </Grid>
          {!isDesktop && btns}
        </CardContent>
      </Card>
      <Collapse in={showMore} timeout="auto" unmountOnExit>
        <CryptoRequisites kit={kit} />
      </Collapse>
    </Box>
  );
}

function CryptoRequisites(props) {
  let { kit, currency } = props;

  const [loaded, setLoaded] = useState(false);
  const [requisites, setRequisites] = useState([]);

  //Hooks
  useEffect(() => {
    socket.on("update_item_req", (r) => {
      setRequisites((list) =>
        list.map((req) => (req._id == r._id ? Object.assign({}, req, r) : req))
      );
    });
    WalletService.getRequisiteList(kit._id).then((r) => {
      setRequisites(r.data);
      setLoaded(true);
    });

    return () => {
      socket.off(`update_item_req`);
    };
  }, []);

  // Requisites
  const addRequisiteHandler = () => {
    WalletService.createRequisite({
      kit: kit._id,
    }).then((r) => {
      setRequisites((list) => [r.data, ...list]);
      toast.success(`Реквизит добавлен!`);
    });
  };

  const deleteRequisiteHandler = (id) => {
    if(!window.confirm('Удалить реквезит?')) return;
    WalletService.deleteRequisite(id).then((r) => {
      setRequisites((list) => list.filter((r) => r._id !== id));
      toast.success(`Реквизит удален!`);
    });
  };

  if (!loaded)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 3,
        }}
      >
        <CircularProgress />
      </Box>
    );

  let requisitesSorted = requisites.sort((a, b) => {
    return a.number - b.number;
  });

  // fetch requisites

  return (
    <Box sx={{ ml: 4, mt: 4 }}>
      <RequisitesWrap
        requisites={requisitesSorted}
        addHandler={addRequisiteHandler}
        RequisiteComponent={CryptoRequisite}
        RequisiteComponentProps={{
          deleteHandler: deleteRequisiteHandler,
          currency: currency,
        }}
      />
    </Box>
  );
}
