import React, { useEffect, useState } from "react";
// ** MUI Imports
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { CircularProgress, Divider } from "@mui/material";

import { styled } from "@mui/material/styles";

//Router
import { useSearchParams } from "react-router-dom";

// ** MUI Imports
// import Box from '@mui/material/Box'
import Card from "@mui/material/Card";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import CardHeader from "@mui/material/CardHeader";
// import Typography from '@mui/material/Typography'
import CardContent from "@mui/material/CardContent";
import StepContent from "@mui/material/StepContent";

// ** Third Party Imports
import clsx from "clsx";
import toast from "react-hot-toast";

// ** Custom Components Imports
// import StepperCustomDot from "./StepperCustomDot";
// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

// ** Styled Components
import StepperWrapper from "src/@core/styles/mui/stepper";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import OrderInfo from "./OrderInfo";

import { socket } from "src/socket";

// Modules
// import ConfirmSendingStep from "./Steps/ConfirmSendingStep";
// import ConfirmComingStep from "./Steps/ConfirmComingStep";

// Streps Modules
import StepIncoming from "./StepIncoming";
import StepSending from "./StepSending";
import StepFinish from "./StepFinish";
import CardStep from "./CardStep";
import OrderDebt from "./OrderDebt";

import StepTitle from "./Modules/StepTitle";

// Services
import OrderService from "src/service/Order";

function OrderDrawer() {
  // Hooks Router
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  // States
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);

  // Hooks
  useEffect(() => {
    if (!orderId) return;
    setLoading(true);
    OrderService.getOne(orderId).then((r) => {
      setOrder(r.data);
      setLoading(false);
    });
  }, [orderId]);

  // Handler
  const handleClose = () => {
    searchParams.delete("orderId");
    setSearchParams(searchParams);
  };

  let hasData = !loading && order;

  return (
    <Drawer
      open={!!orderId}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: false }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 600 } } }}
    >
      {hasData ? (
        <DrawerContent data={order} handleClose={handleClose} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Drawer>
  );
}

function DrawerContent(props) {
  let { data, handleClose } = props;
  let [order, setOrder] = useState(data);

  let orderId = order._id;

  useEffect(() => {
    socket.on(`order_upd_${orderId}`, (upd) => {
      setOrder((o) => Object.assign({}, o, upd));
    });
    return () => {
      socket.off(`order_upd_${orderId}`);
    };
  }, []);

  // Calc
  let hasCard = !!order.card;
  let isResolved = order.status == 105;
  let isRejected = order.status == 106;
  let orderDebt = order.orderDebt;

  // Components

  let cardStep = (
    <React.Fragment>
      <StepTitle number={1} style={{ mb: 4 }} text="Верифицируйте карту" />
      <CardStep style={{ mb: 4 }} card={order.card} />
    </React.Fragment>
  );

  let takeCurName = order.currency.take.name;
  let giveCurName = order.currency.give.name;

  return (
    <Box>
      <Box
        sx={{
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Box>
      <OrderInfo order={order} style={{ pt: 0 }} />
      <Divider />
      <CardContent>
        {!!orderDebt && (
          <>
            <Typography color='red' sx={{mb: 2}} variant="body1">
              Обнаружена задолженность
            </Typography>
            <OrderDebt orderId={order._id} data={orderDebt}/>
          </>
        )}
        {hasCard && cardStep}
        <StepTitle
          number={hasCard ? 2 : 1}
          style={{ mb: 4 }}
          disabledComponent={order.status < 102}
          text={`Подтвердите поступление средств на ${takeCurName}`}
        />
        <StepIncoming disabledComponent={order.status < 102} order={order} />

        <StepTitle
          number={hasCard ? 3 : 2}
          style={{ mt: 4 }}
          disabledComponent={order.status < 104}
          text={`Подтвердите отправление средств на ${giveCurName}`}
        />

        <StepSending
          order={order}
          disabledComponent={order.status < 104}
          style={{ mt: 4 }}
        />

        {(isResolved || isRejected) && (
          <StepTitle
            number={hasCard ? 4 : 3}
            style={{ mt: 4 }}
            disabledComponent={order.status < 104}
            text={`Комментарий системы`}
          />
        )}

        {(isResolved || isRejected) && (
          <StepFinish order={order} style={{ mt: 4 }} />
        )}
      </CardContent>
    </Box>
  );
}

export default OrderDrawer;
