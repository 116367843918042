// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Validate
import InputMask from "react-input-mask";

// route
import { useNavigate } from "react-router-dom";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Third Party Imports
import { useForm, Controller } from "react-hook-form";

// Services
import AdminService from "src/service/Auth";
import RolePermissionService from "src/service/RolePermission";

// TOast
import toast from "react-hot-toast";

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3, 4),
  justifyContent: "space-between",
  backgroundColor: theme.palette.background.default,
}));

const defaultValues = {
  email: "",
  name: "",
  password: "",
  phone: "",
};

const roleList = [
  {
    name: "Администратор",
    value: "administrator",
  },
  {
    name: "Оператор",
    value: "operator",
  },
];

const AddOperator = (props) => {
  // ** Props
  const { open, toggle, addItem } = props;

  const navigate = useNavigate();

  // ** State
  const [isSubmiting, setSubmiting] = useState(false);
  const [role, setRole] = useState(roleList[0].value);
  const [permissionList, setPermissionList] = useState([]);
  const [permission, setPermission] = useState("");

  // Hooks
  useEffect(() => {
    if (!open) return;

    RolePermissionService.getAll().then((r) => {
      let list = r.data.map((r) => {
        return {
          name: r.name,
          value: r._id,
        };
      });
      setPermissionList(list);
      setPermission(list[0].value);
    });
  }, [open]);

  const {
    reset,
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const handleClose = () => {
    toggle();
    reset();
  };

  const onSubmit = handleSubmit((data) => {
    let fetchObj = Object.assign({}, data, {
      phone: data.phone.replace(/\D/g, ""),
      role,
      permission: role == "operator" ? permission : undefined,
    });

    AdminService.createAdmin(fetchObj)
      .then((r) => {
        let result = r.data;
        addItem(result);
        toast.success("Оператор успешно создан!");
        handleClose();
        navigate(`/settings/operators/${result._id}`);
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Drawer
      open={open}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: true }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: 300, sm: 400 } } }}
    >
      <Header>
        <Typography variant="h6">Добавить оператора</Typography>
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Header>
      <Box sx={{ p: 5 }}>
        <form noValidate onSubmit={onSubmit}>
          <FormControl sx={{ mb: 4 }} fullWidth>
            <InputLabel
            >
              Должность
            </InputLabel>
            <Select
              value={role}
              label="Должность"
              onChange={(e) => setRole(e.target.value)}
            >
              {roleList.map((r) => {
                return <MenuItem value={r.value}>{r.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          {role == "operator" && (
            <FormControl sx={{ mb: 4 }} fullWidth>
              <InputLabel
              //  id="demo-simple-select-label"
              >
                Роль
              </InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                value={permission}
                label="Роль"
                onChange={(e) => setPermission(e.target.value)}
              >
                {permissionList.map((r) => {
                  return <MenuItem value={r.value}>{r.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          )}
          <Controller
            name={"email"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                autoFocus
                fullWidth
                placeholder="test@example.com"
                id="email"
                type="email"
                label="Email"
                sx={{ mb: 4 }}
                error={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "Введите Email!",
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Некорректный Email!",
                  },
                })}
                helperText={errors.email?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"name"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                placeholder="Ivan Ivanov"
                fullWidth
                id="name"
                type="text"
                label="Имя"
                sx={{ mb: 4 }}
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Введите Имя!",
                })}
                helperText={errors.name?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"phone"}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <InputMask
                  error={Boolean(errors.phone?.message)}
                  helperText={errors.phone?.message}
                  {...register("phone", {
                    required: "Номер телефона не может быть пустым",
                    validate: (val) => {
                      let ph = val.replace(/\D/g, "");
                      let rval =
                        ph.length >= 11 || "Некорректный номер телефона";
                      return rval;
                    },
                  })}
                  sx={{ mb: 4 }}
                  fullWidth
                  label="Номер телефона"
                  mask="+9 (999) 999-99-99"
                  value={value}
                  onChange={onChange}
                  name="phone"
                  type="text"
                >
                  {(e) => <TextField {...e} />}
                </InputMask>
              );
            }}
          />
          <Controller
            name={"password"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                placeholder="password"
                fullWidth
                id="password"
                type="text"
                label="Пароль"
                sx={{ mb: 4 }}
                error={Boolean(errors.password?.message)}
                {...register("password", {
                  required: "Введите Пароль!",
                  minLength: {
                    message: "Мин. длина: 6 символов",
                    value: 6,
                  },
                  maxLength: 100,
                })}
                helperText={errors.password?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
              size="large"
              fullWidth
              type="submit"
              variant="contained"
            >
              Добавить
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default AddOperator;
