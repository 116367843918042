import { useEffect, useState } from "react";
// Modueles
import PairInfo from "./modules/PairInfo";
import Change from "./modules/Change";

// Mui
import {
  Card,
  CardContent,
  Grid,
  Divider,
  TextField,
  IconButton,
  Box,
  Button,
} from "@mui/material";

// ** Icon
import Icon from "src/@core/components/icon";

// Socket
import { socket } from "src/socket";

//Toast
import { toast } from "react-hot-toast";

// Services
import ExchangeService from "src/service/Exchange";

function TargetCard(props) {
  let { style = {}, data } = props;

  let [exchange, setExchange] = useState(data);

  // Calc
  let takeMainCur = exchange.tk.cur;
  let giveMainCur = exchange.gv.cur;

  // Hooks
  useEffect(() => {
    socket.on(`exchange_upd_${exchange._id}`, (e) => {
      setExchange(e);
    });
    socket.on(`exchange_upd_part_${exchange._id}`, (upd) => {
      setExchange((e) => {
        return Object.assign({}, e, upd);
      });
    });
    //
    return () => {
      socket.off(`exchange_upd_part_${exchange._id}`);
      socket.off(`exchange_upd_${exchange._id}`);
    };
  }, []);

  // Handlers
  const changeExchangeHandler = (upd) => {
    ExchangeService.changeExchange({
      _id: exchange._id,
      ...upd,
    }).then(() => {
      toast.success("Обменная пара изменена!");
    });
  };

  return (
    <Card sx={{ ...style }}>
      {/* <CardContent> */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={3.9}>
          <CardContent>
            <PairInfo
              exchange={exchange}
              takeMainCur={takeMainCur}
              giveMainCur={giveMainCur}
            />
          </CardContent>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} md={4}>
          <CardContent>
            <Change
              exchange={exchange}
              changeExchangeHandler={changeExchangeHandler}
            />
          </CardContent>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} md={4}>
          <CardContent>
            <ExchangeTarget exchangeId={exchange._id} />
          </CardContent>
        </Grid>
      </Grid>
      {/* </CardContent> */}
      {/* {showTarget && <Divider sx={{ my: "0 !important" }} />}
      <Collapse in={showTarget} timeout="auto" unmountOnExit>
        <ExchangeTarget deleteItem={deleteItem} exchangeId={exchange._id} />
      </Collapse> */}
    </Card>
  );
}

function ExchangeTarget({ exchangeId, deleteItem }) {
  let [data, setData] = useState({
    target: 0,
    minPercent: 0,
    step: 0,
    enabled: false,
  });

  let [target, setTarget] = useState(0);
  let [minPercent, setMinPercent] = useState(0);
  let [step, setStep] = useState(0);
  // Hooks

  useEffect(() => {
    ExchangeService.getExchangeTarget(exchangeId).then((r) => {
      if (!r.data) return;
      setData(r.data);
      setTarget(r.data.target);
      setMinPercent(r.data.minPercent);
      setStep(r.data.step);
    });
  }, []);

  // Calc
  let isChanged =
    +data.target !== +target ||
    +data.minPercent !== +minPercent ||
    +data.step !== +step;

  let enabled = data.enabled;

  // Handlers
  const saveHandler = () => {
    ExchangeService.changeExchangeTarget(exchangeId, {
      target: +target,
      minPercent,
      step,
    }).then((r) => {
      setData(r.data);
      setTarget(r.data.target);
      setMinPercent(r.data.minPercent);
      setStep(r.data.step);
      toast.success("Данные сохраненны!");
    });
  };

  const deleteHandler = () => {
    if (!window.confirm("Удалить таргет?")) return;
    ExchangeService.deleteExchangeTarget(exchangeId).then((r) => {
      toast.success("Таргет удален!");
      deleteItem(exchangeId);
    });
    //
  };

  const toggleEnabled = (enabled) => {
    ExchangeService.changeExchangeTarget(exchangeId, {
      enabled,
    }).then((r) => {
      setData(Object.assign({}, data, { enabled }));
      toast.success(`Таргет ${enabled ? "включен" : "выключен"}!`);
    });
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4.5}>
              <TextField
                size="small"
                fullWidth
                value={step}
                label="Шаг"
                onChange={(e) => setStep(e.target.value)}
              />
            </Grid>
            <Grid item xs={4.5}>
              <TextField
                size="small"
                fullWidth
                value={minPercent}
                label="Порог"
                onChange={(e) => setMinPercent(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                color={"error"}
                variant="outlined"
                onClick={deleteHandler}
                sx={{
                  borderRadius: 1,
                  mr: 4,
                  color: "error",
                }}
                fullWidth
              >
                <Icon icon={"mdi:trash"} />
              </IconButton>
              <IconButton
                color={enabled ? "primary" : "secondary"}
                variant="contained"
                onClick={() => toggleEnabled(!enabled)}
                sx={{
                  borderRadius: 1,
                  backgroundColor: (theme) => theme.palette.background.default,
                  color: enabled ? "primary.dark" : "secondary.light",
                }}
                fullWidth
              >
                <Icon
                  icon={
                    enabled
                      ? "pepicons-pop:power-circle-filled"
                      : "pepicons-pop:power-circle-off"
                  }
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {isChanged && (
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              sx={{ p: 1.5, minWidth: 38 }}
              color="primary"
              onClick={saveHandler}
            >
              <Icon icon="material-symbols:save-outline-rounded" />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TargetCard;
