import { useEffect, useState } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Step";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import SumTitle from "./Modules/SumTitle";

// Router
import { Link } from "react-router-dom";

// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

// Services
import OrderService from "src/service/Order";

// Socket
import { socket } from "src/socket";
import { toast } from "react-hot-toast";

function OrderDebt({ data, orderId }) {
  // const [isSubmit, setIsSubmit] = useState("");
  const [debt, setDebt] = useState(data);

  // Hooks
  useEffect(() => {
    socket.on("update_debt_order", (upd) => {
      console.log({ upd });
      setDebt((d) => (d._id == upd._id ? upd : d));
    });
    socket.off("update_debt_order");
  }, [orderId]);

  let debtOrderId = debt.debt.order;
  let field = debt.field;

  // const changeHandler = (action) => {
  //   setIsSubmit(action);
  //   OrderService.changeDebt({
  //     action,
  //     orderId,
  //   })
  //     .then((r) => {
  //       toast.success(
  //         `Взымание задолженности ${
  //           action == "payed" ? "выполнено" : "отклонено"
  //         }!`
  //       );
  //       setDebt(r.data);
  //     })
  //     .finally(() => {
  //       setIsSubmit("");
  //     });
  // };

  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, mb: 2 }}>
      <CardContent sx={{ py: "12px !important", px: 0 }}>
        <Typography sx={{ mb: 3, px: 3 }} variant="body1">
          Обнаружен долг по полю{" "}
          <Typography component="span" variant="body1" color="error">
            {field}
          </Typography>
          . Заявка{" "}
          <Typography
            to={`?orderId=${debtOrderId}`}
            component={Link}
            variant="body1"
            color="primary"
          >
            {debtOrderId}
          </Typography>
        </Typography>
        <Divider />
        <Box
          sx={{
            px: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SumTitle text={"Сумма"} sum={debt.sum} code={debt.currency.code} />
          <CustomChip
            skin="light"
            label={debt.payed ? "Оплачено" : "Не оплачено"}
            color={debt.pyed ? "success" : "error"}
            sx={{
              width: "min-content",
              fontSize: "1rem",
              fontWeight: 600,
              borderRadius: "5px",
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}


export default OrderDebt;
