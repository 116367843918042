// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Icon
import Icon from "src/@core/components/icon";

function Action(props) {
  let { action, history = "" } = props;
  // Calc
  let color = action == "add" ? "success.main" : "error.main";
  let transformIcon = action == "add" ? "rotate(180deg)" : undefined;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body2" color={color}>
        {actionText(history)[action]}
      </Typography>
      <Box
        component="span"
        sx={{
          ml: 2,
          "& svg": {
            transform: transformIcon,
            color: color,
          },
          lineHeight: 0,
        }}
      >
        <Icon icon="mdi:arrow-top-bold-box-outline" />
      </Box>
    </Box>
  );
}

function actionText(history) {
  switch (history) {
    case "operations":
      return {
        add: "Ввод",
        sub: "Вывод",
      };
    case "orders":
      return {
        add: "Приход",
        sub: "Расход",
      };
  }
}

export default Action;
