import { useEffect, useState } from "react";
// Mui
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// Service
import OrderService from "src/service/Order";
import { toast } from "react-hot-toast";

function ChangeIncomeSum(props) {
  let { oldSum, handleClose, orderId } = props;
  let [sum, setSum] = useState(oldSum);
  let [isSubmit, setIsSubmit] = useState(false);

  let isChanged = +sum !== oldSum;

  const saveHandler = () => {
    setIsSubmit(true);

    OrderService.changeIncomeSum(orderId, sum)
      .then(() => {
        toast.success("Сумма поступления измененна!");
        handleClose();
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <CardContent sx={{ p: 3 }}>
      <TextField
        type="number"
        value={sum}
        onChange={(e) => setSum(e.target.value)}
        size="small"
        fullWidth
        label="Новая сумма"
      />
      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={6}>
          <Button
            fullWidth
            sx={{ fontSize: "0.8rem" }}
            variant="contained"
            color="primary"
            disabled={!isChanged || isSubmit}
            onClick={saveHandler}
            startIcon={isSubmit ? <CircularProgress /> : null}
          >
            Сохранить
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            sx={{ fontSize: "0.8rem" }}
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            Отменить
          </Button>
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default ChangeIncomeSum;
