// ** Store
import ServiceDataStore from "src/store/ServiceData";
import ServiceSettingsStore from "src/store/ServiceSettings";
// ** Utils
import calcWithPrecision from "./calcWithPrecision";
// Libs
import { FormatMoney } from "format-money-js";

const fm = new FormatMoney({
  separator: " ",
  leadZeros: true,
  append: true,
});

function getPriceCurrentFiat(price, precision = 8) {
  let cur = ServiceSettingsStore.getSettlementСurrency;
  let { USD } = ServiceDataStore.getFiatPrices;

  if (cur == "USD") {
    return fm.from(price / USD, { symbol: "USD", decimals: precision });
  } else {
    return fm.from(price, { symbol: "RUB", decimals: precision });
  }
}

export default getPriceCurrentFiat;
