import { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Switch from "@mui/material/Switch";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// import Slide, { SlideProps } from '@mui/material/Slide'
import DialogContentText from "@mui/material/DialogContentText";
// Form

import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import toast from "react-hot-toast";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// Custom Component
import BlockLoader from "src/components/BlockLoader";

// Socket
import { socket } from "src/socket";

import ExchangeApiSettingService from "src/service/ExchangeApiSetting";

const AddConnectedExchangeDialog = ({ open, handleClose }) => {
  let [exchangeList, setExchangeList] = useState([]);
  let [selected, setSelected] = useState();
  let [isSubmiting, setSubmiting] = useState(false);
  let [error, setError] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();

    setSubmiting(true);

    let data = {
      name: exchangeList.find((e) => e.code === selected).name,
      code: selected,
    };

    ExchangeApiSettingService.addExchange(data)
      .then((r) => {
        toast.success(`Биржа добавлена!`);
        handleClose();
      })
      .finally(() => setSubmiting(false));
  }

  useEffect(() => {
    ExchangeApiSettingService.getListExchanges().then((r) => {
      setExchangeList(r.data);
    });
  }, []);

  const codes = exchangeList.map((e) => e.code);

  return (
    <>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">Добавить биржу</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 4 }}>
            Добавить можно только униакальную биржу, которой нет в списке.
          </DialogContentText>
          <Collapse sx={{ mb: 2 }} in={error}>
            <Alert
              severity="error"
              action={
                <IconButton
                  size="small"
                  color="inherit"
                  aria-label="close"
                  onClick={() => setError(null)}
                >
                  <Icon icon="mdi:close" fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
          <form onSubmit={handleSubmit}>
            <Autocomplete
              defaultValue={codes[0]}
              options={codes}
              getOptionLabel={(option) => {
                let item = exchangeList.find((c) => option === c.code);
                return `${item.name} (${item.code})`;
              }}
              onChange={(e, value) => {
                setSelected(value);
              }}
              renderInput={(params) => <TextField {...params} label="Биржи" />}
            />

            <Box
              className="demo-space-x"
              sx={{ "& > :last-child": { mr: "0 !important" } }}
            >
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
                size="large"
                type="submit"
                variant="contained"
              >
                Добавить
              </Button>
              <Button
                type="reset"
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ConnectedExchanges = () => {
  const [showDialog, setShowDialog] = useState(false);
  let [list, setList] = useState([]);

  useEffect(() => {
    ExchangeApiSettingService.getExchanges().then((r) => setList(r.data));

    socket.on("exc_api_settings_add", (e) => {
      setList((list) => [e, ...list]);
    });
    socket.on("exc_api_settings_delete", (id) => {
      setList((list) => list.filter((i) => i._id !== id));
    });

    return () => {
      socket.off("exc_api_settings_add");
      socket.off("exc_api_settings_delete");
    };
  }, []);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleToggleExchange = (id, val) => {
    ExchangeApiSettingService.toggleExchange({ id, enabled: val }).then(() => {
      toast.success(`Биржа ${val ? "включена" : "отключена"}!`);
      setList((list) =>
        list.map((i) => (i._id === id ? { ...i, enabled: val } : i))
      );
    });
  };

  const handleDeleteExchange = (id) => {
    ExchangeApiSettingService.deleteExchange(id).then(() => {
      toast.success(`Биржа удалена!`);
    });
  };

  return (
    <Card>
      <AddConnectedExchangeDialog
        open={showDialog}
        handleClose={toggleDialog}
      />
      <CardHeader
        title={"Подключенные биржи"}
        {...{
          action: (
            <Fab
              color="primary"
              variant="extended"
              size="medium"
              sx={{ "& svg": { mr: 1 } }}
              onClick={toggleDialog}
            >
              <Icon icon="mdi:plus" />
              Добавить
            </Fab>
          ),
        }}
      />
      <CardContent>
        <List>
          {list.map((e) => {
            return (
              <ListItem>
                <ListItemText primary={e.name} secondary={e.code} />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={e.enabled}
                    onChange={(s) =>
                      handleToggleExchange(e._id, s.target.checked)
                    }
                  />
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteExchange(e._id)}
                  >
                    <Icon icon="ic:round-delete-forever" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default ConnectedExchanges;
