import { useEffect, useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemButton from "@mui/material/ListItemButton";

// Time
import moment from "moment";

// socket
import { socket } from "src/socket";

// routes
import { Link } from "react-router-dom";

// Custom componenents
import CustomChip from "src/@core/components/mui/chip";

// Congi
import ConfigApi from "src/configs/api";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";
// ** Store
import { observer } from "mobx-react-lite";

function CurrenciesList({ currencies, withTransition }) {
  return (
    <Box>
      <List
        dense
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {currencies.map((c, i) => (
          <CurrencyCard withTransition={withTransition} key={c._id} d={c} />
        ))}
      </List>
    </Box>
  );
}

const CurrencyCard = observer(function ({ d, withTransition }) {
  let [data, setData] = useState(d);

  useEffect(() => {
    socket.on(`currency_${data._id}_part_upd`, (d) => {
      setData(Object.assign({}, data, d));
    });
    return () => {
      socket.off(`currency_${data._id}_part_upd`);
    };
  }, []);

  // Calc
  const ListItemWrap = withTransition
    ? function (props) {
        return (
          <ListItemButton
            component={Link}
            to={`/currency/${data._id}`}
            {...props}
          >
            {props.children}
          </ListItemButton>
        );
      }
    : ListItem;

  return (
    <ListItemWrap>
      <ListItemAvatar>
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + data.image}
          alt={data.name}
          sx={{ height: 36, width: 36 }}
        />
      </ListItemAvatar>
      <ListItemText primary={data.name} secondary={data.code} />
      <ListItemSecondaryAction>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <CustomChip
            skin="light"
            size="small"
            color="primary"
            label={getPriceCurrentFiat(data.value, data.precision)}
            sx={{ height: 20, fontSize: "0.75rem", fontWeight: 500, mb: 1 }}
          />
          <Typography variant="caption">
            {moment(data.updatedAt).format("DD.MM.YYYY, HH:mm:ss")}
          </Typography>
        </Box>
      </ListItemSecondaryAction>
    </ListItemWrap>
  );
});

export default CurrenciesList;
