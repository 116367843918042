import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

function SelectSite({ site, setSite, websites_options }) {
  return (
    <TabContext value={site}>
      <TabList
        variant="scrollable"
        scrollButtons="auto"
        onChange={(e, v) => setSite(v)}
      >
        {websites_options.map((w) => (
          <Tab value={w.value} label={w.name} />
        ))}
      </TabList>
    </TabContext>
  );
}

export default SelectSite;
