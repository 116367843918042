import { useState, useEffect } from "react";
// Custom Components
import PageWrap from "src/components/PageWrap";

// Service
import DebtService from "src/service/Debt";

// Route
import { useNavigate } from "react-router-dom";

// Mui
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

// time
import moment from "moment";
// Socket
import { socket } from "src/socket";

function Arrears() {
  let [list, setList] = useState([]);
  let [loading, setLoading] = useState(false);

  // Router
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    DebtService.getAll().then((r) => {
      setList(r.data);
      setLoading(false);
    });

    socket.on("update_debt", (upd) => {
      setList((list) => list.map((d) => (d._id == upd._id ? upd : d)));
    });

    return () => {
      socket.off("update_debt");
    };
  }, []);

  let listWithId = list.map((c) => Object.assign({}, c, { id: c._id }));

  return (
    <PageWrap title="Долги">
      <Card>
        <DataGrid
          selected={false}
          columns={columns}
          autoHeight
          loading={loading}
          rows={listWithId}
          sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          onCellClick={(r) => {
            navigate(`?debt=${r.id}`);
          }}
        />
      </Card>
    </PageWrap>
  );
}

const columns = [
  {
    flex: 0.1,
    minWidth: 100,
    field: "name",
    headerName: "Название",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {row.name}
        </Typography>
      );
    },
  },
  {
    flex: 0.1,
    minWidth: 100,
    field: "order",
    headerName: "Заявка",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {row.order}
        </Typography>
      );
    },
  },
  {
    // flex: 0.1,
    // minWidth: 100,
    field: "sum",
    headerName: "Сумма",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {`${row.sum} ${row.currency.code}`}
        </Typography>
      );
    },
  },
  {
    flex: 0.08,
    minWidth: 80,
    field: "email",
    headerName: "Email",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="body1" noWrap>
          {row.email}
        </Typography>
      );
    },
  },
  {
    flex: 0.05,
    minWidth: 50,
    field: "ip",
    headerName: "IP",
    renderCell: ({ row }) => {
      return (
        <Typography variant="body2" noWrap>
          {row.ip}
        </Typography>
      );
    },
  },

  {
    flex: 0.15,
    minWidth: 50,
    field: "fields",
    headerName: "Поля",
    renderCell: ({ row }) => {
      return (
        <Typography variant="body2" noWrap>
          {row.fields.map((f) => f.value).join(",")}
        </Typography>
      );
    },
  },
  {
    // flex: 0.04,
    // minWidth: 100,
    field: "payed",
    headerName: "Статус",
    renderCell: ({ row }) => {
      return (
        <Typography textAlign="center" variant="body2" noWrap>
          {row.payed ? "✅" : "❌"}
        </Typography>
      );
    },
  },
  {
    flex: 0.1,
    minWidth: 100,
    field: "createdAt",
    headerName: "Создано",
    renderCell: ({ row }) => {
      return (
        <Typography variant="body2" noWrap>
          {moment(row.createdAt).format("DD.MM.YYYY, в HH:mm")}
        </Typography>
      );
    },
  },
];

export default Arrears;
