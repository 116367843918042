import axios from "axios";

class BlackList {
  getAll() {
    return axios.get(`/black-list`);
  }
  add(data) {
    return axios.post(`/black-list`, data);
  }
  delete(id) {
    return axios.delete(`/black-list/${id}`);
  }
}

export default new BlackList();
