import React, { useState } from "react";
// Mui
import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  CardActions,
  Button,
  CircularProgress,
} from "@mui/material";

// Custom componenents
import CustomChip from "src/@core/components/mui/chip";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";

// Modules
import ChangeModal from "./ChangeModal";


function Details(props) {
  let { data, updateHandler } = props;
  // States
  const [spinner, setSpinner] = useState("");
  const [showModalChange, setShowModalChange] = useState(false);

  // Hooks
  const auth = useAuth();

  // Calc
  let isOnline = data.online;
  let isAdmin = data.role === "administrator";

  let roleName = "Администратор";
  if (!isAdmin) {
    let permissionName = data?.permission?.name;
    roleName = permissionName || "Оператор";
  }

  let isActive = !data.deleted;
  let isAdminView = auth.isAdmin;

  // Components
  let editComponent = (
    <CardActions sx={{ display: "flex", justifyContent: "center" }}>
      {!isActive ? (
        <Button
          variant="contained"
          sx={{ mr: 2 }}
          //   onClick={restoreClientHandler}
          startIcon={
            spinner == "restore" ? <CircularProgress size="1rem" /> : null
          }
          disabled={spinner == "restore"}
        >
          Восстановить
        </Button>
      ) : (
        <React.Fragment>
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => setShowModalChange(true)}
          >
            Изменить
          </Button>
          <Button
            color="error"
            variant="outlined"
            // onClick={deleteClientHandler}
            startIcon={
              spinner == "delete" ? <CircularProgress size="1rem" /> : null
            }
            disabled={spinner == "delete"}
          >
            Удалить
          </Button>
        </React.Fragment>
      )}
    </CardActions>
  );

  return (
    <Box>
      <ChangeModal
      updateHandler={updateHandler}
        open={showModalChange}
        handleClose={() => setShowModalChange(false)}
        data={data}
      />
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{data.email}</Typography>
            <CustomChip
              sx={{ p: 0.5, ml: 2 }}
              label={isOnline ? "Online" : "Offline"}
              skin="light"
              color={isOnline ? "success" : "error"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 3,
            }}
          >
            <CustomChip
              // sx={{ p: 0.5, ml: 2 }}
              size="small"
              label={isActive ? "Активный" : "Удален"}
              skin="light"
              color={isActive ? "success" : "error"}
            />
            <CustomChip
              // sx={{ p: 0.5, ml: 2 }}
              size="small"
              label={roleName}
              skin="light"
              color={isAdmin ? "primary" : "warning"}
            />
          </Box>
          <Typography sx={{ mt: 6, mb: 2 }} variant="body1">
            Детали
          </Typography>
          <Divider />
          <Box sx={{ display: "flex", mb: 2.7 }}>
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              Имя:
            </Typography>
            <Typography variant="body2">{data.name}</Typography>
          </Box>
          <Box sx={{ display: "flex", mb: 2.7 }}>
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              Телефон:
            </Typography>
            <Typography variant="body2">{`+${data.phone}`}</Typography>
          </Box>
        </CardContent>
        {isAdminView && editComponent}
      </Card>
    </Box>
  );
}

export default Details;
