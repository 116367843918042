import { makeObservable, observable, action, computed } from "mobx";

class ServiceData {
  fiatPrices = {};
  constructor() {
    makeObservable(this, {
      fiatPrices: observable,
      setFiatPrices: action,
      getFiatPrices: computed,
    });
  }
  setFiatPrices(fiatPrices) {
    this.fiatPrices = fiatPrices;
  }
  get getFiatPrices() {
    return this.fiatPrices;
  }
}

const serviceDataStore = new ServiceData();

export default serviceDataStore;
