// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

function CardChip(props) {
  let { status, size = "medium", style = {} } = props;

  return (
    <CustomChip
      skin="light"
      size={size}
      label={getStatusData(status).name}
      color={getStatusData(status).color}
      sx={{
        width: "min-content",
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: "5px",
        ...style
      }}
    />
  );
}

function getStatusData(code) {
  switch (code) {
    case 101:
      return {
        color: "warning",
        name: "Новая",
      };
    case 102:
      return {
        color: "success",
        name: "Одобрена",
      };
    case 103:
      return {
        color: "error",
        name: "Отклонена",
      };
    case 104:
      return {
        color: "secondary",
        name: "Заблокирована",
      };
  }
}

export default CardChip;
