import { useEffect, useState } from "react";

// Mui
import { Box, Tab } from "@mui/material";
import { TabList, TabContext } from "@mui/lab";

function Filter(props) {
  let { site, setSite, status, setStatus, WEBSITES, STATUSES } = props;

  return (
    <Box>
      <TabContext value={site}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => setSite(v)}
        >
          {WEBSITES.map((w) => (
            <Tab value={w.value} label={w.name} />
          ))}
        </TabList>
      </TabContext>
      <TabContext value={status}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => setStatus(v)}
        >
          {STATUSES.map((w) => (
            <Tab value={w.value} label={w.name} />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
}

export default Filter;
