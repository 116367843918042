import { useState, useEffect } from "react";
// Mui
import { Box, Grid, CircularProgress } from "@mui/material";

// Modules
import Filter from "./Filter";
import Control from "./Control";

// Global
import OrderCard from "src/modules/Orders/OrderCard/OrderCard";

// Modules
import WalletQuickView from "./WalletQuickView";
import AddOrderDialog from "src/modules/Orders/AddOrderDialog/AddOrderDialog";

// Services
import OrderService from "src/service/Order";

// LIBS
import InfiniteScroll from "react-infinite-scroll-component";

// Configs
import WEBSITES from "src/configs/websites";

// Socker

import { socket } from "src/socket";

const LIMIT = 24;

// CONSTS
const WEBSITES_LIST = [
  {
    value: "all",
    name: "Все",
  },
  ...WEBSITES.map((w) => ({
    name: w.name,
    value: w.id,
  })),
];

const ORDER_STATUS_LIST = [
  {
    value: "all",
    name: "Все",
  },
  {
    value: "new",
    name: "Новые",
  },
  {
    value: "completed",
    name: "Выполненные",
  },
  {
    value: "rejected",
    name: "Отклоненные",
  },
  {
    value: "check",
    name: "На проверке",
  },

  {
    value: "archive",
    name: "Архив",
  },
];

function Orders() {
  const [site, setSite] = useState(WEBSITES_LIST[0].value);
  const [showAddOrder, setShowAddOrder] = useState(false);
  const [status, setStatus] = useState(ORDER_STATUS_LIST[0].value);
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState('');
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    socket.on("order_add", (o) => {
      setOrders((list) => [o, ...list]);
    });
    socket.on("order_upd_item", (u) => {
      setOrders((list) => {
        return list.map((o) => {
          return o._id == u._id ? Object.assign(o, u) : o;
        });
      });
    });
    return () => {
      socket.off("order_upd_item");
      socket.off("order_add");
    };
  }, []);

  useEffect(() => {
    fetchOrders(true);
  }, [status, site]);

  const fetchOrders = (isInit = false) => {
    setLoading(true);
    OrderService.getList({
      filter: {
        status,
        site,
      },
      pagination: {
        limit: LIMIT,
        skip: isInit ? 0 : orders.length,
      },
    }).then((r) => {
      let data = r.data;

      setOrders((list) => {
        return isInit ? data : [...list, ...data];
      });
      setLoading(false);
    });
  };

  let filtredOrders = filterOrder(site, status, [...orders]);

  // Handlers
  const openAddOrderHandler = () => {
    setShowAddOrder(true);
  };
  const closeAddOrderHandler = () => {
    setShowAddOrder(false);
  };

  return (
    <Box>
      <AddOrderDialog handleClose={closeAddOrderHandler} show={showAddOrder} />

      <Filter
        site={site}
        setSite={setSite}
        status={status}
        setStatus={setStatus}
        WEBSITES={WEBSITES_LIST}
        STATUSES={ORDER_STATUS_LIST}
      />
      <Control 
      search={search}
      setSearch={setSearch}
      openAddOrderHandler={openAddOrderHandler} />

      <InfiniteScroll
        dataLength={orders.length}
        next={fetchOrders}
        hasMore={true}
        loader={null}
      >
        <Grid sx={{ mt: 4 }} container spacing={4}>
          {filtredOrders.map((o) => {
            return (
              <Grid key={o._id} item xs={12} sm={6} md={3}>
                <OrderCard key={o._id} status={o?.status} data={o} />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <WalletQuickView />
    </Box>
  );
}

function filterOrder(sc, status, orders) {
  let result = [...orders];

  if (sc !== "all") {
    result = result.filter((o) => o.sc == sc);
  }

  if (status !== "all") {
    switch (status) {
      case "new":
        result = result.filter((o) => {
          let s = o.status;
          return (s == 101 || s == 102 || s == 103 || s == 104) && !o.archived;
        });
        break;
      case "completed":
        result = result.filter((o) => {
          let s = o.status;
          return s == 105 && !o.archived;
        });
        break;
      case "rejected":
        result = result.filter((o) => {
          let s = o.status;
          return s == 106 && !o.archived;
        });
        break;
      case "check":
        result = result.filter((o) => {
          return o.onCheck;
        });
        break;
      case "archive":
        result = result.filter((o) => {
          return o.archived;
        });
        break;
      default:
      // result = result
    }
  } else {
    result = result.filter((o) => {
      return !o.archived;
    });
  }
  return result;
}

export default Orders;
