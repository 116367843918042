import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

function Title({ specific }) {
  let name = getNameBySpecific(specific);
  return (
    <Box>
      {/* <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 35, height: 35, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + currency.image}
          sx={{ height: 28, width: 28 }}
        />
      </CustomAvatar> */}
      <Typography fontWeight="bold" variant="subtitle1">
        {`${name} API`}
      </Typography>
    </Box>
  );
}

function getNameBySpecific(specific) {
  switch (specific) {
    case "advc":
      return "Advanced Cash";
    case "payeer":
      return "Payeer";
    case "ym":
      return "YooMoney";
    default:
      return "";
  }
}

export default Title;
