import React, { useEffect, useState } from "react";

// Mui
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

// Event
import emitter from "src/EventEmitter";

const defaultState = {
  open: false,
  vertical: "top",
  horizontal: "right",
  message: "",
  severity: "info",
};

const TransitionRight = (props) => {
  return <Slide {...props} direction="left" />;
};

function SnackbarModule() {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    emitter.addListener("snackbar", function (d) {
      setState(Object.assign({}, defaultState, d));
    });
  }, []);

  const { open, vertical, horizontal, message, severity } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Box>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        TransitionComponent={TransitionRight}
        // anc
      >
        <Alert variant='filled' elevation={3} onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SnackbarModule;
