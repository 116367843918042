// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";

// Configs
import CONSTANTS from "src/constants/index";

const userLoyaltyColors = {
  default: "secondary",
  permanent: "success",
  risk: "error",
};

function LoyaltyChip(props) {
  let { loyalty, onClick = undefined } = props;
  return (
    <CustomChip
      onClick={onClick}
      skin="light"
      size="small"
      label={CONSTANTS.loalty[loyalty]}
      color={userLoyaltyColors[loyalty]}
      sx={{
        textTransform: "capitalize",
        "& .MuiChip-label": { lineHeight: "18px" },
      }}
      {...props}
    />
  );
}

export default LoyaltyChip;
