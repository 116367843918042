// Services
import InfoService from "src/service/Info";

// Store
import StoreServiceData from "src/store/ServiceData";

// promise
async function initLoadData() {
  let fiatPrice = InfoService.getFiatPrice();

  return new Promise((res, rej) => {
    Promise.all([fiatPrice]).then((values) => {
      StoreServiceData.setFiatPrices(values[0].data);
      res();
    });
  });
}

export default initLoadData;
