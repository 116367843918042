// ** MUI Imports
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

// Vars
let selectList = [
  {
    name: "Обычный",
    value: "default",
  },
  {
    name: "Постоянный",
    value: "permanent",
  },
  {
    name: "Рискованный",
    value: "risk",
  },
];

function LoyaltySelect({ initValue, value, setValue, style }) {
  return (
    <FormControl size="small" fullWidth sx={{ ...style }}>
      <InputLabel id="kit-loyalty-label">Лояльность</InputLabel>
      <Select
        size="small"
        label="Лояльность"
        defaultValue={initValue}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        id="kit-loyalty"
        labelId="kit-loyalty-label"
      >
        {selectList.map((i) => (
          <MenuItem value={i.value} key={i.value}>
            {i.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default LoyaltySelect;
