import { useState, useEffect } from "react";
// ** MUI Imports
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { toast } from "react-hot-toast";

// Components
import ClientService from "src/service/Client";

function ChargeRefToggle({ clientId, initDncr }) {
  let [dncr, setDncr] = useState(initDncr);

  const changeDncrHandler = () => {
    let newDncr = !dncr;
    ClientService.change(clientId, { dncr: newDncr }).then(() => {
      setDncr(newDncr);
      toast.success(
        `Начисление рефералу ${newDncr ? "выключено" : "включено"}!`
      );
    });
  };

  return (
    <Box sx={{mr: 2}}>
      <IconButton
        onClick={changeDncrHandler}
        color={dncr ? "error" : "success"}
        sx={{ borderRadius: 1 }}
        title="Начисление рефералу"
      >
        <Icon
          icon={dncr ? "tabler:cash-banknote-off" : "tabler:cash-banknote"}
        />
      </IconButton>
    </Box>
  );
}

export default ChargeRefToggle;
