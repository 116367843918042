import React, { useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";

// ** Validation
import { useForm, Controller } from "react-hook-form";

// ** Toaster Import
import toast from "react-hot-toast";

// Components
import ClientService from "src/service/Client";

// Event
import emitter from "src/EventEmitter";

function EditClientModal(props) {
  let { show, handleClose, data, updateData } = props;
  let [isSubmiting, setSubmiting] = useState(false);

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: data.email,
      name: data.name,
      telegram: data.telegram,
      phone: data.phone,
    },
    mode: "onBlur",
  });

  // Handlers
  function closeHandlerModal() {
    reset();
    handleClose();
  }
  const onSubmit = handleSubmit((d) => {
    setSubmiting(true);

    ClientService.change(data._id, d)
      .then(() => {
        updateData(d);
        emitter.emit("snackbar", {
          open: true,
          message: "Клиент измененн!",
          severity: "success",
        });
        closeHandlerModal();
      })
      .catch((e) => {
        let message = e?.response?.data?.message;
        if (message) {
          emitter.emit("snackbar", {
            open: true,
            message,
            severity: "error",
          });
        }
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Dialog
      open={show}
      onClose={closeHandlerModal}
      aria-labelledby="user-view-edit"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
      aria-describedby="user-view-edit-description"
    >
      <DialogTitle
        id="user-view-edit"
        sx={{ textAlign: "center", fontSize: "1.5rem !important" }}
      >
        Редактирование клиента
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body2"
          id="user-view-edit-description"
          sx={{ textAlign: "center", mb: 7 }}
        >
          Изменение данных о клиенте
        </DialogContentText>
        <form noValidate onSubmit={onSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    placeholder="test@example.com"
                    id="email"
                    type="email"
                    label="Email"
                    error={Boolean(errors.email?.message)}
                    {...register("email", {
                      required: "Введите Email!",
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Некорректный Email!",
                      },
                    })}
                    helperText={errors.email?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    placeholder="Ivan Ivanov"
                    fullWidth
                    id="name"
                    type="text"
                    label="Имя"
                    error={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: "Введите Имя!",
                    })}
                    helperText={errors.name?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"telegram"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    placeholder="@nickname (необязательно)"
                    fullWidth
                    id="telegram"
                    type="text"
                    label="Ник Telegram"
                    error={Boolean(errors.telegram?.message)}
                    {...register("telegram", {})}
                    helperText={errors.telegram?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    placeholder="7 999 999 9999"
                    fullWidth
                    id="phone"
                    type="number"
                    label="Номер телефона"
                    error={Boolean(errors.phone?.message)}
                    {...register("phone", {})}
                    helperText={errors.phone?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={onSubmit}
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          Сохранить
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={closeHandlerModal}
        >
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditClientModal;
