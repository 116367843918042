import { useState, useEffect } from "react";
// Mui
import {
  Typography,
  Box,
  CardContent,
  Card,
  CardActionArea,
} from "@mui/material";

// Services
import OrdersService from "src/service/Order";
// Components
import ViewImage from "src/components/ViewImage";

// Socket
import { socket } from "src/socket";

function QrImage({ orderId }) {
  let [loaded, setLoaded] = useState(false);
  let [imageBase, setImageBase] = useState(null);

  let [showImage, setShowImage] = useState(false);
  let [urlImage, setUrlImage] = useState(false);

  async function fetchImage() {
    OrdersService.getQr(orderId).then((r) => {
      let baseImg = r.data.image;
      setImageBase("data:image/jpeg;base64," + baseImg);
      setLoaded(true);
    });
  }

  useEffect(() => {
    fetchImage();

    socket.on(`update_qr_info_${orderId}`, fetchImage);
    return () => {
      socket.off(`update_qr_info_${orderId}`);
    };
  }, []);

  // Handlers
  const closeShowImage = () => {
    setShowImage(false);
    setUrlImage("");
  };
  const openShowImage = (url) => {
    setUrlImage(url);
    setShowImage(true);
  };

  if (!loaded) return null;

  let content = (
    <Typography sx={{ mb: 2 }} variant="body1">
      QR-код ТКС не загружен
    </Typography>
  );

  if (imageBase) {
    content = (
      <Box>
        <Typography sx={{ mb: 2 }} variant="body1">
          QR-код ТКС
        </Typography>
        <ViewImage
          show={showImage}
          handleClose={closeShowImage}
          value={urlImage}
        />
        <CardActionArea onClick={() => openShowImage(imageBase)}>
          <img src={imageBase} width="100%" />
        </CardActionArea>
      </Box>
    );
  }

  return <Card sx={{ py: 1.5, px: 2 }}>{content}</Card>;
}

export default QrImage;
