import { useState, useEffect } from "react";
// MUI
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// Modules
import Title from "./Title";
import SelectSite from "./SelectSite";

// ** Icon Imports
import Icon from "src/@core/components/icon";
// import

// Configs
import WEBSITES from "src/configs/websites";

// Service
import AcquiringService from "src/service/Acquiring";
import { toast } from "react-hot-toast";

const websites_options = WEBSITES.map((w) => {
  return {
    name: w.name,
    value: w.id,
  };
});

function Acquiring({ style = {}, specific, requisiteId }) {
  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, ...style }}>
      <CardContent sx={{ p: 2, pb: "8px !important" }}>
        <Title specific={specific} />
        {specific == "advc" && <AdvcContent requisiteId={requisiteId} />}
        {specific == "payeer" && <PayeerContent requisiteId={requisiteId} />}
        {specific == "ym" && <YoomoneyContent requisiteId={requisiteId} />}
      </CardContent>
    </Card>
  );
}

function AdvcContent({ requisiteId }) {
  let [site, setSite] = useState(websites_options[0].value);
  let [data, setData] = useState(true);

  let [email, setEmail] = useState("");
  let [shopName, setShopName] = useState("");
  let [secretKey, setSecretKey] = useState("");

  // Handlers

  // Hooks
  useEffect(() => {
    AcquiringService.getAdvancedCash({
      sc: site,
      requisiteId,
    }).then(({ data }) => {
      setData(data);
      setEmail(data.email);
      setShopName(data.shopName);
      setSecretKey(data.secretKey);
    });
  }, [site]);

  //   requisiteId, email, shopName, secretKey, sc

  //Handlers
  const saveHandler = () => {
    AcquiringService.changeAdvancedCash({
      sc: site,
      requisiteId,
      email,
      shopName,
      secretKey,
    }).then(({ data }) => {
      setData(data);
      toast.success("Изминения сохраненны!");
    });
  };

  // Calc
  let isChanged =
    email !== data.email ||
    shopName !== data.shopName ||
    secretKey !== data.secretKey;

  if (!data) return null;

  return (
    <Box>
      <SelectSite
        setSite={setSite}
        site={site}
        websites_options={websites_options}
      />
      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            size="small"
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Магазин"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Секретный ключ"
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
          />
        </Grid>
      </Grid>

      {isChanged && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            sx={{ p: 1.5, minWidth: 38 }}
            color="primary"
            onClick={saveHandler}
          >
            <Icon icon="material-symbols:save-outline-rounded" />
          </Button>
        </Box>
      )}
    </Box>
  );
}
function PayeerContent({ requisiteId }) {
  let [site, setSite] = useState(websites_options[0].value);
  let [data, setData] = useState(true);

  let [payeerId, setPayeerId] = useState("");
  let [secretKey, setSecretKey] = useState("");

  // Handlers

  // Hooks
  useEffect(() => {
    AcquiringService.getPayeer({
      sc: site,
      requisiteId,
    }).then(({ data }) => {
      setData(data);
      setPayeerId(data.payeerId);
      setSecretKey(data.secretKey);
    });
  }, [site]);

  //   requisiteId, email, shopName, secretKey, sc

  //Handlers
  const saveHandler = () => {
    AcquiringService.changePayeer({
      sc: site,
      requisiteId,
      payeerId,
      secretKey,
    }).then(({ data }) => {
      setData(data);
      toast.success("Изминения сохраненны!");
    });
  };

  // Calc
  let isChanged = payeerId !== data.payeerId || secretKey !== data.secretKey;

  if (!data) return null;

  return (
    <Box>
      <SelectSite
        setSite={setSite}
        site={site}
        websites_options={websites_options}
      />
      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={6} md={5}>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="ID"
            value={payeerId}
            onChange={(e) => setPayeerId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={7}>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Секретный ключ"
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
          />
        </Grid>
      </Grid>

      {isChanged && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            sx={{ p: 1.5, minWidth: 38 }}
            color="primary"
            onClick={saveHandler}
          >
            <Icon icon="material-symbols:save-outline-rounded" />
          </Button>
        </Box>
      )}
    </Box>
  );
}

function YoomoneyContent({ requisiteId }) {
  let [data, setData] = useState(true);

  let [wallet, setWallet] = useState("");
  let [secretKey, setSecretKey] = useState("");

  // Handlers

  // Hooks
  useEffect(() => {
    AcquiringService.getYoomoney({
      requisiteId,
    }).then(({ data }) => {
      setData(data);
      setWallet(data.wallet);
      setSecretKey(data.secretKey);
    });
  }, []);

  //Handlers
  const saveHandler = () => {
    AcquiringService.changeYoomoney({
      requisiteId,
      wallet,
      secretKey,
    }).then(({ data }) => {
      setData(data);
      toast.success("Изминения сохраненны!");
    });
  };

  // Calc
  let isChanged = wallet !== data.wallet || secretKey !== data.secretKey;

  if (!data) return null;

  return (
    <Box>
      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={6} md={5}>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Кошелёк"
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={7}>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Секретный ключ"
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
          />
        </Grid>
      </Grid>

      {isChanged && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            sx={{ p: 1.5, minWidth: 38 }}
            color="primary"
            onClick={saveHandler}
          >
            <Icon icon="material-symbols:save-outline-rounded" />
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Acquiring;
