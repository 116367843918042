export default function formattedValue(value, validation) {
  switch (validation) {
    case "card":
      return value
        .replace(/\W/gi, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
    case "phone":
      return value.charAt(0) == "+" ? value : "+" + value;
    default:
      return value;
  }
}
