// Mui
import { Box, Typography, Avatar } from "@mui/material";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Icon
import Icon from "src/@core/components/icon";

// Config
import ConfigApi from "src/configs/api";

// moment
import moment from "moment";
import "moment/locale/ru";

function PairInfo({ exchange, takeMainCur, giveMainCur }) {
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CoursePartInfo
          name={takeMainCur.name}
          image={takeMainCur.image}
          price={pairGetPrices(exchange).left}
        />
        <Box sx={{ mx: 3 }}>
          <Icon icon="uil:exchange" />
        </Box>
        <CoursePartInfo
          name={giveMainCur.name}
          image={giveMainCur.image}
          price={pairGetPrices(exchange).right}
        />
      </Box>
      <Typography color="secondary" variant="caption">
        {moment(exchange.updatedAt).format("DD.MM, HH:mm:ss")}
      </Typography>
    </Box>
  );
}

function CoursePartInfo({ name, price, image }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 40, height: 40, mr: 2 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image}
          alt={name}
          sx={{ height: 30, width: 30 }}
        />
      </CustomAvatar>
      <Box>
        <Typography variant="body1">{name}</Typography>
        <Typography variant="body2" color="secondary">
          {price}
        </Typography>
      </Box>
    </Box>
  );
}

function pairGetPrices(exc) {
  const srv = exc.rate.srv;
  const ins = exc.rate.in;

  return {
    left: ins,
    right: srv,
  };
}

export default PairInfo;
