import axios from "axios";

class Exchange {
  addExchange(data) {
    return axios.post("/exchange", data);
  }
  addAllExchange(data) {
    return axios.post("/exchange/all", data);
  }
  getExchangeList(params) {
    return axios.get("/exchange", { params });
  }
  changeExchange(data) {
    return axios.put("/exchange", data);
  }
  changeListExchange(data) {
    return axios.put("/exchange/list", data);
  }
  getOneFullExchang(id) {
    return axios.get("/exchange/full", { params: { id } });
  }
  //Exchange target
  getExchangeTarget(exchangeId) {
    return axios.get(`/exchange/${exchangeId}/target`);
  }
  changeExchangeTarget(exchangeId, data) {
    return axios.put(`/exchange/${exchangeId}/target`, data);
  }
  deleteExchangeTarget(exchangeId) {
    return axios.delete(`/exchange/${exchangeId}/target`);
  }

  // "/exchange/:id/target"
}

export default new Exchange();
