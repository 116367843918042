import { useState, useEffect } from "react";
// Mui
import {
  Typography,
  Box,
  CardContent,
  Card,
  CircularProgress,
  Button,
  Grid,
  CardActionArea,
} from "@mui/material";


import toast from 'react-hot-toast';
// Services
import OrderService from "src/service/Order";
// Custom components
import ImageUploader from "src/components/ImageUploader/ImageUploader";

function QrImageSend({ order }) {
  let [image, setImage] = useState(null);
  let [isSubmiting, setIsSubmiting] = useState(false);

  // Handlers
  const sendImageHandler = () => {
    setIsSubmiting(true);
    OrderService.sendQr(order._id, image)
      .then(() => {
        toast.success('QR код успешно отправлен!')
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  const uploadSuccesHandler = (imgName) => {
    setImage(imgName);
  };

  let qrInfo = order.qrInfo;

  return (
    <CardContent>
      <Typography sx={{ mb: 2 }} variant="body1">
          {qrInfo || 'QR для отправки по Email'}
        </Typography>
      <ImageUploader
        purpose="private"
        uploadSuccesHandler={uploadSuccesHandler}
        sx={{ my: 3 }}
      />
      {!!image && (
        <Button
          onClick={sendImageHandler}
          fullWidth
          color="primary"
          variant="contained"
          disabled={isSubmiting}
          startIcon={isSubmiting ? <CircularProgress /> : null}
        >
          Отправить QR
        </Button>
      )}
    </CardContent>
  );
}

export default QrImageSend;
