import { useState, useEffect } from "react";

// Mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import CardContent from "@mui/material/CardContent";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

/// Toast
import { toast } from "react-hot-toast";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import ServiceRairCoin from "src/service/RairCoin";

function RairCoins() {
  // States
  const [showAddCurrency, setShowAddCurrency] = useState(false);
  const [list, setList] = useState([]);

  // Hooks
  useEffect(() => {
    ServiceRairCoin.getList().then((r) => setList(r.data));
  }, []);

  // Handlers
  const closeShowAddCurrency = () => setShowAddCurrency(false);
  const addNewItemList = (item) => setList((list) => [item, ...list]);
  const deleteItemHandler = (id) => {
    ServiceRairCoin.delete(id).then((r) => {
      setList((list) => list.filter((item) => item._id !== id));
      toast.success("Валюта удалена!");
    });
  };

  return (
    <Card>
      <ModalAddCurrency
        addNewItem={addNewItemList}
        open={showAddCurrency}
        handleClose={closeShowAddCurrency}
      />
      <CardHeader
        action={
          <Box>
            <Button
              startIcon={<Icon icon="mdi:plus" />}
              // type="submit"
              onClick={() => setShowAddCurrency(true)}
              variant="contained"
            >
              Добавить
            </Button>
          </Box>
        }
        title={"Редкие биржевые валюты CoinMarketCap"}
        subheader="Цены на валюты обновляются каждые 5 минут, исходя из ограничений бесплатного тарифа сервиса на 10000 запросов в месяц"
      />
      <List
        dense
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {list.map((c, i) => {
          return (
            <ListItem>
              <Avatar
                src={c.image}
                alt={c.name}
                sx={{ height: 36, width: 36, mr: 3 }}
              />
              <ListItemText primary={c.name} secondary={c.symbol} />

              <ListItemSecondaryAction>
                <IconButton onClick={() => deleteItemHandler(c._id)} edge="end">
                  <Icon icon="ic:round-delete-forever" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}

export default RairCoins;

function ModalAddCurrency(props) {
  let { open, handleClose, addNewItem } = props;
  let [isSubmiting, setSubmiting] = useState(false);
  let [list, setList] = useState([]);
  let [loading, setLoading] = useState(true);
  let [selected, setSelected] = useState("");

  // Hooks
  useEffect(() => {
    if (!open) {
      setList([]);
      setSelected("");
    } else {
      setLoading(true);
      ServiceRairCoin.getAllList().then((r) => {
        setList(r.data);
        setLoading(false);
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && list.length && !selected) {
      setSelected(list[0].id);
    }
  }, [open, list.length]);

  // Api

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmiting(true);

    let cur = list.find((c) => c.id == selected);

    ServiceRairCoin.create(cur)
      .then((r) => {
        addNewItem(r.data);
        toast.success("Валюта добавлена!");
        handleClose();
      })
      .finally(() => setSubmiting(false));
  };

  return (
    <Box>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        <DialogTitle>Добавить валюту</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <Autocomplete
              loading={loading}
              loadingText="Загрузка..."
              defaultValue={list[0]?.id}
              options={list.map((item) => item.id)}
              getOptionLabel={(id) => {
                let item = list.find((c) => id === c.id);
                if (!item) return "";
                return `${item.name} (${item.symbol})`;
              }}
              onChange={(e, value) => {
                setSelected(value);
              }}
              renderInput={(params) => <TextField {...params} label="Валюта" />}
            />
            <Box
              className="demo-space-x"
              sx={{ "& > :last-child": { mr: "0 !important" } }}
            >
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting || !selected}
                size="large"
                type="submit"
                variant="contained"
              >
                Добавить
              </Button>
              <Button
                type="reset"
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
