// Mui
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";

// Components
import FiatPrices from "./FiatPrices";
import Stat from "./Stat/Stat";
// import StatOrderCount from "./StatOrderCount";
// import StatProfit from "./StatProfit";
import TechnicalBreak from "./TechnicalBreak";

// Modules
import CurrencyPool from "src/modules/CurrencyPool/CurrencyPool";

function Dashboard() {
  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={5}>
          <TechnicalBreak />
          <Box sx={{ mt: 4 }}>
            <FiatPrices />
          </Box>

          <Box sx={{ mt: 4 }}>
            <CurrencyPool hideAddBtn />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stat />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
