import { useState, useEffect } from "react";
// ** MUI Imports
import Drawer from "@mui/material/Drawer";

import CircularProgress from "@mui/material/CircularProgress";

// Services
import CardService from "src/service/Card";

//Router
import { useSearchParams } from "react-router-dom";

// Modules
import CardContent from './Content';

function CardDrawer() {
  // State
  let [loaded, setLoaded] = useState(false);
  let [card, setCard] = useState(null);

  // Hooks Router
  const [searchParams, setSearchParams] = useSearchParams();
  const cardId = searchParams.get("cardId");

  // Hooks
  useEffect(() => {
    if (cardId) {
      CardService.getById(cardId).then((r) => {
        setCard(r.data);
        setLoaded(true);
      });
    } else {
      setLoaded(false);
      setCard(null);
    }
  }, [cardId]);

  // Handler
  const handleClose = () => {
    searchParams.delete("cardId");
    setSearchParams(searchParams);
  };

  // Calc
  let renderComponent = <CircularProgress style={{ margin: "auto" }} />;

  if (loaded && card) {
    renderComponent = <CardContent
    data={card}
    handleClose={handleClose}
    />;
  }

  return (
    <Drawer
      open={!!cardId}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: true }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 550 } } }}
    >
      {renderComponent}
    </Drawer>
  );
}

export default CardDrawer;
