import { useState } from "react";

// Router
import { Link } from "react-router-dom";

// MUI imports
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";
import LoyaltyChip from "src/modules/LoyaltyChip"

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";
import CONSTANTS from "src/constants/index";

// Utils
import moment from "moment";
import "moment/locale/ru";

const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  cursor: "pointer",
  textDecoration: "none",
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

// ** Vars
const userVerifiedIcons = {
  verified: { icon: "ri:mail-check-line", color: "success.main" },
  unverified: { icon: "ri:mail-close-line", color: "error.main" },
};



const columns = [
  {
    flex: 0.15,
    minWidth: 150,
    field: "email",
    headerName: "Email",
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Badge
            color={true ? "success" : "error"}
            variant="dot"
            badgeContent=" "
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <StyledLink to={`/clients/${row._id}`}>{row.email}</StyledLink>
          </Badge>
        </Box>
      );
    },
  },
  {
    flex: 0.08,
    minWidth: 80,
    field: "sc",
    headerName: "Сайт",
    renderCell: ({ row }) => {
      return (
        <Typography variant="subtitle1" noWrap>
          {WEBSITES.find((s) => s.id === row.sc).name}
        </Typography>
      );
    },
  },
  {
    flex: 0.1,
    minWidth: 100,
    field: "name",
    headerName: "Имя",
    renderCell: ({ row }) => {
      return (
        <Typography noWrap variant="body2">
          {row.name}
        </Typography>
      );
    },
  },
  {
    flex: 0.1,
    minWidth: 100,
    field: "loyalty",
    headerName: "Лояльность",
    renderCell: ({ row }) => {
      return (
        <LoyaltyChip loyalty={row.loyalty}/>
      );
    },
  },
  {
    flex: 0.15,
    minWidth: 50,
    field: "verified",
    headerName: "Вериф.",
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
              mr: 3,
              color:
                userVerifiedIcons[row.verified ? "verified" : "unverified"]
                  .color,
            },
          }}
        >
          <Icon
            icon={
              userVerifiedIcons[row.verified ? "verified" : "unverified"].icon
            }
            fontSize={20}
          />
        </Box>
      );
    },
  },
  // {
  //   flex: 0.1,
  //   minWidth: 110,
  //   field: "email",
  //   headerName: "Обменов",
  //   renderCell: ({ row }) => {
  //     return (
  //       <Typography noWrap variant="body2">
  //         {new Intl.NumberFormat("ru-RU").format(0)} RUB
  //       </Typography>
  //     );
  //   },
  // },
  {
    flex: 0.1,
    minWidth: 100,
    field: "createdAt",
    headerName: "Дата рег.",
    renderCell: ({ row }) => {
      return (
        <Typography noWrap variant="overline">
          {moment(row.createdAt).format("DD.MM.YYYY, в HH:mm")}
        </Typography>
      );
    },
  },
];

const Table = ({ pagination, loading, handleChangePagination, clients }) => {
  let list = clients.map((c) => Object.assign({}, c, { id: c._id }));

  return (
    <DataGrid
      columns={columns}
      autoHeight
      loading={loading}
      rows={list}
      // checkboxSelection
      disableSelectionOnClick
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
      pageSize={pagination.pageSize}
      onPageSizeChange={(newPageSize) =>
        handleChangePagination("pageSize", newPageSize)
      }
      pagination
      rowCount={pagination.rowCount}
      paginationMode="server"
      onPageChange={(newPage) => handleChangePagination("page", newPage)}
    />
  );
};

export default Table;
