import React, { useState } from "react";

// MUI
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// Validation
import { useForm, Controller } from "react-hook-form";
// Modules
import CustomEditor from "src/modules/Editor";

// Services
import MailService from "src/service/Mail";

// Toast
import toast from "react-hot-toast";

// Vars
let timer;

function Mailing({ clientId }) {
  let [message, setMessage] = useState("<p></p>");
  let [isSubmiting, setSubmiting] = useState(false);
  let [messageError, setMessageError] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      theme: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    if (!message) {
      setMessageError(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setMessageError(false);
      }, 3000);
      return;
    }
    setSubmiting(true);

    MailService.sendMail({
      _id: clientId,
      message: message,
      theme: d.theme,
    })
      .then(() => {
        setMessage("");
        setValue("theme", "");
        toast.success("Сообщение отправлено!");
      })
      .finally(() => {
        setSubmiting(false);
      });

    console.log(d.theme, message, "we");
  });

  return (
    <Card>
      <form noValidate onSubmit={onSubmit}>
        <CardHeader title="Отправить сообщение" />
        <CardContent>
          <Controller
            name={"theme"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                id="theme"
                type="text"
                label="Тема"
                error={Boolean(errors.theme?.message)}
                {...register("theme", {
                  required: "Введите тему!",
                })}
                sx={{ mb: 3 }}
                helperText={errors.theme?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Collapse in={messageError} sx={{ mb: 3 }}>
            <Alert severity="error">Введите сообщение!</Alert>
          </Collapse>
          <CustomEditor
            value={message}
            onChange={(v) => setMessage(v)}
          />
        </CardContent>
        <CardActions>
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            type="submit"
            variant="contained"
          >
            Отправить
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}

export default Mailing;
