import axios from "axios";

class Localization {
  getData() {
    return axios.get("/localization");
  }
  changeData(data) {
    return axios.post("/localization", data);
  }
}

export default new Localization();
