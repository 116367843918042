// Mui
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";

// Ui
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

function RewardFields(props) {
  let { style = {}, list = [], sum, code } = props;
  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, ...style }}>
      <CardContent sx={{ py: 2, pb: "8px !important", px: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Cумма:
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 800, ml: 2 }}>
            {sum} RUB
          </Typography>
          <CopyClipboardWrap value={sum} />
        </Box>

        {list.map((f) => {
          return (
            <Box
              sx={{
                display: "flex",
                overflow: "hidden",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <Typography sx={{ mr: 2 }} variant="body2">
                {f.name}:
              </Typography>
              <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
                <Typography noWrap variant="body1">
                  {f.value}
                </Typography>
                <CopyClipboardWrap value={f.value.trim()} />
              </Box>
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default RewardFields;
