import { Fragment } from "react";

// Mui
import Typography from "@mui/material/Typography";

function ChangeBalance(props) {
  let { data } = props;

  let name = data?.name;

  return (
    <Fragment>
      {name && (
        <Typography
          sx={{ fontWeight: 600, fontSize: "14px" }}
          color="main"
          variant="body1"
        >
          {name}
        </Typography>
      )}

      <Typography variant="subtitle2">
        {`${formatPrice(data?.ob)} => ${formatPrice(data?.nb)}`}
      </Typography>
    </Fragment>
  );
}

function formatPrice(price) {
  return new Intl.NumberFormat("ru-RU").format(price);
}

export default ChangeBalance;

//
