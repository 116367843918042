import { useState, useEffect } from "react";
//Mui
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";

import InputMask from "react-input-mask";
// Form
import { useForm, Controller } from "react-hook-form";

// Components
import CurrencySelect from "src/components/CurrencySelect";

// Helpers
import luhnCardValidate from "src/helpers/luhnCardValidate";

//Services
import CurrencyService from "src/service/Currencies";
import ClientService from "src/service/Client";
import CardService from "src/service/Card";

// Toast
import { toast } from "react-hot-toast";
import { Typography } from "@mui/material";

// Custom components
import ImageUploader from "src/components/ImageUploader/ImageUploader";

function AddCardDialog(props) {
  let { show, handleClose, clientId } = props;
  // States
  const [cardCurrencies, setCardCurrencies] = useState([]);
  const [bank, setBank] = useState("");
  const [users, setUsers] = useState([]);
  const [client, setClient] = useState(clientId || "");
  const [imageName, setImageName] = useState('');
  const [selectClientError, setSelectClientError] = useState("");
  const [isSubmiting, setSubmiting] = useState(false);

  // Hooks

  useEffect(() => {
    CurrencyService.getList({ populate: ["settings", "info"] }).then((r) => {
      setCardCurrencies(filterCurrenciesWithCard(r.data));
    });
  }, []);

  // Calc
  const selectedBank = cardCurrencies.find((c) => c._id == bank);


  // Handlers
  const handleClientSearchChange = (event, value) => {
    setSelectClientError("");
    if (value.length < 3) return;
    ClientService.search(value).then((r) => {
      let list = r.data.map((c) => ({
        title: `${c.email} | ${c.name} | ${c.telegram}`,
        value: c._id,
      }));
      setUsers(list);
    });
  };

  const uploadSuccesHandler = (imgName) => {
    setImageName(imgName);
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    fields,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cardNumber: "",
      name: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let clientId = client?.value || client;

    if (!clientId) {
      setSelectClientError("Выберите держателя карты!");
      return;
    }

    let fetchObj = {
      number: d.cardNumber,
      name: d.name,
      client: clientId,
      currency: bank,
      image: imageName
    };

    setSubmiting(true);

    CardService.add(fetchObj)
      .then(() => {
        handleClose();
        toast.success("Карта добавлена!");
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  let updlodCardImageComponent = null;

  if (selectedBank?.settings?.isVerify) {
    updlodCardImageComponent = (
      <ImageUploader purpose="card" uploadSuccesHandler={uploadSuccesHandler} sx={{ my: 3 }} />
    );
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 550, p: [2, 10] } }}
    >
      <DialogTitle
        id="user-view-billing-edit-card"
        sx={{ textAlign: "center", fontSize: "1.5rem !important" }}
      >
        Добавить карту
      </DialogTitle>
      <form noValidate onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText
            variant="body2"
            id="user-view-billing-edit-card-description"
            sx={{ textAlign: "center", mb: 2 }}
          >
            Добавьте новую одобренную карту для пользователя
          </DialogContentText>
          <Box sx={{ mt: 4 }}>
            <Controller
              name={"cardNumber"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputMask
                  error={Boolean(errors.cardNumber?.message)}
                  helperText={errors.cardNumber?.message}
                  {...register("cardNumber", {
                    required: "Обязательное поле!",
                    validate: (val) => {
                      return (
                        luhnCardValidate(val.replace(/\D/g, "")) ||
                        "Неверный формат карты!"
                      );
                    },
                  })}
                  fullWidth
                  label={"Номер карты"}
                  margin="normal"
                  maskChar=" "
                  mask="9999 9999 9999 9999 99"
                  value={value}
                  onChange={onChange}
                  name={"cardNumber"}
                  type="text"
                >
                  {(e) => <TextField {...e} />}
                </InputMask>
              )}
            />

            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Имя держателя"
                  placeholder="Введите имя держателя карты"
                  id="name"
                  type="text"
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.name?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Box>
          <CurrencySelect
            containerStyle={{ mt: 4 }}
            label="Банк Карты"
            placeholder="Выберите банк"
            currencies={cardCurrencies}
            value={bank}
            setValue={setBank}
            addEmpty
            emptyName="Без банка"
          />
          {!clientId && (
            <Autocomplete
              sx={{ mt: 4 }}
              options={users}
              fullWidth
              value={client}
              onChange={(e, v) => setClient(v)}
              onInputChange={handleClientSearchChange}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Держатель карты"
                  error={Boolean(selectClientError)}
                  helperText={selectClientError}
                />
              )}
            />
          )}
          {updlodCardImageComponent}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            type="submit"
            onClick={onSubmit}
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
          >
            Добавить
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Отменить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddCardDialog;

function filterCurrenciesWithCard(currencies) {
  // если есть поле карты в takeFields isBank

  let result = [];

  currencies.forEach((c) => {
    let currencySettings = c.settings;

    if (currencySettings.isBank && !currencySettings.dontTie) {
      result.push(c);
    }
  });

  return result;
}
