import { useEffect, useState } from "react";

// Mui
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/Table";

function ResolveTable({ list }) {
  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, mt: 2 }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Комплект</TableCell>
              <TableCell>Реквизит</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell>Коммиссия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <TableRow
                key={row._id}
                sx={{
                  "&:last-of-type td, &:last-of-type th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.kitName}
                </TableCell>
                <TableCell align="left">{row.requisiteName}</TableCell>

                <TableCell align="left">{row.amount}</TableCell>
                <TableCell align="left">{row.commission}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default ResolveTable;
