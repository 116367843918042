import { useEffect, useState } from "react";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import CardService from "src/service/Card";

// Modules
import CreditCard from "src/modules/Cards/CreditCard/CreditCard";
import AddCardDialog from "src/modules/Cards/AddCardDialog/AddCardDialog";

// Socket
import { socket } from "src/socket";

function Cards(props) {
  let { clientId } = props;
  // States
  let [cards, setCards] = useState([]);
  let [showAddCard, setShowAddCard] = useState(false);

  useEffect(() => {
    CardService.getList({
      query: {
        client: clientId,
      },
      pagination: {
        limit: 300,
        skip: 0,
      },
    }).then((r) => setCards(r.data));
    socket.on("card_add", (card) => {
      if (card.client._id !== clientId) return;
      setCards((cards) => [card, ...cards]);
    });
    return () => {
      socket.off("card_add");
    };
  }, []);

  return (
    <Box>
      <Button
        sx={{ mb: 2 }}
        startIcon={<Icon icon="material-symbols:add-card-outline" />}
        variant="contained"
        onClick={() => setShowAddCard(true)}
      >
        Добавить
      </Button>
      <AddCardDialog
        clientId={clientId}
        show={showAddCard}
        handleClose={() => setShowAddCard(false)}
      />
      <Grid container spacing={4}>
        {cards.map((c) => {
          return (
            <Grid item xs={12} md={6} key={c._id}>
              <CreditCard data={c} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Cards;
