export default function filterCurrenciesWithCard(currencies) {
  // если есть поле карты в takeFields isBank

  let result = [];

  currencies.forEach((c) => {
    let currencySettings = c.settings;

    if (currencySettings.isBank && !currencySettings.dontTie) {
      result.push(c);
    }
  });

  return result;
}
