import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Slider from "@mui/material/Slider";
import Fab from "@mui/material/Fab";

// Services
import SystemSettingsService from "src/service/SystemSettings";

// ** Icon
import Icon from "src/@core/components/icon";

//Toast
import { toast } from "react-hot-toast";


function generateMarks(start, end) {
  const marks = [];
  for (let i = start; i <= end; i++) {
    marks.push({ value: i, label: `${i}` });
  }
  return marks;
}

function TimeoutSetting() {
  // State
  let [settings, setSettings] = useState(null);

  useEffect(() => {
    SystemSettingsService.get().then((r) => setSettings(r.data));
  }, []);

  // Handlers
  const saveHandler = () => {
    SystemSettingsService.change(settings).then(() =>
      toast.success("Настройки измененны!")
    );
  };

  // Calc
  const marksCurrency = generateMarks(3, 20);
  const marksExchange = generateMarks(3, 20);
  const marksTargetExchange = generateMarks(5, 15);

  if (!settings) return null;

  return (
    <Card>
      <CardHeader
        title="Таймаут"
        {...{
          action: (
            <Fab
              color="primary"
              variant="extended"
              size="medium"
              sx={{ "& svg": { mr: 1 } }}
              onClick={saveHandler}
            >
              <Icon icon="material-symbols:save-outline-sharp" />
              Сохранить
            </Fab>
          ),
        }}
      />
      <CardContent>
        <Typography sx={{ mb: 2 }} variant="body1">
          Обновление цен на валюты
        </Typography>
        <Typography variant="caption">
          *cлишком частые запросы к API бирж могут вызывать ошибки
        </Typography>
        <Slider
          value={settings.currencyUpdateTimeout}
          onChange={(event, newValue) =>
            setSettings(
              Object.assign({}, settings, { currencyUpdateTimeout: newValue })
            )
          }
          marks={marksCurrency}
          step={1}
          min={3}
          max={20}
          valueLabelDisplay="on"
          valueLabelFormat={(v) => `${v} сек.`}
          getAriaValueText={(v) => v}
        />
        <Typography sx={{ mb: 2, mt: 6 }} variant="body1">
          Обновление цен обменных пар
        </Typography>
        <Typography variant="caption">
          *слишком частое обновление цен обменных пар создает безсмысленную
          нагрузку на сервис, так как цена на валюту осталось прежней
        </Typography>
        <Slider
          value={settings.exchangeUpdateTimeout}
          onChange={(event, newValue) =>
            setSettings(
              Object.assign({}, settings, { exchangeUpdateTimeout: newValue })
            )
          }
          marks={marksExchange}
          step={1}
          min={3}
          max={20}
          valueLabelDisplay="on"
          valueLabelFormat={(v) => `${v} сек.`}
          getAriaValueText={(v) => v}
        />
        <Typography sx={{ mb: 2, mt: 6 }} variant="body1">
          Периодичность вывода обменных пар в топ
        </Typography>
        <Slider
          value={settings.targetTimeout}
          onChange={(event, newValue) =>
            setSettings(
              Object.assign({}, settings, { targetTimeout: newValue })
            )
          }
          marks={marksTargetExchange}
          step={1}
          min={5}
          max={15}
          valueLabelDisplay="on"
          valueLabelFormat={(v) => `${v} сек.`}
          getAriaValueText={(v) => v}
        />
      </CardContent>
    </Card>
  );
}

export default TimeoutSetting;
