import axios from "axios";

class Client {
  add(data) {
    return axios.post("/client", data);
  }
  getList(query) {
    return axios.get("/client", { params: query });
  }
  search(name) {
    return axios.get(`/client/search`, { params: { name } });
  }
  getOne(id) {
    return axios.get(`/client/${id}`);
  }
  setNewPassword(id, password) {
    return axios.put(`/client/${id}/password`, { password });
  }
  change(id, data) {
    return axios.put(`/client/${id}`, data);
  }
  setVerified(id) {
    return axios.put(`/client/${id}/verified`);
  }
  setReferralPercent(id, percent) {
    return axios.put(`/client/${id}/referral-percent`, { percent });
  }
}

export default new Client();
