import axios from "axios";

class RairCoin {
  getAllList() {
    return axios.get("/rair-coin/all-list");
  }
  getList() {
    return axios.get("/rair-coin");
  }
  create(data) {
    return axios.post("/rair-coin", data);
  }
  delete(id) {
    return axios.delete("/rair-coin", { data: { id } });
  }
}

export default new RairCoin();
