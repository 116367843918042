import axios from "axios";

class CommissionBtc {
  getAll() {
    return axios.get("/btc-commissison");
  }
  add() {
    return axios.post("/btc-commissison");
  }
  change(id, data) {
    return axios.put(`/btc-commissison/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/btc-commissison/${id}`);
  }
}

export default new CommissionBtc();
