// Mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// Components
import TimeoutSetting from "./TimeoutSetting";
import MainSetting from "./MainSetting";

export default function SystemSettings() {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TimeoutSetting />
        </Grid>
        <Grid item xs={12} md={6}>
          <MainSetting />
        </Grid>
      </Grid>
    </Box>
  );
}
