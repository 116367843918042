const SERVER =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://api.admexch.xyz";

const config = {
  API: `${SERVER}/crm`,
  SERVER,
  PUBLIC_IMAGES: `${SERVER}/imgs/`,
};

export default config;
