// ** MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";

function ActiveSwitch({
  style = {},
  hideNumber = false,
  isKit,
  number,
  enabled,
  onChange = () => {},
  hideToggle,
}) {
  return (
    <Box
      sx={{
        width: hideToggle || hideNumber ? "40px" : "80px",
        p: hideToggle ? "8px" : "auto",
        borderRadius: "5px",

        border: (theme) => `1px solid ${theme.palette.divider}`,
        overflow: "hidden",
        ...style,
      }}
    >
      <Grid container sx={{ height: "100% !important" }}>
        {!hideNumber && (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={hideToggle || hideNumber ? 12 : 6}
          >
            <Typography
              sx={{
                fontWeight: isKit ? 800 : 400,
              }}
              textAlign="center"
              variant="body2"
            >
              {number}
            </Typography>
          </Grid>
        )}

        {!hideToggle && (
          <Grid item xs={hideNumber ? 12 : 6}>
            <IconButton
              color={enabled ? "primary" : "secondary"}
              variant="contained"
              onClick={() => onChange(!enabled)}
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: 0,
                width: "100%",
                p: "5px 5px",
                height: "100%",
                color: enabled ? "primary.dark" : "secondary.light"
              }}
              fullWidth
            >
              <Icon
                icon={
                  enabled
                    ? "pepicons-pop:power-circle-filled"
                    : "pepicons-pop:power-circle-off"
                }
              />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
export default ActiveSwitch;
