// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Modules
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

function SumTitle(props) {
  let { text, sum, code, style = {}, old = false } = props;

  let textStyle = {};

  if (old) {
    textStyle = {
      color: "red",
      textDecoration: "line-through",
    };
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...style }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {text}:
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 800, ml: 2, ...textStyle }}
      >
        {sum} {code}
      </Typography>
      {!old && <CopyClipboardWrap value={sum} />}
    </Box>
  );
}

export default SumTitle;
