import { Fragment, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

// Modules
import Fields from "./Modules/Fields";
import SumTitle from "./Modules/SumTitle";
import QrImageSend from "./QrImageSend";

// Services
import OrderService from "src/service/Order";

// Helpers
import getTransactionLink from "src/helpers/order/getTransactionLink";

// Toast
import { toast } from "react-hot-toast";

////
import ChooseWalletSendPayment from "./ChooseWalletSendPayment";
import OutcomeTable from "./OutcomeTable";

// Modules
import AddressQr from "./Modules/AddressQr";
import StepSendDebt from "./StepSendDebt";

function StepSending(props) {
  let { style = {}, disabledComponent = false, order } = props;

  let [viewComponentName, setViewComponentName] = useState(false);
  let [isSubmit, setSubmit] = useState("");

  // Calc
  let amount = order.amount;
  let giveCurrency = order.currency.give;
  let serviceConfirmPayment = order.status == 104;
  let isResolved = order.status == 105;
  let onCheck = isResolved && order.onCheck;
  let giveIsQr =
    giveCurrency?.currency && giveCurrency?.currency?.specific == "qr";

  // View calc

  // Handlers
  const checkedHandler = () => {
    setSubmit("checked");
    OrderService.checked(order._id)
      .then(() => {
        toast.success("Заявка проверенна!");
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const refundHandler = () => {
    setSubmit("refund");
    OrderService.refund(order._id)
      .then(() => {
        toast.success("Возврат средств успешен!");
      })
      .finally(() => {
        setSubmit("");
      });
  };

  //Transaction

  let transLink = order?.currency?.give.currency?.info?.transLink;
  let giveAddress = order.fields.give.find((f) => f.validation == "address");
  let transactionComponent = null;

  if (transLink && giveAddress) {
    let link = transLink + giveAddress.value;

    transactionComponent = (
      <Box
        sx={{
          mt: 3,
          display: "flex",
          overflow: "hidden",
          alignItems: "center",
          maxWidth: "100%",
        }}
      >
        <Typography color="primary" sx={{ mr: 2 }} variant="body2">
          Транзакция:
        </Typography>
        <Link
          component={"a"}
          target="_blank"
          href={link}
          color="primary"
          underline="none"
          rel="noreferrer"
          variant="body1"
          sx={{ wordBreak: "break-word" }}
        >
          {link}
        </Link>
      </Box>
    );
  }

  // View Components

  let controlComponent = (
    <CardContent sx={{ p: 3 }}>
      <Grid container spacing={4}>
        {serviceConfirmPayment && (
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ fontSize: "0.8rem" }}
              variant="contained"
              color="primary"
              onClick={() => setViewComponentName("select")}
            >
              Выбрать реквизит
            </Button>
          </Grid>
        )}
        {(isResolved || onCheck) && (
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ fontSize: "0.8rem" }}
              variant="outlined"
              color="error"
              onClick={refundHandler}
              disabled={isSubmit == "refund"}
              startIcon={isSubmit == "refund" ? <CircularProgress /> : null}
            >
              Возврат средств
            </Button>
          </Grid>
        )}
        {onCheck && (
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ fontSize: "0.8rem" }}
              variant="contained"
              color="primary"
              onClick={checkedHandler}
              disabled={isSubmit == "checked"}
              startIcon={isSubmit == "checked" ? <CircularProgress /> : null}
            >
              Проверено
            </Button>
          </Grid>
        )}
        {isResolved && (
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ fontSize: "0.8rem" }}
              variant="text"
              color="primary"
              onClick={() => setViewComponentName("cms")}
            >
              Изменить комиссию
            </Button>
          </Grid>
        )}
      </Grid>
    </CardContent>
  );

  let viewComponent = controlComponent;

  if (viewComponentName == "select") {
    viewComponent = null;
  } else if (viewComponentName == "cms") {
    viewComponent = null;
  }

  // let isChecked = false;

  // Styles

  let addedStyles = {};

  if (disabledComponent) {
    addedStyles = {
      "pointer-events": "none",
      cursor: "default",
      opacity: 0.32,
    };
  }
  // Handlers

  // if()

  return (
    <Card
      sx={{
        bgcolor: (t) => t.palette.background.default,
        ...addedStyles,
        ...style,
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SumTitle text={"Сумма"} sum={amount.give} code={giveCurrency.code} />
          {!!amount.old && (
            <SumTitle
              old
              text={"Старая сумма"}
              sum={amount?.old?.give}
              code={giveCurrency.code}
            />
          )}
        </Box>
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      {/* {!!order.orderDebt && (
        <>
          <StepSendDebt debt={order.orderDebt} originalSum={order.amount.give} initGiveCur={order?.currency?.give?.currency} />
          <Divider />
        </>
      )} */}

      <CardContent sx={{ p: 3 }}>
        <Typography variant="body1">Реквизиты клиента:</Typography>
        <Fields list={order.fields.give} style={{ mt: 2 }} />
        {transactionComponent}
      </CardContent>
      {!!giveAddress?.value && <AddressQr value={giveAddress?.value} />}
      <Divider sx={{ my: "0px !important" }} />
      {!!giveIsQr && <QrImageSend order={order} />}

      {serviceConfirmPayment && viewComponentName == "select" && (
        <ChooseWalletSendPayment
          handleClose={() => setViewComponentName("")}
          sum={order.amount.give}
          currency={order.currency.give.currency}
          orderId={order._id}
          code={order?.currency?.give?.currency?.code}
        />
      )}
      {order.outcomeInfo && isResolved && (
        <Fragment>
          <CardContent sx={{ p: 3, pb: 2 }}>
            <Typography variant="body1">
              Реквизиты сервиса для отправки:
            </Typography>
          </CardContent>
          <OutcomeTable
            orderId={order._id}
            isChanged={viewComponentName == "cms"}
            outcomeInfo={order.outcomeInfo}
            handleClose={() => setViewComponentName("")}
          />
        </Fragment>
      )}

      {viewComponent}
    </Card>
  );
}

export default StepSending;
