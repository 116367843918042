import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import AlertTitle from "@mui/material/AlertTitle";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";

// Libs
import moment from "moment";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Notify
import toast from "react-hot-toast";

// Service
import AdminService from "src/service/Auth";

// ** Context
import { useAuth } from "src/hooks/useAuth";

function Session(props) {
  let { data } = props;

  // States
  let [sessions, setSessions] = useState([]);

  // Hooks
  useEffect(() => {
    AdminService.adminGetSession(data._id).then((r) => setSessions(r.data));
  }, []);

  let auth = useAuth();
  let user = auth.user;
  let isAdmin = auth.isAdmin;

  // Calculate
  let showResetBtn = isAdmin || user._id == data._id;

  // Handlers
  const resetSessionHandler = () => {
    AdminService.adminResetSession(data._id).then(() => {
      toast.success("Все сессии сброшены!");
    });
  };

  return (
    <Card>
      <CardHeader
        action={
          showResetBtn ? (
            <Button
              onClick={resetSessionHandler}
              startIcon={<Icon icon="mdi:trash-outline" />}
              variant="outlined"
              color="error"
            >
              Сбросить все
            </Button>
          ) : undefined
        }
        title="Активные сессии"
      />

      <Divider sx={{ m: "0 !important" }} />

      <TableContainer>
        <Table sx={{ minWidth: 500 }}>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? "grey.50"
                  : "background.default",
            }}
          >
            <TableRow>
              <TableCell>Браузер</TableCell>
              <TableCell>Устройство</TableCell>
              <TableCell>Локация</TableCell>
              <TableCell>Последняя активность</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sessions.map((item, index) => {
              let location = "Неизвестно";

              if (item.country) {
                location = item.country;
              }
              if (item.city) {
                location += `(${item.city})`;
              }

              let recentActivity = moment(item.recentActivity).format(
                "DD.MM.YYYY, в HH:mm"
              );

              return (
                <TableRow
                  hover
                  key={index}
                  sx={{ "&:last-of-type td": { border: 0 } }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{ ml: 2, fontWeight: 500, fontSize: "0.875rem" }}
                      >
                        {item.browser}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{item.device}</TableCell>
                  <TableCell>{location}</TableCell>
                  <TableCell>{recentActivity}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default Session;
