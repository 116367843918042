export default function (currencies, withBalance = false) {
  let result = [];

  currencies.forEach((c) => {
    let hasInResult = result.find((r) => c.code == r.code);

    if (c.isNet && hasInResult) {
      return;
    }
    result.push(c);
  });

//   if(withBalance)

//   balance: {
//     type: Number,
//     default: 0,
//     min: [-1, "Баланс не может быть отрицательным!"],
//   },
//   disabledBalance: {
//     type: Number,
//     default: 0,
//     min: [-1, "Баланс не может быть отрицательным!"],
//   },

  return result;
}
