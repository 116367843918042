// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import Avatar from "@mui/material/Avatar";

// Config
import ConfigApi from "src/configs/api";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Icon Imports
import Icon from "src/@core/components/icon";

import { Link } from "react-router-dom";

// Libs
import moment from "moment";

// Helpers
import cardNumberAddSpaces from "src/helpers/cardNumberAddSpaces";

// Modules
import CardChip from "../CardChip";
import CardFromMobile from "../CardFromMobile";
import CardCurrency from "../CardCurrency";

// Configs
import WEBSITES from "src/configs/websites";

function Info(props) {
  let { handleClose, card, style = {} } = props;

  // Calc
  let website = WEBSITES.find((s) => s.id == card?.sc);

  let currencyComponent = null;

  let currency = card.currency;

  if (currency) {
    currencyComponent = <CardCurrency currency={currency} />;
  }

  let comment = card?.comment?.admin;

  return (
    <Box sx={{ ...style }}>
      <CardContent
        sx={{ py: 3, px: 4, display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </CardContent>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">
            {cardNumberAddSpaces(card.number)}
          </Typography>
          <CardChip status={card.status} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <InfoRaw iconName="icon-park-outline:edit-name" text={card.name} />
          <InfoRaw
            iconName="ic:outline-email"
            text={card.client.email}
            link={`/clients/${card.client._id}`}
          />
          <InfoRaw iconName="dashicons:admin-site-alt3" text={website.name} />
          <InfoRaw
            iconName="ion:time-outline"
            text={moment(card.createdAt).format("DD MMMM YYYY, в HH:mm")}
          />
          {comment && <InfoRaw iconName="ic:outline-comment" text={comment} />}
        </Box>
        {currencyComponent}
        {card.isMobile && <CardFromMobile style={{ mt: 3 }} />}

        <div
          class="step-content"
          v-if="card.info && card.info.currency && isVerification"
        ></div>
      </CardContent>
    </Box>
  );
}

{
  /* <Box sx={{ mx: 4 }}>
        
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
            mb: 1,
          }}
        >
          <CurrencyInfo
            imageSize={20}
            textVariant="body1"
            currency={card.currency}
          />
          <Tooltip title={card.archived ? "Разархивировать" : "Заархивировать"}>
            <IconButton onClick={toggleArchivedHandler} color={"primary"}>
              <Icon
                icon={card.archived ? "ph:eye-bold" : "ph:eye-slash-bold"}
              />
            </IconButton>
          </Tooltip>
        </Box>


        <InfoRaw
          iconName=""
          text={website.name}
          style={{ mb: 2 }}
          iconSize={22}
          variantText="body1"
        />
         
      </Box> */
}

function InfoRaw(props) {
  let { style, textColor, link, iconName, variantText = "", text } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon={iconName} />
      <Typography
        color={link ? "primary" : textColor}
        component={link ? Link : undefined}
        to={link}
        sx={{ ml: 1.5 }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default Info;
