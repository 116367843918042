import { useEffect, useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Service Import
import CardCommentService from "src/service/CardComment";

// ** Toaster Import
import toast from "react-hot-toast";

// ** Card Comment Dialog Import
import CardCommentDialog from "./CardCommentDialog";

const TableBasic = () => {
  // ** States
  const [list, setList] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // ** Handlers
  const handleDialogToggle = () => {
    setShowDialog(!showDialog);
  };
  function addItem(item) {
    setList([item, ...list]);
  }
  function changeItem(item) {
    setList(list.map((i) => (i._id == item._id ? item : i)));
  }
  function deleteItem(id) {
    setList(list.filter((i) => i._id !== id));
  }
  function openChangeDialog(data) {
    setSelectedItem(data);
    setShowDialog(true);
  }

  // ** Fetches
  function fetchList() {
    CardCommentService.getAll().then((r) => setList(r.data));
  }
  function fetchDeleteItem(id) {
    CardCommentService.delete(id).then(() => {
      toast.success("Комментарий удален");
      deleteItem(id);
    });
  }

  // ** Columns
  const columns = [
    {
      field: "admin",
      flex: 0.3,
      headerName: "CRM",
      minWidth: 200,
    },
    {
      flex: 0.3,
      field: "rus",
      headerName: "Клиент (РУС)",
      minWidth: 200,
    },
    {
      flex: 0.3,
      field: "eng",
      headerName: "Клиент (ENG)",
      minWidth: 200,
    },
    {
      flex: 0.1,
      field: "actions",
      headerName: "Действие",
      minWidth: 100,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => openChangeDialog(row)}>
            <Icon icon="mdi:pencil-outline" />
          </IconButton>
          <IconButton onClick={() => fetchDeleteItem(row.id)}>
            <Icon icon="mdi:delete-outline" />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    fetchList();
  }, []);

  if (!list) return null;

  let rows = list.map((r) => Object.assign({}, r, { id: r._id }));

  return (
    <Card>
      <CardCommentDialog
        handleDialogToggle={handleDialogToggle}
        open={showDialog}
        addItem={addItem}
        changeItem={changeItem}
        data={selectedItem}
      />
      <CardHeader
        title="Комментарии к карте"
        action={
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setSelectedItem(null);
                handleDialogToggle();
              }}
            >
              Добавить
            </Button>
          </div>
        }
      />
      <Box sx={{ height: 500 }}>
        <DataGrid
          components={{
            noRowsOverlay: <p>wd</p>,
          }}
          hideFooterPagination
          columns={columns}
          rows={rows}
        />
      </Box>
    </Card>
  );
};

export default TableBasic;
