import React, { useEffect, useState } from "react";
// ** MUI Imports
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { CircularProgress, Divider } from "@mui/material";

import { styled } from "@mui/material/styles";

//Router
import { useSearchParams } from "react-router-dom";

// ** MUI Imports
// import Box from '@mui/material/Box'
import Card from "@mui/material/Card";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import CardHeader from "@mui/material/CardHeader";
// import Typography from '@mui/material/Typography'
import CardContent from "@mui/material/CardContent";
import StepContent from "@mui/material/StepContent";

// ** Third Party Imports
import clsx from "clsx";
import toast from "react-hot-toast";

// ** Custom Components Imports
// import StepperCustomDot from "./StepperCustomDot";
// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

// ** Styled Components
import StepperWrapper from "src/@core/styles/mui/stepper";

// ** Icon Imports
import Icon from "src/@core/components/icon";

import { socket } from "src/socket";

// Services
import RewardService from "src/service/Reward";

// Modules
import RewardInfo from "./Info";
import RewardFields from "./RewardFields";
import RewardReject from "./Reject";
import RewardResolve from "./Resolve";
import ResolveTable from "./ResolveTable";

function OrderDrawer() {
  // Hooks Router
  const [searchParams, setSearchParams] = useSearchParams();
  const rewardId = searchParams.get("rewardId");

  // States
  const [loading, setLoading] = useState(true);
  const [reward, setReward] = useState({});

  // Hooks
  useEffect(() => {
    if (!rewardId) return;
    setLoading(true);
    RewardService.getOne(rewardId).then((r) => {
      setReward(r.data);
      setLoading(false);
    });
  }, [rewardId]);

  // Handler
  const handleClose = () => {
    searchParams.delete("rewardId");
    setSearchParams(searchParams);
  };

  // Calc

  let hasData = !loading && reward;

  return (
    <Drawer
      open={!!rewardId}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: false }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 500 } } }}
    >
      {hasData ? (
        <DrawerContent data={reward} handleClose={handleClose} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Drawer>
  );
}

function DrawerContent(props) {
  let { data, handleClose } = props;
  let [reward, setReward] = useState(data);
  let [view, setView] = useState("");

  useEffect(() => {
    socket.on(`update_reward`, (upd) => {
      if (upd._id == reward._id) {
        setReward((o) => Object.assign({}, o, upd));
      }
    });
    return () => {
      socket.off(`update_reward`);
    };
  }, []);

  // `${reward._id}`

  let viewContent = (
    <Grid sx={{ mt: 0 }} container spacing={4}>
      <Grid item xs={6}>
        <Button
          onClick={() => setView("resolve")}
          fullWidth
          variant="contained"
        >
          Принять
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={() => setView("reject")}
          fullWidth
          variant="outlined"
          color="error"
        >
          Отклонить
        </Button>
      </Grid>
    </Grid>
  );

  if (view == "reject") {
    viewContent = (
      <RewardReject reward={reward} handleClose={() => setView("")} />
    );
  } else if (view == "resolve") {
    viewContent = (
      <RewardResolve reward={reward} handleClose={() => setView("")} />
    );
  }

  if (reward.status !== 101) {
    viewContent = null;
  }

  return (
    <Box>
      <Box
        sx={{
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Box>
      <RewardInfo reward={reward} />
      <Divider sx={{ mt: "0px !important" }} />
      <CardContent>
        {reward.status == 103 && (
          <Typography variant="body1" sx={{ mb: 2 }}>
            Комментарий: {reward.comment}
          </Typography>
        )}

        {(reward.status == 101 || reward.status == 102) && (
          <RewardFields sum={reward.sum} list={reward.fields} />
        )}

        {reward.status == 102 && <ResolveTable list={reward.payment} />}

        {viewContent}
      </CardContent>
    </Box>
  );
}

export default OrderDrawer;
