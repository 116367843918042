import { useState, useEffect } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

// Socket
import { socket } from "src/socket";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";

// Modules
import CardControl from "../../Cards/CardDrawer/Control";

const Avatar = styled(CustomAvatar)(({ theme }) => ({
  width: 35,
  height: 35,
  //   marginRight: theme.spacing(4),
}));

function CardStep(props) {
  let { style, card: cardData } = props;

  let [card, setCard] = useState(cardData);

  // Hooks
  useEffect(() => {
    socket.on(`card_upd_${card._id}`, (c) => {
      setCard((card) => {
        return Object.assign({}, card, c);
      });
    });
    return () => {
      socket.off(`card_upd_${card._id}`);
    };
  }, []);


  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, ...style }}>
      <CardControl isOrder card={card} />
    </Card>
  );
}

// function CardCurrency() {
//   return (
//     <Box sx={{ display: "flex", alignItems: "center" }}>
//       <Avatar skin="light" color="primary" variant="rounded">

//       </Avatar>
//       <Typography sx={{ ml: 2 }} variant="body1">
//         Cбербанк
//       </Typography>
//     </Box>
//   );
// }

export default CardStep;
