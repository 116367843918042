import { Fragment, useEffect, useState } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Toast
import { toast } from "react-hot-toast";
// Services
import OrderService from "src/service/Order";

function FinishStep(props) {
  let { style, order } = props;

  // States
  let [restoreSubmitting, setRestoreSubmitting] = useState(false);

  // Calc
  let isResolved = order.status == 105;
  let isRejected = order.status == 106;

  let message = "";
  let comment = order?.comment?.result;

  if (isResolved) {
    message = "Hermes выполнил все обязательства по переводу.";
  } else if (isRejected) {
    message = comment || "Hermes не получил нужную сумму в течение 15 минут.";
  }

  // Handlers
  const restoreOrderHandler = () => {
    setRestoreSubmitting(true);
    OrderService.restore(order._id)
      .then(() => {
        toast.success("Заявка восстановлена!");
      })
      .finally(() => {
        setRestoreSubmitting(false);
      });
  };

  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, ...style }}>
      <CardContent sx={{ p: 3 }}>
        <Typography variant="body1">{message}</Typography>
      </CardContent>
      {isRejected && (
        <Fragment>
          <Divider sx={{ my: "0px !important" }} />
          <CardContent sx={{ p: 3 }}>
            <Grid justifyContent="flex-end" container spacing={4}>
              <Grid item xs={6}>
                <Button
                  onClick={restoreOrderHandler}
                  fullWidth
                  sx={{ fontSize: "0.8rem" }}
                  variant="outlined"
                  color="primary"
                  disabled={restoreSubmitting}
                  startIcon={restoreSubmitting ? <CircularProgress /> : null}
                >
                  Восстановить заявку
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Fragment>
      )}
    </Card>
  );
}



export default FinishStep;
