// ** MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function PageWrap(props) {
  let { children, title } = props;

  return (
    <Box>
      <Typography sx={{ mb: 4 }} variant="h5">
        {title}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
}

export default PageWrap;
