import { useState, useEffect } from "react";

// Mui
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// Validate
import InputMask from "react-input-mask";

// ** Third Party Imports
import { useForm, Controller } from "react-hook-form";

// Services
import RolePermissionService from "src/service/RolePermission";
import AdminService from "src/service/Auth";
import { toast } from "react-hot-toast";

const roleList = [
  {
    name: "Администратор",
    value: "administrator",
  },
  {
    name: "Оператор",
    value: "operator",
  },
];

function ChangeModal(props) {
  let { open, handleClose, data, updateHandler } = props;

  // States
  const [isSubmiting, setSubmitting] = useState(false);
  const [role, setRole] = useState(roleList[0].value);
  const [permissionList, setPermissionList] = useState([]);
  const [permission, setPermission] = useState("");

  // Hooks

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
    },
    mode: "onChange",
  });

  // Hooks
  useEffect(() => {
    if (!open) return;

    setValue("email", data.email);
    setValue("name", data.name);
    setValue("phone", data.phone);
    setRole(data.role);
    let permissionId = data?.permission?._id;
    if (permissionId) {
      setPermission(permissionId);
    }

    RolePermissionService.getAll().then((r) => {
      let list = r.data.map((r) => {
        return {
          name: r.name,
          value: r._id,
        };
      });
      setPermissionList(list);
    });
  }, [open]);

  // Handlers
  const onSubmit = handleSubmit((d) => {
    let fetchObj = Object.assign({}, d, {
      _id: data._id,
      phone: d.phone.replace(/\D/g, ""),
      role,
      permission: role == "operator" ? permission : undefined,
    });

    setSubmitting(true);
    AdminService.changeAdmin(fetchObj)
      .then((r) => {
        let upd = r.data;
        updateHandler(upd);
        toast.success("Оператор изменен!");
        closeHandler();
      })
      .finally(() => {
        setSubmitting(false);
      });
    // AdminService
  });
  const closeHandler = () => {
    reset();
    handleClose();
  };

  //role permission email name phone(notify)
  // -pqsswordi

  return (
    <Box>
      <Dialog maxWidth="xs" open={open} onClose={closeHandler}>
        <DialogTitle textAlign="center">Изменить оператора</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <FormControl sx={{ mb: 4, mt: 2 }} fullWidth>
              <InputLabel>Должность</InputLabel>
              <Select
                value={role}
                label="Должность"
                onChange={(e) => setRole(e.target.value)}
              >
                {roleList.map((r) => {
                  return (
                    <MenuItem key={r.value} value={r.value}>
                      {r.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {role == "operator" && (
              <FormControl sx={{ mb: 4 }} fullWidth>
                <InputLabel>Роль</InputLabel>
                <Select
                  value={permission}
                  label="Роль"
                  onChange={(e) => setPermission(e.target.value)}
                >
                  {permissionList.map((r) => {
                    return (
                      <MenuItem key={r.value} value={r.value}>
                        {r.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <Controller
              name={"email"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoFocus
                  fullWidth
                  placeholder="test@example.com"
                  id="email"
                  type="email"
                  label="Email"
                  sx={{ mb: 4 }}
                  error={Boolean(errors.email?.message)}
                  {...register("email", {
                    required: "Введите Email!",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Некорректный Email!",
                    },
                  })}
                  helperText={errors.email?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  placeholder="Ivan Ivanov"
                  fullWidth
                  id="name"
                  type="text"
                  label="Имя"
                  sx={{ mb: 4 }}
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Введите Имя!",
                  })}
                  helperText={errors.name?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Controller
              name={"phone"}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputMask
                    error={Boolean(errors.phone?.message)}
                    helperText={
                      errors.phone?.message ||
                      "Если номер будет изменен, потребуется повторная подписка на Telegram Bot"
                    }
                    {...register("phone", {
                      required: "Номер телефона не может быть пустым",
                      validate: (val) => {
                        let ph = val.replace(/\D/g, "");
                        let rval =
                          ph.length >= 11 || "Некорректный номер телефона";
                        return rval;
                      },
                    })}
                    sx={{ mb: 4 }}
                    fullWidth
                    label="Номер телефона"
                    mask="+9 (999) 999-99-99"
                    value={value}
                    onChange={onChange}
                    name="phone"
                    type="text"
                  >
                    {(e) => <TextField {...e} />}
                  </InputMask>
                );
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
                size="large"
                type="submit"
                variant="contained"
                sx={{ mr: 1 }}
              >
                Сохранить
              </Button>
              <Button
                sx={{ ml: 1 }}
                type="reset"
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ChangeModal;
