import axios from "axios";

class TechnicalBreak {
  getAll() {
    return axios.get("/tech-break");
  }
  changeAll(data) {
    return axios.put("/tech-break", data);
  }
  change(id, data) {
    return axios.put(`/tech-break/${id}`, data);
  }
}

export default new TechnicalBreak();
