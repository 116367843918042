import { useState, useEffect } from "react";
import { CardContent, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Custom componenents
import CustomChip from "src/@core/components/mui/chip";
import CustomAvatar from "src/@core/components/mui/avatar";
// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";
//

// Utils
import moment from "moment";

// Store
import { observer } from "mobx-react-lite";

// Services
import SiteCurrencyService from "src/service/SiteCurrency";

// ** Icon Imports
import Icon from "src/@core/components/icon";

//Toast
import { toast } from "react-hot-toast";
// Socket
import { socket } from "src/socket";

// Emitter
import emitter from "src/EventEmitter";

// import Grid from "@mui/material/Grid";

// Config
import ConfigApi from "src/configs/api";

function CurrencyCard(props) {
  let { d } = props;
  let [data, setData] = useState(d);
  let [rsv, setRsv] = useState(d.rsv);

  useEffect(() => {
    socket.on(`site_currency_upd_${data._id}`, (d) => {
      setRsv(d.rsv);
      setData(d);
    });
    return () => {
      socket.off(`site_currency_upd_${data._id}`);
    };
  }, []);

  // `

  // API
  const changeCurrencySaveHandler = (upd) => {
    let fetchObj = {
      _id: data._id,
      ...upd,
    };
    SiteCurrencyService.changeSiteCurrency(fetchObj).then((r) =>
      toast.success("Валюта изменена!")
    );
  };

  const saveRsvHanler = () => {
    if (rsv < 0) {
      emitter.emit("snackbar", {
        open: true,
        message: "Резерв не может быть отрицательным!",
        severity: "error",
      });
      return;
    }
    changeCurrencySaveHandler({ rsv: +rsv });
  };

  // Calc
  let main = data.cur;
  let isChangeReserve = +data.rsv !== +rsv;



  return (
    <Card {...props}>
      <CardContent>
        <Box sx={{ display: "flex" }}>
          <CustomAvatar
            skin="light"
            variant="rounded"
            sx={{ width: 46, height: 46 }}
          >
            <Avatar
              src={ConfigApi.PUBLIC_IMAGES + main.image}
              alt={main.name}
              sx={{ height: 36, width: 36 }}
            />
          </CustomAvatar>
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="body2">{main.name}</Typography>
              <Typography color="secondary" variant="body2">
                {main.code}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <CustomChip
                skin="light"
                size="small"
                color="primary"
                label={getPriceCurrentFiat(data.val, main.precision)}
                sx={{ height: 20, fontSize: "0.75rem", fontWeight: 500, mb: 1 }}
              />
              <Typography color="secondary" variant="caption">
                {moment(data.updatedAt).format("DD.MM, HH:mm:ss")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            alignItems: "center ",
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <TextField
              size="small"
              sx={{
                flexGrow: 1,
                "& .MuiInputBase-root": {
                  pr: 0,
                },
              }}
              label="Резерв"
              type="number"
              value={rsv}
              onChange={(e) => setRsv(e.target.value)}
              placeholder="Резерв"
              InputProps={{
                endAdornment: (
                  <>
                    {isChangeReserve && (
                      <InputAdornment onClick={saveRsvHanler} position="end">
                        <IconButton sx={{ borderRadius: 0 }}>
                          <Icon icon="mingcute:save-line" />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default observer(CurrencyCard);
