import { useState, useEffect } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// Router
import { useNavigate, Link } from "react-router-dom";

// ** Icon
import Icon from "src/@core/components/icon";

// Date
import moment from "moment";

// Config
import ConfigApi from "src/configs/api";

// Helpers
import cardNumberAddSpaces from "src/helpers/cardNumberAddSpaces";

// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";
import CardCurrencyInfo from "src/components/Cards/CurrencyInfo";
import InfoRaw from "src/components/InfoRaw";

// Constants
import CONSTANTS from "src/constants/index";

// Configs
import WEBSITES from "src/configs/websites";
import { Tooltip } from "@mui/material";

// Toast
import { toast } from "react-hot-toast";

// Services
import CardService from "src/service/Card";

// Modules
import CardChip from "../CardChip";

import { socket } from "src/socket";

function CreditCard(props) {
  let { data } = props;
  let [card, setCard] = useState(data);
  let [showArchived, setShowArchived] = useState(false);
  // State
  // Router
  const navigate = useNavigate();

  // Hooks
  useEffect(() => {
    socket.on(`card_upd_${data._id}`, (c) => {
      setCard((card) => {
        return Object.assign({}, card, c);
      });
    });
    return () => {
      socket.off(`card_upd_${data._id}`);
    };
  }, []);

  // Handlers
  const archivedHandler = (event) => {
    event.stopPropagation();
    let archived = !card.archived;
    CardService.archive(card._id).then(() => {
      toast.success(
        `Карта ${archived ? "заархивирована" : "разархивирована"}!`
      );
    });
  };

  let infoList = [
    {
      icon: "mingcute:bank-line",
      alt: "Банк",
      name: card?.currency?.name || "Не установлено",
    },
    {
      icon: "icon-park-outline:edit-name",
      alt: "Имя",
      name: card.name,
    },
    {
      icon: "ic:outline-email",
      alt: "Email",
      name: card?.client?.email || "",
    },
  ];

  return (
    <Card
      onClick={() => {
        navigate(`?cardId=${card._id}`);
      }}
      onMouseOver={() => setShowArchived(true)}
      onMouseOut={() => setShowArchived(false)}
    >
      <CardActionArea>
        <CardContent
          sx={{
            px: 3,
            py: 2,
            pb: "8px !important",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }} variant="body2">
              {cardNumberAddSpaces(card.number)}
            </Typography>

            <CardChip
              style={{ fontSize: "0.8rem" }}
              size="small"
              status={card.status}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
              <Icon fontSize={18} icon="dashicons:admin-site-alt3" />
              <Typography sx={{ ml: 1.5 }} variant="body2">
                {WEBSITES.find((s) => s.id === card.sc)?.name}
              </Typography>
            </Box>
            {showArchived && (
              <IconButton
                onClick={archivedHandler}
                sx={{ p: 1, borderRadius: 1 }}
              >
                <Icon
                  fontSize={18}
                  icon={card.archived ? "carbon:view-off" : "carbon:view"}
                />
              </IconButton>
            )}
          </Box>
          <Info list={infoList} />

          <Typography
            sx={{
              alignSelf: "flex-end",
            }}
            variant="caption"
          >
            {moment(card.createdAt).format("YYYY.MM.DD HH:mm")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Info(props) {
  let { list } = props;

  return (
    <Box sx={{ mb: 2.5, color: "secondary" }}>
      {list.map((i) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
            <Icon fontSize={18} icon={i.icon} />
            <Typography sx={{ ml: 1.5 }} variant="body2">
              {i.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default CreditCard;
