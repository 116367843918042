import { useState } from "react";
// Mui
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import IconButton from "@mui/material/IconButton";

// ** Custom Components
import RewardChip from "../RewardChip";
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";
import ConfigApi from "src/configs/api";

// Libs
import moment from "moment";

import { Link } from "react-router-dom";

function RewardInfo(props) {
  let { style = {}, reward } = props;
  // Calc
  let sc = reward.sc;
  let status = reward.status;
  let isReferral = reward.type !== "cashback";

  let name = isReferral ? "Реф. программа" : "Кэшбек";

  return (
    <CardContent sx={{ ...style }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{name}</Typography>
        <RewardChip status={status} />
      </Box>

      <Box sx={{ mt: 3 }}>
        <InfoRaw
          iconName="dashicons:admin-site-alt3"
          text={WEBSITES.find((s) => s.id === sc)?.name}
        />
        <InfoRaw
          iconName="mdi:user-circle-outline"
          text={reward.client.email}
          link={`/clients/${reward.client._id}`}
        />
        <InfoRaw
          iconName="ion:time-outline"
          text={moment(reward.createdAt).format("DD MMMM YYYY, в HH:mm")}
        />
        <CurrencyInfo
          image={reward.currency.image}
          name={reward.currency.name}
        />
      </Box>
    </CardContent>
  );
}

function InfoRaw(props) {
  let { style, textColor, link, iconName, variantText = "", text } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon={iconName} />
      <Typography
        color={link ? "primary" : textColor}
        component={link ? Link : undefined}
        to={link}
        sx={{ ml: 1.5 }}
      >
        {text}
      </Typography>
    </Box>
  );
}

function CurrencyInfo(props) {
  let { name, image } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 38, height: 38, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image}
          alt={name}
          sx={{ height: 30, width: 30 }}
        />
      </CustomAvatar>
      <Typography variant="body2">{name}</Typography>
    </Box>
  );
}

export default RewardInfo;
