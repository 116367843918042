import { useState, useEffect } from "react";

// ** Icon Imports
import Icon from "src/@core/components/icon";
// Mui
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Components
import DialogAddCurrency from "./DialogAddCurrency";
import CurrenciesList from "./CurrenciesList";

import combineCurrencyNets from "src/helpers/combineCurrencyNets";

// Services
import CurrenciesService from "src/service/Currencies";

// Socket
import { socket } from "src/socket";

// vars

const TypeFilterList = [
  {
    value: "",
    label: "Все",
  },
  {
    value: "fiat",
    label: "Фиат",
  },

  {
    value: "crypto",
    label: "Криптовалюта",
  },
];

function CurrencyPool({ hideAddBtn, withTransition = false }) {
  let [showAddCurrency, setShowAddCurrency] = useState(false);
  let [currencies, setCurrencies] = useState([]);
  let [search, setSearch] = useState("");
  let [currencyType, setCurrencyType] = useState("");

  useEffect(() => {
    CurrenciesService.getList({}).then((r) => setCurrencies(r.data));
    // socket_currency_add
    socket.on("currency_add", (c) => setCurrencies((list) => [c, ...list]));
    socket.on("currency_item_part_upd", (upd) => {
      setCurrencies((list) =>
        list.map((c) => (c._id == upd._id ? Object.assign({}, c, upd) : c))
      );
    });

    return () => {
      socket.off("currency_add");
      socket.off("currency_item_part_upd");
    };
  }, []);

  let filteredCurrencies = search
    ? currencies.filter(
        (c) =>
          c.name.toLowerCase().includes(search.toLowerCase()) ||
          c.code.toLowerCase().includes(search.toLowerCase())
      )
    : combineCurrencyNets(currencies);

  filteredCurrencies = filteredCurrencies.filter((c) => {
    if (!currencyType) return true;

    if (c.isCrypto && currencyType == "crypto") {
      return true;
    } else if (!c.isCrypto && currencyType == "fiat") {
      return true;
    } else {
      return false;
    }
  });

  // hideAddBtn

  let addBtn = null;

  if (!hideAddBtn) {
    addBtn = (
      <Fab
        color="primary"
        variant="extended"
        size="medium"
        sx={{ "& svg": { mr: 1 } }}
        onClick={() => setShowAddCurrency(!showAddCurrency)}
      >
        <Icon icon="mdi:plus" />
        Добавить
      </Fab>
    );
  }

  return (
    <Card>
      <DialogAddCurrency show={showAddCurrency} setShow={setShowAddCurrency} />

      <CardHeader
        title={"Пул валют"}
        {...{
          action: addBtn,
        }}
      />
      <CardContent>
        <Grid sx={{ my: 2 }} container spacing={6}>
          <Grid item sm={6} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="site-select">Тип</InputLabel>
              <Select
                fullWidth
                size="small"
                value={currencyType}
                id=""
                label="Тип"
                labelId="site-select"
                onChange={(e) => setCurrencyType(e.target.value)}
                inputProps={{ placeholder: "Выберите тип" }}
              >
                {TypeFilterList.map((s) => {
                  return (
                    <MenuItem key={s.value} value={s.value}>
                      {s.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              size="small"
              fullWidth
              value={search}
              // sx={{ mr: 6, mb: 2 }}
              placeholder="Поиск"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CurrenciesList
        withTransition={withTransition}
        currencies={filteredCurrencies}
      />
    </Card>
  );
}

export default CurrencyPool;
