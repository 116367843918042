import { useState, useEffect } from "react";

// Mui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import OrderService from "src/service/Order";

// Toast
import toast from "react-hot-toast";

function Charge(props) {
  let { originSum, orderId, typ } = props;
  let [sum, setSum] = useState(originSum);

  let isChange = sum > 0 && +sum !== originSum;

  let name = typ == "cashback" ? "Кэшбек" : "Реф. начисление";

  // handlers
  const saveHandler = () => {
    OrderService.changeCharge(orderId, {
      sum,
      type: typ,
    }).then(() => {
      toast.success(`Сумма измененна`);
    });
  };

  let saveBtn = (
    <InputAdornment position="end">
      <IconButton onClick={saveHandler} sx={{ borderRadius: 0 }}>
        <Icon icon="mingcute:save-line" />
      </IconButton>
    </InputAdornment>
  );

  return (
    <Box>
      <TextField
        size="small"
        label={name}
        value={sum}
        sx={{
          "& .MuiInputBase-input": {
            px: 2,
            py: 1.5,
          },
          width: "150px",
          "& .MuiInputBase-root": {
            pr: 0,
          },
        }}
        type="number"
        onChange={(e) => setSum(e.target.value)}
        InputProps={{
          endAdornment: isChange ? saveBtn : undefined,
        }}
      />
    </Box>
  );
}

export default Charge;
