import { makeObservable, observable, action, computed } from "mobx";

class ServiceSettings {
  settlementСurrency = localStorage.getItem("settlementСurrency") || "USD";
  constructor() {
    makeObservable(this, {
      settlementСurrency: observable,
      setSettlementСurrency: action,
      getSettlementСurrency: computed,
    });
  }

  setSettlementСurrency(val){
    this.settlementСurrency = val;
    localStorage.setItem("settlementСurrency", val)
  }
  get getSettlementСurrency(){
    return this.settlementСurrency
  }
}

const serviceSettingsStore = new ServiceSettings();

export default serviceSettingsStore;
