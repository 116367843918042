// ** MUI Components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// Libs
import QRCode from "react-qr-code";

function ViewImage(props) {
  let { show, handleClose, value, isQrCode = false } = props;

  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={show}>
      <DialogContent sx={{maxHeight: '90vh', maxWidth: '90vw'}}>
        {isQrCode ? (
          <QRCode
            style={{ margin: "10px auto", display: "block", width: '100%' }}
            value={value}
          />
        ) : (
          <img
            src={value}
            style={{
              width: "100%",
              // height: '100%',
              borderRadius: 4,
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ViewImage;
