import Typography from "@mui/material/Typography";

function Mailing() {
  return (
    <Typography variant="h5" component="span" textAlign="center">
      Раздел в разработке
    </Typography>
  );
}

export default Mailing;
