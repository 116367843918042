import { useState, useEffect } from "react";

// Mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

// Store

// ** Icon Imports
import Icon from "src/@core/components/icon";

// socket
import { socket } from "src/socket";

// Services
import ExchangeApiSettingService from "src/service/ExchangeApiSetting";
import { toast } from "react-hot-toast";

export default function CurrencyConnectedExchanges() {
  // State
  const [currentTab, setCurrentTab] = useState("all");
  const [exchangeList, setExchangeList] = useState([]);
  const [addCurrencyShow, setAddCurrencyShow] = useState({
    show: false,
    code: "",
  });
  // const

  // Hooks
  useEffect(() => {
    ExchangeApiSettingService.getExchanges().then((r) => {
      setExchangeList(r.data);
    });

    socket.on("exc_api_settings_add", (e) => {
      setExchangeList((list) => [e, ...list]);
    });
    socket.on("exc_api_settings_delete", (id) => {
      setExchangeList((list) => list.filter((i) => i._id !== id));
    });
    socket.on("exc_api_settings_update", (upd) => {
      setExchangeList((list) => list.map((i) => (i._id == upd._id ? upd : i)));
    });
    return () => {
      socket.off("exc_api_settings_add");
      socket.off("exc_api_settings_delete");
      socket.off("exc_api_settings_update");
    };
  }, []);

  // Handlers
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const closeAddCurrencyShow = () => {
    setAddCurrencyShow(Object.assign({}, addCurrencyShow, { show: false }));
  };
  const openAddCurrencyShow = (code) => {
    setAddCurrencyShow(Object.assign({}, { show: true, code }));
  };

  // const addCurrencyModalOpen = (val) => {
  //   setAddCurrencyShow(val);
  // };

  return (
    <Card>
      <ModalAddCurrency
        open={addCurrencyShow.show}
        code={addCurrencyShow.code}
        handleClose={closeAddCurrencyShow}
      />
      <CardHeader title={"Биржевые валюты"} />
      <CardContent>
        <TabContext value={currentTab}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {[{ name: "Все", code: "all" }, ...exchangeList].map((e) => (
              <Tab value={e.code} label={e.name} />
            ))}
          </TabList>
          <TabPanel value="all">
            <MainTabPanel exchanges={exchangeList} />
          </TabPanel>
          {exchangeList.map((e) => {
            return (
              <TabPanel value={e.code}>
                <CurrencyTabPanel
                  currencies={e.currencies}
                  code={e.code}
                  setAddCurrencyShow={openAddCurrencyShow}
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </CardContent>
    </Card>
  );
}

function MainTabPanel(props) {
  let { exchanges } = props;

  //State
  let [search, setSearch] = useState("");

  const currencies = {};

  exchanges.forEach(({ name, currencies: exchangeCurrencies }) => {
    exchangeCurrencies.forEach((currency) => {
      if (!currencies[currency]) {
        currencies[currency] = { exchanges: [] };
      }

      if (!currencies[currency].exchanges.includes(name)) {
        currencies[currency].exchanges.push(name);
      }
    });
  });

  let result = Object.keys(currencies).map((code) => ({
    code,
    exchanges: currencies[code].exchanges,
  }));

  result = search
    ? result.filter((c) => c.code.toLowerCase().includes(search.toLowerCase()))
    : result;

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            value={search}
            placeholder="Поиск"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <List
        dense
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {result.map((c, i) => (
          <CurrencyCard
            key={c.code}
            _id={c.code}
            code={c.code}
            exchanges={c.exchanges}
            isMain
          />
        ))}
      </List>
    </Box>
  );
}

function CurrencyTabPanel(props) {
  let { code, setAddCurrencyShow, currencies } = props;
  let [search, setSearch] = useState("");

  let list = [...currencies];

  list = search
    ? list.filter((c) => c.toLowerCase().includes(search.toLowerCase()))
    : list;

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            value={search}
            placeholder="Поиск"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            startIcon={<Icon icon="mdi:plus" />}
            type="submit"
            onClick={() => setAddCurrencyShow(code)}
            variant="contained"
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <List
        dense
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {list.map((c, i) => (
          <CurrencyCard exchange={code} key={c} _id={c} code={c} />
        ))}
      </List>
    </Box>
  );
}

const CurrencyCard = function ({ code, exchanges, isMain, exchange }) {
  function deleteHandler() {
    ExchangeApiSettingService.exchangeDeleteCurrency({
      code,
      exchange
    }).then(() => {
      toast.success("Валюта удалена!");
    });
  }

  return (
    <ListItem>
      <ListItemText primary={code} />

      {isMain ? (
        <Typography variant="body2">{exchanges.join(", ")}</Typography>
      ) : (
        <ListItemSecondaryAction>
          <IconButton onClick={deleteHandler} edge="end">
            <Icon icon="ic:round-delete-forever" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

function ModalAddCurrency(props) {
  let { open, handleClose, code } = props;
  let [isSubmiting, setSubmiting] = useState(false);
  // State
  let [currencyList, setCurrencyList] = useState([]);
  let [loadingCodes, setLoadingCodes] = useState(false);
  let [selected, setSelected] = useState("");
  let [error, setError] = useState("");

  // Hooks
  useEffect(() => {
    if (!open) {
      setCurrencyList([]);
      setSelected("");
      setError("");
    } else {
      setLoadingCodes(true);
      ExchangeApiSettingService.getAllCurrenciesExchange(code)
        .then((r) => setCurrencyList(r.data))
        .finally(() => setLoadingCodes(false));
    }
  }, [open]);

  useEffect(() => {
    if (open && currencyList.length && !selected) {
      setSelected(currencyList[0].code);
    }
  }, [open, currencyList.length]);

  // Api

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmiting(true);
    ExchangeApiSettingService.exchangeAddCurrency({
      code,
      currency: selected,
    })
      .then(() => {
        toast.success("Валюта добавлена!");
        handleClose();
      })
      .finally(() => setSubmiting(false));
  };

  // Handlers

  // Calc
  const codes = currencyList.map((e) => e.code);

  return (
    <Box>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        <DialogTitle>Добавить валюту</DialogTitle>
        <DialogContent>
          <Collapse sx={{ mb: 2 }} in={error}>
            <Alert
              severity="error"
              action={
                <IconButton
                  size="small"
                  color="inherit"
                  aria-label="close"
                  onClick={() => setError(null)}
                >
                  <Icon icon="mdi:close" fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>

          <form onSubmit={onSubmit}>
            <Autocomplete
              loading={loadingCodes}
              loadingText="Загрузка..."
              defaultValue={codes[0]}
              options={codes}
              getOptionLabel={(option) => {
                let item = currencyList.find((c) => option === c.code);
                if (!item) return "";
                return `${item.code}`;
              }}
              onChange={(e, value) => {
                setSelected(value);
              }}
              renderInput={(params) => <TextField {...params} label="Валюта" />}
            />
            <Box
              className="demo-space-x"
              sx={{ "& > :last-child": { mr: "0 !important" } }}
            >
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
                size="large"
                type="submit"
                variant="contained"
              >
                Добавить
              </Button>
              <Button
                type="reset"
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
