import React, { useState, useEffect } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// ** Router
import { useParams } from "react-router-dom";

// ** Config Websites
import WEBSITES from "src/configs/websites";

// Utils
import toast from "react-hot-toast";

// Modules
import CustomEditor from "src/modules/Editor";
import SwitchLang from "src/modules/SwitchLang";
// Services
import ContentService from "src/service/Content";

function AgreementContent() {
  let [lang, setLang] = useState("rus");
  let [isSubmiting, setSubmiting] = useState(false);
  let [data, setData] = useState(null);

  // ** Hooks
  const params = useParams();

  const site = WEBSITES.find((s) => s.code === params.site);

  let siteCode = site.code;

  // API
  useEffect(() => {
    ContentService.get(site.id, "agreement").then((r) => {
      setData(r.data);
    });
  }, [siteCode]);

  function onSaveHandler() {
    setSubmiting(true);
    ContentService.change(data)
      .then((r) => {
        setData(r.data);
        toast.success("Данные изменены!");
      })
      .finally(() => setSubmiting(false));
  }

  // Handlers
  const changeDataHandler = (name, value) => {
    setData(Object.assign({}, data, { [name]: value }));
  };

  if (!data) return;

  return (
    <Card>
      <CardHeader title={`Соглашение (${site.name})`} />
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <SwitchLang value={lang} setValue={setLang} />
        <Button
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
          variant="contained"
          sx={{ mr: 2 }}
          onClick={onSaveHandler}
        >
          Cохранить
        </Button>
      </CardActions>
      <CardContent>
        <CustomEditor
          value={data[lang]}
          onChange={(v) => changeDataHandler(lang, v)}
        />
      </CardContent>
    </Card>
  );
}

export default AgreementContent;
