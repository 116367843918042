import React, { useState, useEffect } from "react";

// Mui
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

// ** Icon
import Icon from "src/@core/components/icon";

import { socket } from "src/socket";

// Services
import CurrencyService from "src/service/Currencies";

// Config
import ConfigApi from "src/configs/api";

function CurrencySelect(props) {
  let {
    label,
    placeholder,
    setValue,
    value,
    containerStyle = {},
    currencies = [],
    showBalance = false,
    size = "medium",
    addEmpty = false,
    emptyName = "",
  } = props;

  let currencyList = currencies;

  let currenciesLength = currencyList.length;

  if (addEmpty) {
    currencyList = [
      {
        name: emptyName,
        image: "",
        _id: "",
      },
      ...currencyList,
    ];
  }

  useEffect(() => {
    if (!value && currenciesLength) {
      setValue(currencyList[0]._id);
    }
  }, [currenciesLength, value]);

  return (
    <FormControl size={size} sx={containerStyle} fullWidth>
      <InputLabel id="currency-select">{label}</InputLabel>
      <Select
        size={size}
        fullWidth
        value={value}
        id="currency-select"
        label={label}
        labelId="currency-select"
        renderValue={(value) => {
          let currency = currencyList.find((c) => c._id == value);
          return (
            <CurrencySelectItem showBalance={showBalance} currency={currency} />
          );
        }}
        onChange={(e) => setValue(e.target.value)}
        inputProps={{ placeholder: placeholder }}
      >
        {currencyList.map((currency) => {
          return (
            <MenuItem key={currency._id} value={currency._id}>
              <CurrencySelectItem showBalance={showBalance} currency={currency} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default CurrencySelect;

function CurrencySelectItem(props) {
  let { currency, showBalance = false } = props;

  let { image, name, wallet, code } = currency;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        py: 0,
      }}
    >
      <Box sx={{ display: "flex" }}>
        {image && (
          <Avatar
            variant="square"
            src={ConfigApi.PUBLIC_IMAGES + image}
            alt={name}
            sx={{ height: 23, width: 23, mr: 3 }}
          />
        )}
        {name}
      </Box>

      {showBalance && wallet ? (
        <BalanceInfo wallet={wallet} code={code} />
      ) : null}
    </Box>
  );
}

function BalanceInfo(props) {
  let { wallet, code } = props;

  let { balance, disabledBalance } = wallet;

  return (
    <Typography variant="body2" color="text.secondary">
      {`${balance} ${disabledBalance ? ` (${disabledBalance})` : ""} ${code}`}
    </Typography>
  );
}
