import { useState } from "react";
// Mui
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// Services
import RewardService from "src/service/Reward";
import { toast } from "react-hot-toast";

// req.body.id, req.body.status, req.body.requisites, req.body.comment;

function Reject({ handleClose, reward }) {
  let [comment, setComment] = useState("");
  let [isSubmitting, setIsSubmitting] = useState(false);

  const saveHandler = () => {
    setIsSubmitting(true);
    RewardService.change({
      id: reward._id,
      status: 103,
      comment,
    }).then(() => {
      toast.success("Вывод вознаграждения отклонен!");
      setIsSubmitting(false);
    });
  };

  return (
    <Box sx={{ mt: 4 }}>
      <TextField
        label="Комментарий"
        fullWidth
        size="small"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={6}>
          <Button
            disabled={isSubmitting || !comment}
            startIcon={isSubmitting ? <CircularProgress /> : null}
            fullWidth
            onClick={saveHandler}
            variant="contained"
          >
            Отправить
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleClose}
            fullWidth
            variant="outlined"
            color="error"
          >
            Отменить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Reject;
