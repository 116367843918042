import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";
// Libs
import { CopyToClipboard } from "react-copy-to-clipboard";

// Toast
import toast from "react-hot-toast";

function CopyClipboardWrap({ children, value = '' }) {
  return (
    <>
      {children}
      <CopyToClipboard
        text={value}
        onCopy={() => {
          toast.success("Скопировано!");
        }}
      >
        <IconButton sx={{ ml: 1, p: 1, borderRadius: 1 }}>
          <Icon icon="material-symbols:content-copy-outline-rounded" />
        </IconButton>
      </CopyToClipboard>
    </>
  );
}

export default CopyClipboardWrap;
