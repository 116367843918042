import axios from "axios";

class History {
  operationsGet(params) {
    return axios.get("/history/operations", { params });
  }
  transfersGet(params) {
    return axios.get("/history/transfers", { params });
  }
  transfersCancel(id) {
    return axios.put("/history/transfers/cancel", { id });
  }
  ordersGet(params) {
    return axios.get("/history/orders", { params });
  }
  generalsGet(params) {
    return axios.get("/history/generals", { params });
  }
}

export default new History();
