// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function WalletInfo(props) {
  let { name, value } = props;

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        maxWidth: "100%",
      }}
    >
      <Typography sx={{ mr: 2 }} textTransform='uppercase' variant="body2">
        {name}:
      </Typography>
      <Typography textTransform='uppercase' noWrap variant="body1">
        {value}
      </Typography>
    </Box>
  );
}
