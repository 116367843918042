const WEBSITES = [
  {
    name: "Hermes",
    code: "hermes",
    short: "hermes",
    id: "h",
    api: "https://api.brclcreqr.space",
    color: "#2196f3",
  },
];

export default WEBSITES;
