import { useEffect, useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";


// Toast
import toast from "react-hot-toast";

// Services
import CurrencyService from "src/service/Currencies";

function CashCity({ infoId, cashCityCode }) {
  let [codes, setCodes] = useState([]);
  let [code, setCode] = useState(cashCityCode);

  useEffect(() => {
    CurrencyService.getCityCodes().then((r) => {
      setCodes(r.data);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setCode(newValue);
    CurrencyService.infoChange({
      _id: infoId,
      data: {
        cashCityCode: newValue,
      },
      action: "base",
    }).then((r) => {
     
      toast.success("Город изменен!");
    });
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="body1">Город для выдачи наличных</Typography>
        }
      />
      <CardContent>
        <Autocomplete
          value={code}
          options={codes.map((c) => c.code)}
          onChange={handleChange}
          getOptionLabel={(option) => {
            let item = codes.find((c) => option === c.code);
            return `${item?.name || ""}`;
          }}
          renderInput={(params) => <TextField {...params} label="Город" />}
        />
      </CardContent>
    </Card>
  );
}

export default CashCity;
