import { Box, Grid, TextField } from "@mui/material";
import { useState, useEffect } from "react";

// Forms
import { Controller } from "react-hook-form";

function Fields(props) {
  let { exchangeId, control, errors, register, takeFields, giveFields } = props;

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {takeFields.map((f) => {
            let { _id, name, validation } = f;

            return (
              <Controller
                key={_id}
                name={_id}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    {...register(_id, {
                      required: "Обязательное поле!",
                      // validate,
                    })}
                    error={Boolean(errors[_id]?.message)}
                    fullWidth
                    margin="normal"
                    helperText={errors[_id]?.message}
                    label={name}
                    name={_id}
                    type={"text"}
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            );
          })}
        </Grid>
        <Grid item xs={6}>
          {giveFields.map((f) => {
            let { _id, name, validation } = f;

            return (
              <Controller
                key={_id}
                name={_id}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    {...register(_id, {
                      required: "Обязательное поле!",
                      // validate,
                    })}
                    error={Boolean(errors[_id]?.message)}
                    fullWidth
                    margin="normal"
                    helperText={errors[_id]?.message}
                    label={name}
                    name={_id}
                    type={"text"}
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

// {takeFields.map((f) => {
//     let { _id, name, isCard, isAddress } = f;
//     let type = "text";
//     let validate = null;

//     if (isAddress) {
//       validate = (val) =>
//         cardNumberValidate(val) || "Некорректный номер карты";
//     }

//     let textInput = (
//       <Controller
//         key={_id}
//         name={_id}
//         control={control}
//         render={({ field: { onChange, value } }) => (
//           <TextField
//             {...register(_id, {
//               required: "Обязательное поле!",
//               validate,
//             })}
//             error={Boolean(errors[_id]?.message)}
//             fullWidth
//             margin="normal"
//             helperText={errors[_id]?.message}
//             label={name}
//             name={_id}
//             type={type}
//             variant="outlined"
//             onChange={onChange}
//             value={value}
//           />
//         )}
//       />
//     );
//     if (isCard) {
//       textInput = (
//         <Controller
//           key={_id}
//           name={_id}
//           control={control}
//           render={({ field: { onChange, value } }) => (
//             <InputMask
//               error={Boolean(errors[_id]?.message)}
//               helperText={errors[_id]?.message}
//               {...register(_id, {
//                 required: "Обязательное поле!",
//                 validate: (val) => {
//                   return (
//                     cardNumberValidate(val.replace(/\D/g, "")) ||
//                     "Некорректный номер карты"
//                   );
//                 },
//               })}
//               fullWidth
//               label={name}
//               margin="normal"
//               maskChar=" "
//               mask="9999 9999 9999 9999 99"
//               value={value}
//               onChange={onChange}
//               name={_id}
//               type="text"
//             >
//               {(e) => <TextField {...e} />}
//             </InputMask>
//           )}
//         />
//       );
//     }

//     return textInput;
//   })}

export default Fields;
