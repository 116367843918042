import { Fragment, useEffect, useState } from "react";
// Mui
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// WalletService
import WalletService from "src/service/Wallet";
import OrderService from "src/service/Order";

import { ListItemSecondaryAction } from "@mui/material";
import { toast } from "react-hot-toast";
import sortWallet from "src/helpers/wallet/sortWallet";

// Modules
import WalletSelect from "src/modules/Wallets/WalletSelect";

function ChangeIncomeRequisite(props) {
  let { orderId, sum, currency, requisiteId, isCrypto, code, handleClose } =
    props;

  let [kits, setKits] = useState([]);
  let [selectedWallet, setSelectedWallet] = useState(null);
  let [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    WalletService.getIncomeKitsList({
      sum,
      currency,
      requisiteId,
    }).then((r) => setKits(r.data));
  }, []);

  // Handlers

  const handlerSetWallet = (w) => {
    setSelectedWallet(w);
  };

  const saveHandler = () => {
    setIsSubmitting(true);
    OrderService.changeRequisite(orderId, selectedWallet._id)
      .then(() => {
        toast.success("Реквизит изменен!");
        handleClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // const changeRequisiteHandler = (req) => {
  //   if (window.confirm(`Сменить реквизит на "${req.name}"?`)) {
  //     OrderService.changeRequisite(orderId, {
  //       requisiteId: req._id,
  //     });
  //     // let orderId = this.order._id;
  //     // let requisiteId = req._id;
  //     // await axios.put("/orders/changeRequisite", { orderId, requisiteId });
  //     // this.showChangeRequisite = "";
  //   }
  // };

  return (
    <Box>
      {/* <KitsList
        code={code}
        kits={kits}
        setHandler={handlerSetWallet}
        isCrypto={isCrypto}
      /> */}
      <WalletSelect kits={kits} code={code} setHandler={handlerSetWallet}/>
      <CardContent sx={{ py: 0, px: 2 }}>
        {!!selectedWallet && (
          <SelectedItem
            isCrypto={isCrypto}
            data={selectedWallet}
            deleteHandler={() => {
              setSelectedWallet(null);
            }}
            code={code}
          />
        )}
        <Grid
          sx={{ mt: 3 }}
          justifyContent="space-between"
          container
          spacing={4}
        >
          <Grid item xs={6}>
            {!!selectedWallet && (
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="contained"
                color="primary"
                onClick={saveHandler}
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress /> : null}
              >
                Подтвердить отправление
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ fontSize: "0.8rem" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Отменить
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
}

function KitsList(props) {
  let { kits = [], isCrypto, setHandler, code } = props;

  // States
  let [openKitId, setOpenKitId] = useState("");

  // Handlers
  const kitClickHandler = (kit) => {
    setOpenKitId(openKitId == kit._id ? "" : kit._id);
    // if (isCrypto) {
    //   setHandler(kit);
    // } else {
    //   setOpenKitId(openKitId == kit._id ? "" : kit._id);
    // }
  };
  const requisiteClickHandler = (req) => {
    // if (!isCrypto) {
    //   setHandler(req);
    // }
    setHandler(req);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <List dense>
        {sortWallet(kits).map((k) => {
          return (
            <Fragment>
              <ListItem key={k._id} disablePadding>
                <ListItemButton onClick={() => kitClickHandler(k)}>
                  <ListItemText
                    primary={
                      <Typography
                        // variant="body1"
                        sx={{ color: k.enabled ? "inherit" : "text.secondary" }}
                      >
                        {k.name}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction
                    sx={{
                      mr: isCrypto ? undefined : 8,
                      color: k.enabled ? "inherit" : "text.secondary",
                    }}
                  >
                    {`${k.balance} ${code}`}
                  </ListItemSecondaryAction>

                  {!isCrypto && (
                    <Icon
                      icon={
                        openKitId === k._id
                          ? "mdi:chevron-up"
                          : "mdi:chevron-down"
                      }
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse in={openKitId === k._id} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                  {sortWallet(k.requisites).map((r) => {
                    return (
                      <ListItem key={r._id} disablePadding>
                        <ListItemButton
                          onClick={() => requisiteClickHandler(r)}
                          sx={{ pl: 8 }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  color: r.enabled
                                    ? "inherit"
                                    : "text.secondary",
                                }}
                              >
                                {r.name}
                              </Typography>
                            }
                          />
                          {!r.isCrypto && (
                            <ListItemSecondaryAction
                              sx={{
                                mr: isCrypto ? undefined : 8,
                                color: r.enabled ? "inherit" : "text.secondary",
                              }}
                            >
                              {`${r.balance} ${code}`}
                            </ListItemSecondaryAction>
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </Fragment>
          );
        })}
      </List>
    </Box>
  );
}

function SelectedItem(props) {
  let { isCrypto, data, code, deleteHandler } = props;
  let name = data.name;
  return (
    <Card sx={{ mb: 2.5 }}>
      <CardContent
        sx={{
          p: 2,
          pb: "12px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{}}>
            <Typography sx={{}} variant="body2">{`${
              isCrypto ? "Комплект" : "Реквизит"
            }: ${name}`}</Typography>
            {!isCrypto && (
              <Typography
                sx={{}}
                variant="body2"
              >{`${data.balance} ${code}`}</Typography>
            )}
          </Box>

          <IconButton
            onClick={() => deleteHandler(data._id)}
            color="error"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="ic:round-delete" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ChangeIncomeRequisite;
