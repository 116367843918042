import { useState, useEffect } from "react";
// Modules
import PageWrap from "src/components/PageWrap";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";

import moment from "moment";

// Modules
import Action from "./Modules/Action";
import ChangeBalance from "./Modules/ChangeBalance";

// ** Icon
import Icon from "src/@core/components/icon";

// Services
import HistoryService from "src/service/History";

function createData(order, action, population, size) {
  const density = population / size;

  return { order, action, population, size, density };
}

const rows = [
  createData("123321123", "IN", 1324171354, 3287263),
  createData("123321123", "CN", 1403500365, 9596961),
  createData("123321123", "IT", 60483973, 301340),
  createData("123321123", "US", 327167434, 9833520),
  createData("123321123", "CA", 37602103, 9984670),
  createData("123321123", "AU", 25475400, 7692024),
  createData("123321123", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

function Exchanges() {
  const [page, setPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  // Hooks
  useEffect(() => {
    HistoryService.ordersGet({
      page,
      rowsPerPage: rowsPerPage,
    }).then((r) => {
      let data = r.data;
      setRowsCount(data.count);
      setRows(data.rows);
    });
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card>
      <CardHeader title="Обмены" />
      <TableContainer dense component={Paper} sx={{ maxHeight: "70vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Заявка</TableCell>
              <TableCell>Действие</TableCell>
              <TableCell>Комплект</TableCell>
              <TableCell>Реквизит</TableCell>
              <TableCell>Автор</TableCell>
              <TableCell>Дата</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell>
                    <Typography variant="body1">{row.order}</Typography>
                  </TableCell>
                  <TableCell>
                    <Action history="orders" action={row.action} />
                  </TableCell>

                  <TableCell>
                    <ChangeBalance data={row.kit} />
                  </TableCell>
                  <TableCell>
                    {row.requisite && <ChangeBalance data={row.requisite} />}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{row.author}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="secondary">
                      {moment(row.createdAt).format("DD.MM.YYYY, в HH:mm:ss")}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

export default Exchanges;
