import { useEffect, useState } from "react";

import { Grid, IconButton, TextField } from "@mui/material";

// ** Icon
import Icon from "src/@core/components/icon";

function Change({ changeExchangeHandler, exchange }) {
  let [mn, setMn] = useState(exchange.limit.mn);
  let [mx, setMx] = useState(exchange.limit.mx);
  let [prc, setPrc] = useState(exchange.rate.prc);

  useEffect(() => {
    setMn(exchange.limit.mn);
    setMx(exchange.limit.mx);
    setPrc(exchange.rate.prc);
  }, [exchange?.updatedAt]);

  //Calc
  let isbn = exchange.rate.isbn;
  let isChanged =
    +exchange.limit.mn !== +mn ||
    +exchange.limit.mx !== +mx ||
    +exchange.rate.prc !== +prc;

  let takeMainCur = exchange.tk.cur;
  let giveMainCur = exchange.gv.cur;

  return (
    <Grid container columns={15} alignItems="center" spacing={4}>
      <Grid item xs={1.5} sx={{ pr: 1 }}>
        <IconButton
          sx={{
            transform: `rotate(${exchange.rate.isbn ? 0 : 180}deg)`,
            borderRadius: 0,
            p: 0.5,
            margin: "0px auto",
          }}
          onClick={() =>
            changeExchangeHandler({
              rate: {
                isbn: !isbn,
              },
            })
          }
          color={exchange.rate.isbn ? "success" : "error"}
        >
          <Icon icon="mdi:arrow-top-bold-box-outline" />
        </IconButton>
      </Grid>
      <Grid item xs={isChanged ? 4 : 4.5}>
        <TextField
          size="small"
          fullWidth
          value={prc}
          label="Процент"
          type="number"
          onChange={(e) => setPrc(e.target.value)}
        />
      </Grid>
      <Grid item xs={isChanged ? 4 : 4.5}>
        <TextField
          size="small"
          fullWidth
          value={mn}
          type="number"
          label={`Мин. ${takeMainCur.code}`}
          onChange={(e) => setMn(e.target.value)}
        />
      </Grid>
      <Grid item xs={isChanged ? 4 : 4.5}>
        <TextField
          size="small"
          fullWidth
          value={mx}
          type="number"
          label={`Макс. ${takeMainCur.code}`}
          onChange={(e) => setMx(e.target.value)}
        />
      </Grid>
      {isChanged && (
        <Grid item xs={1.5}>
          <IconButton
            onClick={() => {
              changeExchangeHandler({
                limit: {
                  mn: Math.abs(+mn),
                  mx: Math.abs(+mx),
                },
                rate: {
                  prc: Math.abs(+prc),
                },
              });
            }}
            sx={{
              margin: "0px auto",
              borderRadius: 0,
              p: 0.5,
            }}
            color="primary"
          >
            <Icon icon="mingcute:save-line" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default Change;
