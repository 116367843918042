// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

// ** Icon Imports
import Icon from "src/@core/components/icon";

function CardFromMobile({ style = {} }) {
  return (
    <CustomChip
      skin="light"
      label="С телефона"
      color="primary"
      icon={<Icon fontSize={15} icon="bi:phone" />}
      sx={{
        borderRadius: "5px",
        ...style,
      }}
    />
  );
}

export default CardFromMobile;
