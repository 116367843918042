import { useState } from "react";
// Mui
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import MuiLink from "@mui/material/Link";

// ** Custom Components
import OrderChip from "../OrderChip";
import Timer from "../Timer";
import CustomChip from "src/@core/components/mui/chip";

// Modules
import Reviews from "./Reviews";
import Charge from "./Modules/Charge";
import ChargeRefToggle from "./Modules/ChargeRefToggle";
import ClientLoyaltyChange from "src/modules/Clients/ClientLoyalty";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";

// Libs
import moment from "moment";

import { Link, useNavigate } from "react-router-dom";

import History from "./History";

function OrderInfo(props) {
  let { style = {}, order } = props;

  let [showHistory, setShowHistory] = useState(false);
  let [showReviews, setShowReviews] = useState(false);

  // Router
  const navigate = useNavigate();

  let id = order._id;

  let ipInfo = order?.info?.ip;
  let fieldsStamp = order?.info?.fieldsStamp;
  let newClient = order?.info?.newClient;
  let referrer = order?.info?.referrer;
  let reviews = order?.info?.reviews;
  let referrerClient = order?.client?.referrer;
  let adminComment = order?.comment?.admin;

  return (
    <CardContent sx={{ ...style }}>
      <History
        open={showHistory}
        handleClose={() => setShowHistory(false)}
        orderId={id}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5">{id}</Typography>
          <IconButton
            onClick={() => setShowHistory(true)}
            color="primary"
            sx={{ borderRadius: 1, ml: 3 }}
          >
            <Icon icon="material-symbols:history-edu-outline" />
          </IconButton>
        </Box>

        <Box>
          {order.onCheck && <OrderChip status={"oncheck"} style={{ mr: 2 }} />}
          <OrderChip status={order.status} />
        </Box>
      </Box>
      {order.status <= 103 && (
        <Box sx={{ display: "flex", my: 3 }}>
          <Timer
            TypographyProps={{
              variant: "h6",
              sx: {
                fontWeight: 500,
              },
            }}
            finished={order.finished}
          />
        </Box>
      )}

      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", flex: "flex-wrap" }}
          >
            <InfoRaw
              iconName="mdi:user-circle-outline"
              text={order.client.email}
              style={{ mb: 0 }}
              link={`/clients/${order.client._id}`}
            />
            
            <ClientLoyaltyChange
              style={{ ml: 1 }}
              init={order?.client?.loyalty}
              clientId={order?.client?._id}
            />
          </Box>

          <Box>
            {!!order?.charge?.cashbackSum && (
              <Charge
                typ="cashback"
                orderId={order._id}
                originSum={order?.charge?.cashbackSum}
              />
            )}
          </Box>
        </Box>
        {!!referrerClient && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <InfoRaw
              iconName="material-symbols:supervised-user-circle-outline"
              text={referrerClient.email}
              style={{ mb: 0 }}
              link={`/clients/${referrerClient._id}`}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {order.status !== 105 && (
                <ChargeRefToggle
                  clientId={order.client._id}
                  initDncr={order.client.dncr}
                />
              )}

              {!!order?.charge?.referralSum && (
                <Charge
                  typ="referral"
                  orderId={order._id}
                  originSum={order?.charge?.referralSum}
                />
              )}
            </Box>
          </Box>
        )}

        <InfoRaw iconName="la:exchange-alt" text={order.rate} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <InfoRaw
            iconName="dashicons:admin-site-alt3"
            text={WEBSITES.find((s) => s.id === order.sc)?.name}
          />
          {newClient && (
            <CustomChip
              skin="light"
              size={"small"}
              label={"Новый клиент"}
              color="success"
              sx={{
                width: "min-content",
                fontSize: "1rem",
                fontWeight: 600,
                borderRadius: "5px",
              }}
            />
          )}
        </Box>
        <InfoRaw
          iconName="ion:time-outline"
          text={moment(order.createdAt).format("DD MMMM YYYY, в HH:mm")}
        />
        {ipInfo && (
          <InfoRaw iconName="fluent:location-16-regular" text={ipInfo} />
        )}
        {reviews && !!reviews.length && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoRaw iconName="octicon:code-review-16" text="Отзывы" />
              <IconButton
                sx={{ ml: 3 }}
                onClick={() => setShowReviews(!showReviews)}
              >
                <Icon icon="material-symbols:expand-more-rounded" />
              </IconButton>
            </Box>
            <Collapse in={showReviews} timeout="auto" unmountOnExit>
              <Reviews list={reviews} />
            </Collapse>
          </>
        )}
        {referrer && (
          <InfoRaw
            link={referrer}
            iconName="carbon:notebook-reference"
            text={referrer}
            external
          />
        )}
        {adminComment && order.status == 106 && (
          <InfoRaw iconName="ic:outline-comment" text={adminComment} />
        )}
        {/* <Button
        onClick={()=>{
          navigate(`?debt=new&oid=${order._id}`);
        }}
        
        size="small" variant="contained" endIcon={<Icon icon='mdi:send' />}>
          Установить задолженность
        </Button> */}
        <InfoRaw
          link={
            order.debt ? `?debt=${order.debt}` : `?debt=new&oid=${order._id}`
          }
          iconName="arcticons:debtcalc"
          text={
            order.debt ? "Посмотреть задолженность" : "Установить задолженность"
          }
        />
      </Box>
    </CardContent>
  );
}

function InfoRaw(props) {
  let { style, textColor, link, external = false, iconName, text } = props;

  let textComponent;

  if (external) {
    textComponent = (
      <MuiLink
        component={"a"}
        target="_blank"
        color="primary"
        underline="none"
        rel="noreferrer"
        variant="body1"
        href={link}
      >
        {text}
      </MuiLink>
    );
  } else {
    textComponent = (
      <Typography
        color={link ? "primary" : textColor}
        component={link ? Link : undefined}
        to={link}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon={iconName} />
      <Box sx={{ ml: 1.5 }}>{textComponent}</Box>
    </Box>
  );
}

export default OrderInfo;
