import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Fab from "@mui/material/Fab";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Services
import SystemSettingsService from "src/service/SystemSettings";

// ** Icon
import Icon from "src/@core/components/icon";

//Toast
import { toast } from "react-hot-toast";

function MainSetting() {
  // State
  let [settings, setSettings] = useState(null);

  useEffect(() => {
    SystemSettingsService.get().then((r) => setSettings(r.data));
  }, []);

  // Handlers
  const saveHandler = () => {
    SystemSettingsService.change({
      _id: settings._id,
      collectDebt: settings.collectDebt,
    }).then(() => toast.success("Настройки измененны!"));
  };

  if (!settings) return null;

  return (
    <Card>
      <CardHeader
        title="Общие"
        {...{
          action: (
            <Fab
              color="primary"
              variant="extended"
              size="medium"
              sx={{ "& svg": { mr: 1 } }}
              onClick={saveHandler}
            >
              <Icon icon="material-symbols:save-outline-sharp" />
              Сохранить
            </Fab>
          ),
        }}
      />
      <CardContent>
        <FormControlLabel
          label="Взымать задолженность"
          control={
            <Checkbox
              checked={settings.collectDebt}
              onChange={(e) =>
                setSettings(
                  Object.assign({}, settings, { collectDebt: e.target.checked })
                )
              }
              name="Взымать задолженность"
            />
          }
        />
      </CardContent>
    </Card>
  );
}

export default MainSetting;
