import { Fragment, useState } from "react";
// Mui
import {
  Typography,
  Box,
  Collapse,
  Divider,
  CardContent,
  IconButton,
  CardActionArea,
} from "@mui/material";

// Icons
import Icon from "src/@core/components/icon";

// Custom Components
import ViewImage from "src/components/ViewImage";

// Libs
import QRCode from "react-qr-code";

function AddressQr({ value }) {
  // State
  let [show, setShow] = useState(false);
  let [showImage, setShowImage] = useState(false);

console.log({value})

  return (
    <Fragment>
      <ViewImage
        show={showImage}
        handleClose={() => setShowImage(false)}
        isQrCode
        value={value}
      />
      <CardContent sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">QR для перевода:</Typography>
          <IconButton sx={{ p: 1 }} onClick={() => setShow(!show)}>
            <Icon icon={show ? "mdi:chevron-up" : "mdi:chevron-down"} />
          </IconButton>
        </Box>

        <Collapse in={show} timeout="auto" unmountOnExit>
          <Box>
            <CardActionArea sx={{margin: "10px auto", width: 'auto'}} onClick={() => setShowImage(value)}>
              <QRCode
                style={{  display: "block" }}
                value={value}
              />
            </CardActionArea>
          </Box>
        </Collapse>
      </CardContent>
    </Fragment>
  );
}

export default AddressQr;
