// Mui
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Reviews({ list }) {
  return (
    <Box>
      {list.map((r, i) => {
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">{r.author}</Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: r.desc }}
            />
            <Typography
              variant="caption"
              dangerouslySetInnerHTML={{ __html: r.contacts }}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default Reviews;
