import { useEffect } from "react";
// router
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

// ** Pages
import Login from "./pages/Login/Login";
import ConfirmLogin from "./pages/Login/ConfirmLogin";
import FAQPage from "./pages/content/FAQ";
import AgreementContent from "./pages/content/Agreement";
import NotificationContent from "./pages/content/Notification";
import ContactsContent from "./pages/content/Contacts";
import AdContent from "./pages/content/Ad";
import NewsContent from "./pages/content/News";
import MailContent from "./pages/content/Mailing";

import Cards from "./pages/cards/Cards";
import CardComments from "./pages/settings/card-comments/CardComments";
import CurrencySettings from "./pages/settings/currency";
import SystemSettings from "./pages/settings/system/SystemSettings";
import BlackList from "./pages/settings/BlackList/BlackList";
import Localization from "./pages/settings/localization/Localization";
import Rewards from "./pages/rewards/Rewards";

import Currencies from "./pages/currencies";
import Courses from "./pages/courses/Courses";
import CoursesTarget from "./pages/courses/Target";

import CoursesTable from "./pages/courses/Table";

import Clients from "./pages/clients/Clients";
import Client from "./pages/client/Client";

// Wallets
import WalletControl from "./pages/wallet/Control";
import WalletGeneral from "./pages/wallet/General";
import WalletHistoryTransfers from "./pages/wallet/History/Transfers";
import WalletHistoryExchanges from "./pages/wallet/History/Exchanges";
import WalletHistoryOperations from "./pages/wallet/History/Operations";
import Operators from "./pages/settings/operators/Operators";
import Operator from "./pages/settings/operator/Operator";

// Wallets

import Currency from "./pages/currency/Currency";
import Dashboard from "./pages/dashboard/Dashboard";

// Orders
import Orders from "./pages/orders/Orders";

// ** Layout
import UserLayout from "src/layouts/UserLayout";

// Finance
import Arrears from "src/pages/Arrears/Arrears";

export default function MainRouter() {
  const { user, loading } = useAuth();


  if (loading) return null;

  // Calc
  const isAuth = !!user;

  const getLayout = !isAuth
    ? (f) => f
    : (page) => <UserLayout>{page}</UserLayout>;

  const authed = (
    <>
      <Route path="/orders" element={<Orders />} />
      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/content/faq/:site" element={<FAQPage />} />
      <Route path="/content/agreement/:site" element={<AgreementContent />} />
      <Route
        path="/content/notification/:site"
        element={<NotificationContent />}
      />
      <Route path="/content/contacts/:site" element={<ContactsContent />} />
      <Route path="/content/ad/:site" element={<AdContent />} />
      <Route path="/content/news/:site" element={<NewsContent />} />
      <Route path="/content/mail/:site" element={<MailContent />} />

      <Route path="/settings/card-comments" element={<CardComments />} />
      <Route
        path="/settings/currency-and-rate"
        element={<CurrencySettings />}
      />
      <Route path="/settings/localization" element={<Localization />} />

      <Route path="/settings/system" element={<SystemSettings />} />
      <Route path="/settings/black-list" element={<BlackList />} />
      <Route path="/settings/operators" element={<Operators />} />
      <Route path="/settings/operators/:id" element={<Operator />} />

      {/* Wallets */}
      <Route path="/requisites/control" element={<WalletControl />} />
      <Route path="/requisites/global" element={<WalletGeneral />} />
      <Route
        path="/requisites/history/exchanges"
        element={<WalletHistoryExchanges />}
      />
      <Route
        path="/requisites/history/transfers"
        element={<WalletHistoryTransfers />}
      />
      <Route
        path="/requisites/history/operations"
        element={<WalletHistoryOperations />}
      />
      {/* Wallets */}

      {/* Currencies */}
      <Route path="/currencies/:site" element={<Currencies />} />
      <Route path="/currency/:id" element={<Currency />} />

      {/* Courses */}
      <Route path="/courses/:site" element={<Courses />} />
      <Route path="/courses/target" element={<CoursesTarget />} />

      <Route path="/courses-table" element={<CoursesTable />} />

      <Route path="/clients" element={<Clients />} />
      <Route path="/clients/:id" element={<Client />} />

      {/* cards */}
      <Route path="/cards" element={<Cards />} />

      {/* Others */}
      <Route path="/rewards" element={<Rewards />} />

      {/* Finance */}
      <Route path="/finance/arrears" element={<Arrears />} />

      <Route path="*" element={<Navigate to="/orders" replace />} />
    </>
  );

  const routes = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/confirm-login" element={<ConfirmLogin />} />
      {isAuth && authed}
    </Routes>
  );

  return getLayout(routes);
}
