const input = (theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          lineHeight: "1.5rem",
          "&:before": {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.22)`,
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.32)`,
          },
          "&.Mui-disabled:before": {
            borderBottomStyle: "solid",
          },
          // "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          //   {
          //     display: "none",
          //   },
          // "& input[type=number]": {
          //   MozAppearance: "textfield",
          // },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: `rgba(${theme.palette.customColors.main}, 0.05)`,
          "&:hover:not(.Mui-disabled)": {
            backgroundColor: `rgba(${theme.palette.customColors.main}, 0.08)`,
          },
          "&:before": {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.22)`,
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.32)`,
          },
          "&.Mui-disabled": {
            backgroundColor: `rgba(${theme.palette.customColors.main}, 0.05)`,
            "&:before": {
              borderBottomStyle: "solid",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          "&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
            {
              borderColor: `rgba(${theme.palette.customColors.main}, 0.32)`,
            },
          "&:hover.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.main,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `rgba(${theme.palette.customColors.main}, 0.22)`,
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.text.disabled,
          },
          // "&[type=number]": {
          //   "-moz-appearance": "textfield",
          // },
          // "&[type=number]::-webkit-outer-spin-button": {
          //   "-webkit-appearance": "none",
          //   margin: 0,
          // },
          // "&.[type=number]::-webkit-inner-spin-button": {
          //   "-webkit-appearance": "none",
          //   margin: 0,
          // },
        },
      },
    },
  };
};

export default input;
