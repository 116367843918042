import { useState, useEffect } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";

// ** MUI Imports
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

// ** Icon Imports
import Icon from "src/@core/components/icon";
// Form
import { useForm, Controller } from "react-hook-form";

// Toast
import toast from "react-hot-toast";

// Services
import CommissionBtcService from "src/service/CommissionBtc";

function BitcoinCommission(props) {
  // States
  const [list, setList] = useState([]);

  // hooks
  useEffect(() => {
    CommissionBtcService.getAll().then((r) => setList(r.data));
  }, []);

  // Handlers
  const addItemHandler = (item) => {
    CommissionBtcService.add().then((r) => {
      setList((list) => [...list, r.data]);
      toast.success("Комиссия добавлена!");
    });
  };

  const updateItemHandler = (upd) => {
    setList((list) => list.map((i) => (i._id == upd._id ? upd : i)));
  };

  const deleteItemHandler = (id) => {
    setList((list) => list.filter((i) => i._id !== id));
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="body1">
            Общая комиссия Валюта => Bitcoin
          </Typography>
        }
        action={
          <IconButton onClick={addItemHandler} color="primary">
            <Icon icon="material-symbols:add" />
          </IconButton>
        }
      />
      <CardContent>
        <List>
          {list.map((item) => {
            return (
              <CommissionItem
                deleteItem={deleteItemHandler}
                updateItem={updateItemHandler}
                key={item._id}
                data={item}
              />
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}

function CommissionItem(props) {
  let { data, updateItem, deleteItem } = props;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      from: data.from,
      to: data.to,
      cms: data.cms,
    },
  });

  let from = watch("from");
  let to = watch("to");
  let cms = watch("cms");

  let isChanged =
    +data.from !== +from || +data.to !== +to || +data.cms !== +cms;

  const onSubmit = handleSubmit((d) => {
    CommissionBtcService.change(data._id, d).then((r) => {
      updateItem(r.data);
      toast.success("Комиссия изменена!");
    });
  });

  const deleteItemHandler = () => {
    CommissionBtcService.delete(data._id).then((r) => {
      deleteItem(data._id);
      toast.success("Комиссия удалена!");
    });
  };

  return (
    <ListItem>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Controller
            name={"from"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="От RUB"
                placeholder="От RUB"
                size="small"
                id="from"
                type="number"
                error={Boolean(errors.from?.message)}
                {...register("from", {
                  required: "Обязательное поле!",
                })}
                helperText={errors.from?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={"to"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="До RUB"
                placeholder="До RUB"
                size="small"
                id="to"
                type="number"
                error={Boolean(errors.to?.message)}
                {...register("to", {
                  required: "Обязательное поле!",
                })}
                helperText={errors.to?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={"cms"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="Комм. BTC"
                placeholder="Комм. BTC"
                size="small"
                id="cms"
                type="number"
                error={Boolean(errors.cms?.message)}
                {...register("cms", {
                  required: "Обязательное поле!",
                })}
                helperText={errors.cms?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex" }}>
        {isChanged && (
          <IconButton
            onClick={onSubmit}
            sx={{ mx: 2 }}
            color="primary"
            edge="end"
          >
            <Icon icon="material-symbols:save-outline" />
          </IconButton>
        )}

        <IconButton onClick={deleteItemHandler} color="error" edge="end">
          <Icon icon="material-symbols:delete-forever-rounded" />
        </IconButton>
      </Box>
    </ListItem>
  );
}

export default BitcoinCommission;
