import axios from "axios";

class Content {
  get(id, destination) {
    return axios.get("/content", { params: { id, destination } });
  }
  change(data) {
    return axios.put("/content", data);
  }
  getAllArticle(id, destination) {
    return axios.get("/content/article", { params: { id, destination } });
  }
  createArticle(data) {
    return axios.post("/content/article", data);
  }
  changeArticle(data){
    return axios.put("/content/article", data);
  }
  deleteArticle(id) {
    return axios.delete("/content/article", { params: { id } });
  }
  //   add(data){
  //     return axios.post("/card-comment", data);
  //   }
  //   change(data){
  //     return axios.put("/card-comment", data);
  //   }
  //   delete(id) {
  //     return axios.delete("/card-comment", { data: { id } });
  //   }
}

export default new Content();
