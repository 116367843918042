import { useState, useEffect } from "react";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Components
import ClientService from "src/service/Client";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { toast } from "react-hot-toast";

function Accrual(props) {
  let { referral, accrual, clientId, updateData } = props;
  // States
  let [percent, setPercent] = useState(referral.percent);
  // Handlers
  const saveNewReferralPercent = () => {
    ClientService.setReferralPercent(clientId, percent).then((r) => {
      updateData({
        referral: r.data,
      });
      toast.success('Процент реферального начисления изменен!')
    });
  };

  //Cald
  let isChanged = +percent !== +referral.percent;

  let endAdornment = undefined;

  if (isChanged) {
    endAdornment = (
      <InputAdornment position="end">
        <IconButton onClick={saveNewReferralPercent} sx={{ borderRadius: 0 }}>
          <Icon icon="mingcute:save-line" />
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <CardContent sx={{ my: 1 }}>
      <Divider sx={{ mt: (theme) => `${theme.spacing(2)} !important` }} />
      <Typography sx={{ mb: 2 }} variant="subtitle1">
        Начисления
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="body2">Реф. программа </Typography>
          <Typography variant="h6" sx={{ lineHeight: 1.3, mt: 1 }}>
            {referral.balance} RUB
          </Typography>
          <TextField
            sx={{
              mt: 3,
              "& .MuiInputBase-root": {
                pr: 0,
              },
            }}
            size="small"
            label="Процент начисления"
            type="number"
            value={percent}
            onChange={(e) => setPercent(e.target.value)}
            InputProps={{
              endAdornment,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Кэшбек </Typography>
          <Typography variant="h6" sx={{ lineHeight: 1.3, mt: 1 }}>
            {`${accrual.availableBalance} (${accrual.balance}) RUB`}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default Accrual;
