import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
} from "@mui/material";

// route
import { useNavigate } from "react-router-dom";

// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";

function OperatorCard(props) {
  let { data } = props;

  // Calc
  let isAdmin = data.role == "administrator";

  // let roleName = isAdmin ? "Администратор" : "Роль";

  let roleName = "Администратор";
  if (!isAdmin) {
    let permissionName = data?.permission?.name;
    roleName = permissionName || "Оператор";
  }

  // Hooks
  const navigate = useNavigate();

  return (
    <Card>
      <CardActionArea
        onClick={() => navigate(`/settings/operators/${data._id}`)}
      >
        <CardContent sx={{ py: 3, px: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              noWrap
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                textDecoration: "none",
              }}
            >
              {data.name}
            </Typography>
            <CustomChip
              skin="light"
              size="small"
              label={data.online ? "Online" : "Offline"}
              color={data.online ? "success" : "error"}
            />
          </Box>

          <CustomChip
            skin="light"
            size="small"
            label={roleName}
            color={isAdmin ? "primary" : "warning"}
            sx={{ mt: 2 }}
          />

          <Typography sx={{ mt: 2, mb: 1 }} variant="body2" noWrap>
            {data.email}
          </Typography>
          <Typography variant="body2" noWrap>
            {`+${data.phone}`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default OperatorCard;
