import { useState, useEffect } from "react";
// Mui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

// Modules
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

// ** Icon
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";

const websites_options = WEBSITES.map((w) => {
  return {
    name: w.name,
    value: w.id,
  };
});

function LinksToConnect({ specific }) {
  let [showLinks, setShowLinks] = useState(false);

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">Ccылки для подключения</Typography>
          </Box>
          <IconButton onClick={() => setShowLinks(!showLinks)}>
            <Icon icon="material-symbols:expand-more-rounded" />
          </IconButton>
        </Box>
        <Collapse in={showLinks} timeout="auto" unmountOnExit>
          <Content specific={specific} />
        </Collapse>
      </CardContent>
    </Card>
  );
}

function Content({ specific }) {
  let [site, setSite] = useState(websites_options[0].value);

  let linkList = getLinksWithTitle(specific, site);


  return (
    <Box>
      <TabContext value={site}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => setSite(v)}
        >
          {websites_options.map((w) => (
            <Tab value={w.value} label={w.name} />
          ))}
        </TabList>
      </TabContext>
      <List dense>
        {linkList.map((l) => {
          return (
            <ListItem disablePadding>
              <ListItemText primary={l.title} secondary={l.link} />
              <ListItemSecondaryAction>
                <CopyClipboardWrap value={l.link} />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

function getLinksWithTitle(specific, sc) {
  if (specific == "advc") {
    let api = WEBSITES.find((s) => s.id == sc)?.api;
    return [
      {
        title: "Успешно (POST)",
        link: api + `/api/invoice/advanced-cash-status/success`,
      },
      {
        title: "Неудачно (POST)",
        link: api + `/api/invoice/advanced-cash-status/fail`,
      },
      {
        title: "Статус (POST)",
        link: api + `/api/invoice/advanced-cash-status/status`,
      },
    ];
  } else if (specific == "payeer") {
    let api = WEBSITES.find((s) => s.id == sc)?.api;
    return [
      {
        title: "Успешно",
        link: api + `/api/invoice/payeer-status/success`,
      },
      {
        title: "Неудачно",
        link: api + `/api/invoice/payeer-status/fail`,
      },
      {
        title: "Статус",
        link: api + `/api/invoice/payeer-status/status`,
      },
    ];
  } else {
    return [];
  }
}

export default LinksToConnect;
