import { useState, useEffect } from "react";
// Mui
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// Services
import BlacklistService from "src/service/Blacklist";
import { CardContent } from "@mui/material";

// ** Third Party Imports
import { useForm, Controller } from "react-hook-form";

// Libs
import { isIP } from "is-ip";
import { toast } from "react-hot-toast";

// TODO
// mask for email, ip
//
//
//

function AddToBlackList(props) {
  let { typesBlacklist, addItemHandler } = props;
  // States
  const [isSubmiting, setSubmiting] = useState(false);
  const [selectType, setSelectType] = useState(typesBlacklist[0].value);

  // forms
  const {
    reset,
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      ip: "",
      requisite: "",
    },
    mode: "onChange",
  });

  // Hooks
  useEffect(() => {
    reset();
  }, [selectType]);

  // Handlers
  const onSubmit = handleSubmit((data) => {
    let requestData = {
      mean: selectType,
      value: data[selectType],
    };
    setSubmiting(true);
    BlacklistService.add(requestData)
      .then((r) => {
        
        addItemHandler(r.data)
        toast.success('Добавлено в ЧС!')
      })
      .finally(() => setSubmiting(false));
  });

  // Components

  let emailComponent = (
    <Controller
      name={"email"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          autoFocus
          fullWidth
          size="small"
          placeholder="text@example.com"
          id="email"
          type="email"
          label="Email"
          sx={{ mt: 3 }}
          error={Boolean(errors.email?.message)}
          {...register("email", {
            required: "Введите Email!",
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Некорректный Email!",
            },
          })}
          helperText={errors.email?.message}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );

  let ipComponent = (
    <Controller
      name={"ip"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          autoFocus
          size="small"
          fullWidth
          placeholder="111.111.111.111"
          id="ip"
          type="text"
          label="IP"
          sx={{ mt: 3 }}
          error={Boolean(errors.ip?.message)}
          {...register("ip", {
            required: "Введите IP!",
            validate: (v) => isIP(v) || "Некорректный IP!",
          })}
          helperText={errors.ip?.message}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );

  let requisiteComponent = (
    <Controller
      name={"requisite"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          size="small"
          autoFocus
          fullWidth
          placeholder="Введите номер карты, адрес кошелька и тд."
          id="requisite"
          type="text"
          label="Значение"
          sx={{ mt: 3 }}
          error={Boolean(errors.requisite?.message)}
          {...register("requisite", {
            required: "Введите значение!",
          })}
          helperText={errors.requisite?.message}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );

  let showComponent;

  switch (selectType) {
    case "email":
      showComponent = emailComponent;
      break;
    case "ip":
      showComponent = ipComponent;
      break;
    case "requisite":
      showComponent = requisiteComponent;
      break;
  }

  return (
    <Card>
      <CardHeader
        sx={{ pb: 3.25 }}
        title="Добавить"
        subheader="Всем добавленным Email, совпадающим Ip, а также совпадающим реквизитам (Номер карты, номер телефона и тд) будет ограничен доступ"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent component="form" noValidate onSubmit={onSubmit}>
        <FormControl sx={{ mt: 4 }} size="small" fullWidth>
          <InputLabel id="blacklist-select-type">Тип</InputLabel>
          <Select
            size="small"
            fullWidth
            value={selectType}
            label="Тип"
            onChange={(e) => setSelectType(e.target.value)}
            labelId="blacklist-select-type"
          >
            {typesBlacklist.map((t) => {
              return <MenuItem value={t.value}>{t.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        {showComponent}
        <Button
          sx={{ mt: 4 }}
          fullWidth
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
          size="large"
          type="submit"
          variant="contained"
        >
          Добавить
        </Button>
      </CardContent>
    </Card>
  );
}

export default AddToBlackList;
