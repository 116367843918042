import React, { useEffect, useState } from "react";
// Router
import { useNavigate } from "react-router-dom";
// Mui
import {
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Typography,
  Avatar,
  IconButton,
  CardActionArea,
} from "@mui/material";
//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";

import { socket } from "src/socket";
// Libs
import moment from "moment";

// Config
import ConfigApi from "src/configs/api";

import { toast } from "react-hot-toast";

// Modules
import OrderChip from "../OrderChip";
import Timer from "../Timer";

import OrderService from "src/service/Order";

function OrderCard(props) {
  let { data } = props;
  let [order, setOrder] = useState(data);
  let [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    socket.on(`order_upd_${data._id}`, (upd) => {
      setOrder((o) => Object.assign(o, upd));
    });
    return () => {
      socket.off(`order_upd_${data._id}`);
    };
  }, []);

  let status = order.status;

  // Router
  const navigate = useNavigate();

  let infoList = [
    {
      icon: "la:exchange-alt",
      alt: "Курс",
      name: order.rate,
    },

    {
      icon: "mdi:user-circle-outline",
      alt: "Клиент",
      name: order.client.email,
    },
  ];

  const archivedHandler = (event) => {
    event.stopPropagation();
    let archived = order.archived;
    OrderService.changeArchive(order._id).then(() => {
      toast.success(
        `Заявка ${archived ? "разархивированна" : "заархивирована"}`
      );
    });
  };

  let archived = order.archived;

  return (
    <Card
      onClick={() => {
        navigate(`?orderId=${order._id}`);
      }}
      onMouseOver={() => setShowArchived(true)}
      onMouseOut={() => setShowArchived(false)}
    >
      <CardActionArea>
        <CardContent sx={{ py: 3, px: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "18px" }} variant="body1">
              {order._id}
            </Typography>
            <OrderChip size="small" status={status} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1.5,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mt: 1.5  }}>
              <Icon fontSize={18} icon={"dashicons:admin-site-alt3"} />
              <Typography sx={{ ml: 1.5 }} variant="body2">
                {WEBSITES.find((s) => s.id === order.sc)?.name}
              </Typography>
            </Box>
            {showArchived && (
              <IconButton
                onClick={archivedHandler}
                sx={{ p: 1, borderRadius: 1 }}
              >
                <Icon
                  fontSize={18}
                  icon={archived ? "carbon:view-off" : "carbon:view"}
                />
              </IconButton>
            )}
          </Box>
          <Info list={infoList} />
        </CardContent>
        <Divider sx={{ my: "0px !important" }} />
        <CardContent sx={{ py: 2, px: 3 }}>
          <CurrencyInfo
            currency={order.currency}
            take={order.amount.take}
            give={order.amount.give}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent:
                order.status <= 103 ? "space-between" : "flex-end",
              mt: 2,
              mb: 2
            }}
          >
            {order.status <= 103 && (
              <Timer
                TypographyProps={{
                  variant: "caption",
                  sx: {
                    fontWeight: 500,
                  },
                }}
                finished={order.finished}
              />
            )}

            <Typography
              sx={{
                alignSelf: "flex-end",
              }}
              variant="caption"
            >
              {moment(order.createdAt).format("YYYY.MM.DD HH:mm")}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Info(props) {
  let { list } = props;

  return (
    <Box sx={{ mb: 2.5, color: "secondary" }}>
      {list.map((i) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2.5 }}>
            <Icon fontSize={18} icon={i.icon} />
            <Typography
              // color={isLink ? "primary" : textColor}
              // component={isLink ? Link : undefined}
              // to={link}
              sx={{ ml: 1.5 }}
              variant="body2"
            >
              {i.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

function CurrencyInfo(props) {
  let { currency, take, give } = props;

  let curTake = currency.take;
  let curGive = currency.give;

  return (
    <Box>
      <CurrencyInfoItem
        name={curTake.name}
        code={curTake.code}
        sum={take}
        image={curTake.image}
      />
      <Box sx={{ ml: 1.5 }}>
        <Icon icon="ph:arrow-down-bold" />
      </Box>
      <CurrencyInfoItem
        name={curGive.name}
        code={curGive.code}
        sum={give}
        image={curGive.image}
      />
    </Box>
  );
}

function CurrencyInfoItem(props) {
  let { name, code, sum, image } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 38, height: 38, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image}
          alt={name}
          sx={{ height: 30, width: 30 }}
        />
      </CustomAvatar>
      <Box>
        <Typography
          fontWeight="bold"
          variant="body1"
        >{`${sum} ${code}`}</Typography>
        <Typography noWrap variant="body2">
          {name}
        </Typography>
      </Box>
    </Box>
  );
}

export default OrderCard;
