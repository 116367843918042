import axios from "axios";
import Config from "../configs/api";

// Toast
import { toast } from "react-hot-toast";
import emitter from "../EventEmitter";

// import {logout} from './store/slices/auth'
// import store from './store/index';
axios.defaults.baseURL = Config.API;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  // console.log(,'config.headers')
  if (token) {
    config.headers["token"] = `Bearer ${token}`;
  }
  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let message = error?.response?.data?.message;
    let status = error?.response?.status;

    if (message) {
      emitter.emit("snackbar", {
        open: true,
        message,
        severity: "error",
      });
    }
    if (status == 403) {
      emitter.emit("logOut");
    }

    return Promise.reject(error);
  }
);
