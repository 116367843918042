import { useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

// Custom componenents
import CustomChip from "src/@core/components/mui/chip";
import CustomAvatar from "src/@core/components/mui/avatar";
// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

// Store
import { observer } from "mobx-react-lite";

// ** Icon
import Icon from "src/@core/components/icon";

// Config
import ConfigApi from "src/configs/api";

// Modules
import ModalChangeIcon from "./ModalChangeIcon";

function CurrencyPageHeader({ name, code, price, image, currencyId, precision }) {
  let [showChangeImgBtn, setShowChangeImgBtn] = useState(false);
  let [showChangeImg, setShowChangeImg] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 5.75,
      }}
    >
      <ModalChangeIcon
      currencyId={currencyId}
        show={showChangeImg}
        handleClose={() => setShowChangeImg(false)}
      />
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        onMouseEnter={() => setShowChangeImgBtn(true)}
        onMouseLeave={() => setShowChangeImgBtn(false)}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image}
          alt={name}
          sx={{ height: 32, width: 32, mr: 3 }}
        />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">{name}</Typography>
          <Typography variant="caption">{code}</Typography>
        </Box>
        {showChangeImgBtn && (
          <IconButton
            onClick={() => setShowChangeImg(true)}
            color="primary"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="mdi:image-edit-outline" />
          </IconButton>
        )}
      </Box>
      <CustomChip
        skin="light"
        color="primary"
        label={
          <Typography variant="h6" color="primary">
            {getPriceCurrentFiat(price, precision)}
          </Typography>
        }
      />
    </Box>
  );
}

export default observer(CurrencyPageHeader);
